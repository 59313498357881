import React, { useContext } from "react";
import Upgrade from "../../views/Upgrade";
import { AppConfigContext } from "../../context/AppConfigContext";

const ConversationUpgrade = () => {
  const { app_name } = useContext(AppConfigContext);
  
  return (
    <Upgrade>
      <p>
        Creating more conversations and producing more content with{" "}
        <span className="text-accent">
          {app_name}
        </span>{" "}
        requires a paid subscription.
      </p>
    </Upgrade>
  );
};

export default ConversationUpgrade;
