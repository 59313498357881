import React, { useContext, useState } from "react";
import useTranslations from "../../../hooks/useTranslations";
import { Link } from "@reach/router";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import { GroupsContext } from "../../../context/GroupsContext";
import { ModalContext } from "../../../context/ModalContext";
import useCurrentOrganizationUser from "../../../hooks/organizations/useCurrentOrganizationUser";

import AddGroupUsersForm from "./AddGroupUsersForm";

const OrganizationGroupHeader = () => {
  const [query, setQuery] = useState("");
  const [field, setField] = useState("");


  const { group } = useContext(GroupsContext);
  const { modalComponent } = useContext(ModalContext);

  const translations = useTranslations();
  const { getCurrentUserType } = useCurrentOrganizationUser();

  const usersTranslations = translations.admin.users;

  const handleAddUser = () => {
    
    modalComponent(
      "Add Users to Group",
      <AddGroupUsersForm/>
    );
  }

  const renderAddBtn = () => {
    const userType = getCurrentUserType();
    if(userType !== 'Member') {
      return(
        <div className="col-2 col-md-4 text-right" >
          <button onClick={handleAddUser} className="btn btn-primary ">
            + <span className="hide-mobile">{usersTranslations.addBtn}</span>
          </button>
        </div>
      )
    }
  }

  return(
    <div className="row mb-3 align-items-center">
    <div className="col-12 col-md-4">
      <h1 className="text-gradient mb-0 d-inline-block">{group?.name}</h1>
    </div>

    <div className="col-12 col-md-8 px-0 align-items-center" >
      <div className="row">

        <div className="col-10 col-md-8 px-0">
          <div className="row">
            
            <div className="col-12 pr-0 px-0">
              <input
                type="text"
                className="form-control bg-white"
                placeholder="Search..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>

          </div>
        </div>

        {renderAddBtn()}
      </div>
    </div>
  </div>
  )
}

export default OrganizationGroupHeader;