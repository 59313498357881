import React from "react";

const tableColumns = [
  {
    name: '#ID'
  },
  {
    name: 'Name'
  },
  {
    name: 'Email'
  },
  {
    name: 'Role'
  },
]

const OrganizationGroupTable = ({ children }) => {
  const renderTableColumns = () => {
    return tableColumns.map(column => (
      <th key={column.name}>{column.name}</th>
    ))
  }

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            {renderTableColumns()}
          </tr>
        </thead>

        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default OrganizationGroupTable;
