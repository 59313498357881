import React, { createContext, useReducer } from "react";
import SocketReducer from "../reducers/SocketReducer";
import { SET_SOCKET } from "../types/socket";

const initialState = {
  socket: null,
};

export const SocketContext = createContext(initialState);

export const SocketProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SocketReducer, initialState);

  const setSocket = (payload) => {
    dispatch({ type: SET_SOCKET, payload });
  };

  return (
    <SocketContext.Provider
      value={{
        ...state,
        setSocket,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
