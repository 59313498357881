const conversations = {
  add: "Agregar Chat",
  title: "Chats",
  button: "Agregar",
  saved: "Chat guardado",
  deleted: "Chat eliminado",
  search: "Buscar en los chats...",
  empty: "You don't have any conversations.",
  spinner: "Espera un momento, estamos en tus respuestas",
};

export default conversations;
