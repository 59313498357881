import React, { useContext, useEffect, useState } from "react";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import { GroupsContext } from "../../../context/GroupsContext";
import SearchableSelect from "../../global/SearchableSelect";

const CampaignAccessForm = ({ campaign_id, type, onCancel, onSave }) => {
    const { organization, getSingleOrganization } = useContext(OrganizationsContext);
    const { getGroupsByOrganization, groups } = useContext(GroupsContext);
    const [accessId, setAccessId] = useState()

    const [selectItems, setSelectItems] = useState([]);

    useEffect(() => {
        if (organization && organization.organization_id && type && type === "groups") {
            getGroupsByOrganization(organization.organization_id);
        }
    }, [type, organization]);

    useEffect(() => {
        if (type === null || type === undefined) {
            return
        }

        if (type === "users") {
            if (organization.users) {
                const current_users = organization.users.map((organization_user) => organization_user.user)
                setSelectItems(current_users);
            }
        }

        if (type === "groups") {
            setSelectItems(groups);
        }
    }, [type, organization, groups]);

    const handleSave = () => {
        const callback = () => getSingleOrganization(organization.organization_id);
        if (type === "users") {
            onSave({ campaign_id, user_id: accessId }, callback);
        }

        if (type === "groups") {
            onSave({ campaign_id, group_id: accessId }, callback);
        }
    }

    const renderEntitySelect = () => {
        if (selectItems !== null) {
            return (
                <SearchableSelect
                    items={selectItems}
                    itemText="name"
                    itemValue={`${type === "users" ? "user_id" : "group_id"}`}
                    placeholderText={`Select a ${type}...`}
                    modifier={(selectData) => setAccessId(selectData.value)}
                />
            );
        }
        return null;
    };

    return (
        <div>
            <form>
                <div className="mb-3">
                    <label htmlFor="entity" className="form-label">
                        <span>{`Select a ${type}:`}</span>
                    </label>
                    {renderEntitySelect()}
                </div>
                <div className="row">
                    <button type="button" className="col-12 col-md-6 btn" onClick={onCancel}>
                        Cancel
                    </button>
                    <button type="button" className="col-12 col-md-6 btn btn-primary" onClick={handleSave}>
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CampaignAccessForm;
