import React from "react";
import CampaignResourceHandler from "./CampaignResourceHandler";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

const CampaignActions = ({ filter, setFilter }) => {
    const { user_organization } = useContext(AuthContext);
    const { handleCreateCampaignResource } = CampaignResourceHandler(filter)
    const resourceTitle = filter.charAt(0).toUpperCase() + filter.slice(1);

    const renderCreateResource = () => {
        if(user_organization?.user_type.name !== "Member") {
            return(
                <button
                    className="btn btn-outline-primary mt-2 ms-2"
                    onClick={handleCreateCampaignResource}
                >
                    + {resourceTitle}
                </button>
            );
        }
    }

    const renderButtons = () => {
        return (
            <div className="btn-group border br-25 mt-2 ms-2" role="group">
                <button
                    type="button"
                    onClick={() => setFilter("document")}
                    className={`btn ${filter === "document" ? "btn-primary" : ""}`}
                >
                    Documents
                </button>
                <button
                    type="button"
                    onClick={() => setFilter("template")}
                    className={`btn ${filter === "template" ? "btn-primary" : ""}`}
                >
                    Templates
                </button>
            </div>
        );
    };

    return (
        <div>
            {renderButtons()}
            {renderCreateResource()}
        </div>
    );
};

export default CampaignActions;
