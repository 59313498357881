import { Link } from "@reach/router";
import React from "react";

const WorkflowCard = ({ workflow, handleCallback }) => {
  const handleClick = () => {
    if (typeof handleCallback === "function") {
      handleCallback();
    }
  };

  return (
    <div className="card position-relative bg-light py-3 mb-3">
      <div className="card-body pb-2">
        <h3>{workflow.name}</h3>
        <p>{workflow.description}</p>
      </div>
      <Link
        to={`/workflows/${workflow.workflow_id}`}
        className="btn btn-primary btn-view"
      >
        <i className="fa fa-chevron-right"></i>
      </Link>
    </div>
  );
};

export default WorkflowCard;
