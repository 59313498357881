import React, { createContext, useReducer } from "react";
import {
  SET_DEFAULT_TABS,
  SET_ORGANIZATION_TABS,
  SET_SELECTED,
  SET_TABS_DATA,
  SET_TOOLS_TABS,
  TOGGLE_SETTINGS,
} from "../types/menu";
import MenuReducer from "../reducers/MenuReducer";
import { AuthContext } from "./AuthContext";
import { useContext } from "react";
import { getOrganizationTabs, getTabs, getTools } from "../utils/menu";
import { AppConfigContext } from "./AppConfigContext";
import { OrganizationsContext } from "./OrganizationsContext";
import { useEffect } from "react";
import { getValue } from "../utils";

export const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
  const { user } = useContext(AuthContext);

  const initialState = {
    tabs: [
      {
        link: "/tutorials",
        name: { en: "Tutorials", es: "Tutoriales" },
        icon: "fa fa-chalkboard me-2",
      },
    ],
    toolsTabs: {
      tools: {
        name: "Tools",
        icon: "fa fa-tools me-2",
        tabs: [],
      },
    },
    adminTabs: {
      admin: {
        name: "Admin",
        icon: "fa fa fa-user-shield me-2",
        tabs: [
          {
            link: "/admin",
            name: { en: "Purchases", es: "Comparas" },
            icon: "fa fa fa-dollar-sign me-2",
          },
          {
            link: "/admin/users",
            name: { en: "Users", es: "Usuarios" },
            icon: "fa fa-user-circle me-2",
          },
          {
            link: "/admin/staff",
            name: { en: "Staff", es: "Staff" },
            icon: "fa fa-user-check me-2",
          },
          {
            link: "/admin/products",
            name: { en: "Products", es: "Productos" },
            icon: "fas fa-shopping-cart me-2",
          },
          {
            link: "/admin/settings",
            name: { en: "Settings", es: "Ajustes" },
            icon: "fas fa-cog me-2",
          },
        ],
      },
      analytics: {
        name: "Analytics",
        icon: "fa fa fa-chart-pie me-2",
        tabs: [
          {
            link: "/admin/analytics/customers",
            name: { en: "Customers", es: "Clientes" },
            icon: "fa fa-user-check me-2",
          },
          {
            link: "/admin/analytics/income",
            name: { en: "Income", es: "Ingresos" },
            icon: "fa fa-dollar-sign me-2",
          },
          {
            link: "/admin/analytics/products",
            name: { en: "Products", es: "Productos" },
            icon: "fas fa-box me-2",
          },
        ],
      },
    },
    userSettingTabs: {
      userSettings: {
        name: user ? user?.name : "User Settings",
        icon: "fas fa-user me-2",
        tabs: [
          {
            name: { en: "Settings", es: "Ajustes" },
            icon: "fa fa-cog",
            link: "/settings",
          },
          {
            link: "/billing",
            name: { en: "Billing", es: "Facturación" },
            icon: "fa fa-file-invoice me-2",
          },
        ],
      },
    },
    organizationTabs: {},
    selected: "",
    showSettings: false,
  };

  const [state, dispatch] = useReducer(MenuReducer, initialState);

  const appconfig = useContext(AppConfigContext);
  const { organization } = useContext(OrganizationsContext);

  const setDefaultTabs = () => {
    dispatch({ type: SET_DEFAULT_TABS, payload: initialState });
  };

  const setSelected = (name) => {
    dispatch({ type: SET_SELECTED, payload: name });
  };

  const toggleSettings = () => {
    dispatch({ type: TOGGLE_SETTINGS });
  };

  const setToolsTabs = (payload) => {
    dispatch({ type: SET_TOOLS_TABS, payload });
  };

  const setTabsData = (payload) => {
    dispatch({ type: SET_TABS_DATA, payload });
  };

  const getAppTabs = () => {
    const appToolsTabs = getTools(appconfig);
    const appTabs = getTabs(appconfig);
    setToolsTabs(appToolsTabs);
    setTabsData(appTabs);
    setOrganizationTabs();
  };

  const setOrganizationTabs = () => {
    const organizationTabs = getOrganizationTabs(appconfig);
    const currentTabs = {
      organization: {
        name: "Admin",
        icon: "fas fa-building me-2",
        tabs: organizationTabs.organization,
      },
      campaign: organizationTabs.campaign,
    };

    dispatch({ type: SET_ORGANIZATION_TABS, payload: currentTabs });
  };

  return (
    <MenuContext.Provider
      value={{
        ...state,
        setSelected,
        toggleSettings,
        getAppTabs,
        setDefaultTabs,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
