import React, { useContext, useEffect } from "react";
import { TranslationsContext } from "../context/TranslationsContext";
import moment from "moment";
import { AuthContext } from "../context/AuthContext";
import { navigate } from "@reach/router";
import { ModalContext } from "../context/ModalContext";
import SelectLanguage from "../components/global/SelectLanguage";

const useTranslations = () => {
  const { lang, setLang, translations, getLocalStorageLang, setDefaultLang } =
    useContext(TranslationsContext);
  const { modalComponent } = useContext(ModalContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (lang === "es") {
      moment.locale("es", {
        monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split(
          "_"
        ),
        weekdaysShort: "Dom_Lun_Mar_Mie_Jue_Vie_Sab".split("_"),
        weekdays: "Lun_Mar_Mie_Jue_Vie_Sab_Dom".split("_"),
      });
    }

    if ((!user || user === null) && !getLocalStorageLang()) {
      if (lang === "es") navigate("/latam");
      if (lang === "en") navigate("/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  useEffect(() => {}, [lang]);

  const formatDate = (date) => {
    if (lang === "en") {
      return moment(date).utc().format("MMM DD YYYY");
    }
    return moment(date).utc().format("DD MMM YYYY");
  };

  const formatDateTime = (date) => {
    if (lang === "en") {
      return moment(date).utc().format("MMM DD YYYY HH:mm");
    }
    return moment(date).utc().format("DD MMM YYYY HH:mm");
  };

  const handleDefaultLang = () => {
    const lsLang = getLocalStorageLang();
    if (user === null && !lsLang) {
      modalComponent(translations["en"].general.language, <SelectLanguage />);
    } else if (user) {
      setLang(user.lang);
    } else if (lsLang) {
      setLang(lsLang);
    }
  };

  const textTranslations = translations[lang]
    ? translations[lang]
    : translations["en"];

  return {
    ...textTranslations,
    lang,
    setLang,
    formatDate,
    formatDateTime,
    setDefaultLang,
    handleDefaultLang,
    getLocalStorageLang,
  };
};

export default useTranslations;
