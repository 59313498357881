
import api from './api';

const route = '/workflows';

export default {
  getWorkflows: () => api.get(route),
  getSingleWorkflow: (workflow_id) => api.get(`${route}/${workflow_id}`),
  postWorkflow: (workflow) => api.post(route, { ...workflow}),
  putWorkflow: (workflow) => api.put(route, { ...workflow}),
  deleteWorkflow: (Workflow) => api.delete(`${route}/${Workflow}`),
};
