import React, { useContext } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import { ModalContext } from "../../context/ModalContext";

const ConfirmDeleteUser = ({ user_id }) => {
  const { clearModal } = useContext(ModalContext);
  const { spinner, deleteCustomer } = useContext(CustomerContext);
  return (
    <div>
      <p>
        Are you sure you want to delete this user's account? This action cannot
        de undone.
      </p>
      <div className="row">
        <div className="col-6">
          <button onClick={clearModal} className="btn w-100 text-muted">
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button
            disabled={spinner}
            onClick={() => deleteCustomer(user_id, clearModal)}
            className="btn w-100 btn-danger"
          >
            {spinner ? (
              <div className="spinner-border" />
            ) : (
              <span>
                <i className="fa fa-trash" /> Delete User
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteUser;
