import api from "./api";

const route = "/user_workflows";

export default {
  getUserWorkflows: () => api.get(route),
  getSingleUserWorkflow: (userWorkflow_id) =>
    api.get(`${route}/${userWorkflow_id}`),
  postUserWorkflow: (userWorkflow) => api.post(route, { ...userWorkflow }),
  putUserWorkflow: (userWorkflow) => api.put(route, { ...userWorkflow }),
  deleteUserWorkflow: (UserWorkflow) => api.delete(`${route}/${UserWorkflow}`),
};
