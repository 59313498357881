import React, { useContext, useEffect } from "react";
import TemplateForm from "../components/templates/TemplateForm";
import { TemplatesContext } from "../context/TemplatesContext";
import { navigate } from "@reach/router";

const SingleTemplate = ({ title, idTemplate }) => {

  const { setTemplate, getSingleTemplate } = useContext(TemplatesContext);

  useEffect(() => {
    if (idTemplate) getViewData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getViewData = () => {
    getSingleTemplate(idTemplate);
  };

  const onCancel = () => {
    navigate("/templates");
  };

  const onCreateTemplate = () => {
    setTemplate(null);
    navigate("/templates");
  };

  return (
    <div
      className="px-4 py-4 card bg-white"
      style={{
        height: "100%",
      }}
    >
      <div className="col-12 px-2" style={{ height: "50px" }}>
        <h1 className="mb-0 text-capitalize text-gradient d-inline-block">
          {title}
        </h1>
      </div>

      <div
        className="col-12 position-relative"
        style={{
          overflowY: "hidden",
          flex: 1
        }}
      >
        <section className="row h-100">
          <TemplateForm
            handleCallback={onCreateTemplate}
            handleCancel={onCancel}
          />
        </section>
      </div>
    </div>
  );
};

export default SingleTemplate;
