import React, { useContext } from "react";
import { SuperFetchContext } from "../../context/SuperFetchContext";
import TextareaInput from "../../components/common/TextareaInput";
import useTranslations from "../useTranslations";
import { AvatarsContext } from "../../context/AvatarsContext";

const useSFInputs = () => {

  const { inputs, changeInputValue } = useContext(SuperFetchContext);
  const { super_fetch_avatar, setSuperFetchAvatar } = useContext(AvatarsContext);
  const translations = useTranslations();

  const handleChangeFile = (event) => {
    const file = event.target.files[0];

    setSuperFetchAvatar({
      ...super_fetch_avatar,
      file,
    });
  };

  const handleChangeInput = (event, inputIndex) => {
    const newValue = event.target.value;
    changeInputValue(newValue, inputIndex);
  };

  const handleChangeTextArea = (newValue, inputIndex) => {
    changeInputValue(newValue, inputIndex);
  }

  const renderTextArea = (input, inputIndex) => {
    return(
      <TextareaInput
        key={input.placeholder}
        label={input.label}
        description={input.placeholder} 
        valid={input.isValid}
        value={input.value}
        handleChange={(value) => {
          handleChangeTextArea(value, inputIndex);
        }}
        maxHeight={'200px'}
        smallDescription
        smallContent
        bgWhite
      />
    )
  }


  const renderInput = (input, inputIndex) => {
    return(
      <div key={input.label} className="px-0 mb-2">
        <label className="px-0 small bold">{input.label}</label>
        <input
          type={input.type}
          placeholder={input.placeholder}
          className={`form-control bg-white small z-2 ${
            input.isValid ? "" : "border border-danger"
          }`}
          value={input.value}
          onChange={(event) => {
            handleChangeInput(event, inputIndex);
          }}
        />

        <span
          className={`text-danger small
          ${input.isValid ? "d-none" : ""}`}
        >
          {translations.superfetch.emptyInput}
        </span>
      </div>
    )
  }

  const renderFileInput = () => {
    return (
      <div className="px-0">
        <label className="form-label small">
          <b>Insert File</b>
        </label>
        <span className="small d-block">
          {" "}
          <span className="bold small">
            {translations.superfetch.fileTypes}
          </span>{" "}
          .docx, .pdf, .pptx, .txt
        </span>
        <input
          accept=".docx, .pdf, .pptx, .txt"
          className="form-control rounded-3 small"
          type="file"
          id="avatar__input-file"
          onChange={handleChangeFile}
          style={{
            paddingLeft: "0px!important",
          }}
        />
      </div>
    );
  }

  const renderSFInputs = () => {
    return (
      <>
        {
          inputs.map((input, index) => {
            if(input.type === 'text') return renderInput(input, index);
            if(input.type === 'textarea') return renderTextArea(input, index);
          })
        }
        {renderFileInput()}
      </>
    )
  }

  return {
    renderSFInputs
  }

}

export default useSFInputs;