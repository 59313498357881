import React, { useContext } from "react"
import { AuthContext } from "../../context/AuthContext";

const useCurrentOrganizationUser = () => {
  const { user_organization } = useContext(AuthContext);

  const getCurrentUserType = () => {
    const user_type = user_organization?.user_type;

    return user_type?.name;
  }

  return {
    getCurrentUserType
  }
}

export default useCurrentOrganizationUser