
import api from './api';

const route = '/groups';

const service = {
  getOrganizationGroups: (organization_id) => api.get(`${route}/organization/${organization_id}`),
  getSingleGroup: (group_id) => api.get(`${route}/${group_id}`),
  postGroup: (group) => api.post(route, { ...group}),
  addUserToGroup: (user_id, group_id) => api.post(`${route}/users/${user_id}/${group_id}`),
  putGroup: (group) => api.put(route, { ...group}),
  deleteGroup: (Group) => api.delete(`${route}/${Group}`),
  deleteUserFromGroup: (user_id, group_id) => api.delete(`${route}/users/${user_id}/${group_id}`),

};

export default service;