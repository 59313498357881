import React from "react";
import useTranslations from "../../hooks/useTranslations";
import { useContext } from "react";
import { ProductsContext } from "../../context/ProductsContext";
import { getValue } from "../../utils";

const ProductSubscriptionsOptions = () => {
  const { product, setProductProperty } = useContext(ProductsContext);
  const translations = useTranslations();
  return (
    <div className="row align-items-center mb-2">
      <label className="px-0">
        {translations.products.subscription_interval}
      </label>
      <div className="ps-0 col-12 col-md-2 mb-3">
        <input
          type="number"
          className="form-control"
          value={getValue(product, "subscription_interval")}
          onChange={(e) =>
            setProductProperty("subscription_interval", e.target.value)
          }
        />
      </div>
      <div className="col-12 col-md-10 mb-3">
        <select
          className="form-control"
          value={getValue(product, "subscription_period")}
          onChange={(e) =>
            setProductProperty("subscription_period", e.target.value)
          }
        >
          <option value="day">{translations.products.day}</option>
          <option value="month">{translations.products.month}</option>
          <option value="year">{translations.products.year}</option>
        </select>
      </div>
    </div>
  );
};

export default ProductSubscriptionsOptions;
