import React from "react";
import UserWorkflowCard from "./UserWorkflowCard";

const UserWorkflowList = ({ size, user_workflows }) => {
  const renderWorkflows = () => {
    if (Array.isArray(user_workflows)) {
      if (user_workflows.length === 0) {
        return <p>You don't have any workflows.</p>;
      }
      return user_workflows.map((user_workflow) => (
        <div
          key={user_workflow.user_workflow_id}
          className={size === "lg" ? "col-12" : "col-12 col-md-6 col-xl-4"}
        >
          <UserWorkflowCard user_workflow={user_workflow} />
        </div>
      ));
    }
  };

  return <div className="row">{renderWorkflows()}</div>;
};

export default UserWorkflowList;
