import { PRODUCTS_RECEIVED, SET_PRODUCT, SET_PRODUCT_PROPERTY } from "../types/products";

const ProductsReducer = (state, { type, payload }) => {
  switch (type) {
    case PRODUCTS_RECEIVED:
      return { ...state, products: payload };
    case SET_PRODUCT:
      return { ...state, product: payload };
    case SET_PRODUCT_PROPERTY:
      const updatedProduct = {
        ...state.product,
        [payload.key]: payload.value
      }
      return { ...state, product: updatedProduct };
    default:
      return { ...state };
  }
};
export default ProductsReducer;
