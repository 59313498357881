import React, { useContext, useState } from "react";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import { ModalContext } from "../../../context/ModalContext";
import OrganizationForm from "./OrganizationForm";

const OrganizationsHandler = () => {
    const { modalComponent, clearModal, alert } = useContext(ModalContext);
    const { deleteOrganization, saveOrganization } = useContext(OrganizationsContext);

    const handleCancel = () => {
        clearModal();
    };

    const handleCreateOrganization = () => {
        modalComponent(
            "Create Organization",
            <OrganizationForm
                onCancel={handleCancel}
                onSave={saveOrganization}
            />
        );
    };

    const handleEditOrganization = (organization) => {
        modalComponent(
            `Edit ${organization.name}`,
            <OrganizationForm
                handleCancel={handleCancel}
                organizationToEdit={organization}
                onSave={saveOrganization}
            />
        );
    };

    const handleDeleteOrganization = (organization, goBack) => {
        let inputValue = '';

        const onDelete = () => {

            if (inputValue === organization.name) {
                deleteOrganization(organization.organization_id);
                if (goBack) {
                    window.history.go(-1);
                }
            } else {

                alert('Organization name does not match. Deletion aborted.');
            }
        };

        modalComponent(
            `Delete ${organization.name}?`,
            <div className="w-100 d-flex flex-column mt-3">
                <label htmlFor="organizationNameInput" className="mb-2">
                    Enter organization name to confirm deletion:
                </label>
                <input
                    type="text"
                    id="organizationNameInput"
                    onChange={(e) => (inputValue = e.target.value)}
                    className="form-control mb-3"
                />
                <div className="d-flex">
                    <button className="btn me-1 flex-grow-1" onClick={clearModal}>
                        Cancel
                    </button>
                    <button
                        className="btn btn-danger ms-1 flex-grow-1 text-white"
                        onClick={onDelete}
                    >
                        Delete Organization
                    </button>
                </div>
            </div>
        );
    };

    return {
        handleCreateOrganization,
        handleEditOrganization,
        handleDeleteOrganization,
    };
};

export default OrganizationsHandler;
