import React, { useContext, useEffect } from "react";
import { SuperFetchContext } from "../../context/SuperFetchContext";
import useSFInputs from "../../hooks/super_fetch/useSFInputs";
import { AuthContext } from "../../context/AuthContext";
import { AvatarsContext } from "../../context/AvatarsContext";
import { DocumentsContext } from "../../context/DocumentsContext";

const SuperFetchAside = () => {
  const { user } = useContext(AuthContext);
  const { super_fetch_avatar, saveAvatarFile } = useContext(AvatarsContext);
  const { loadingOutputs, setLoadingOutputs } = useContext(DocumentsContext);

  const { 
    validateInputsLength, 
    getTemplatePrompt, 
    getSuperFetchPrompt,
  } = useContext(SuperFetchContext);

  const { renderSFInputs } = useSFInputs();

  const handleGenerate = async () => {
    const inputsValid = validateInputsLength();

    const assistant_id = user.defaultAssistant?.id;
    const avatar_id = user.defaultAssistant?.avatar_id;
    const file = super_fetch_avatar?.file;

    if(inputsValid) {
      setLoadingOutputs(true);

      if(file && file !== null && file !== undefined) {
        await saveAvatarFile(file, assistant_id, avatar_id);
      }

      const content = getTemplatePrompt();
      getSuperFetchPrompt(1, user.defaultAssistant.id, content);
    }
  }

  const renderLoading = () => {
    if(loadingOutputs) {
      return(
        <div className="spinner-border me-2 text-white
        position-absolute top-0 bottom-0 end-0 m-auto"></div>
      )
    } 
  }

  return(
    <div className="row">
      {renderSFInputs()}

      <button 
        className="btn btn-accent small mt-3 position-relative"
        onClick={handleGenerate}
        disabled={loadingOutputs}
      >
        Generate
        <i className={`fa fa-arrow-right ms-2 ${loadingOutputs ? 'd-none' : ''}`}></i>
        {renderLoading()}
      </button>
    </div>
  )
}

export default SuperFetchAside;