import React, { useContext, useEffect, useState } from "react";
import { Link } from "@reach/router";
import Pagination from "../../components/global/Pagination";
import useTranslations from "../../hooks/useTranslations";
import ProductsTable from "../../components/products/ProdutcsTable";
import { ProductsContext } from "../../context/ProductsContext";

const AdminProducts = () => {
  const [sort, setSort] = useState("name");
  const [page, setPage] = useState(1);
  const [direction, setDirection] = useState("ASC");
  const [query, setQuery] = useState("");
  const { products, getAllProducts } = useContext(ProductsContext);

  const translations = useTranslations();
  const usersTranslations = translations.admin.users;

  useEffect(() => {
    getAllProducts({ page });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = () => {
      getAllProducts({ query, page, sort, direction });
    };

    if (query.length > 0) {
      const timeoutId = setTimeout(fetchData, 500);
      return () => clearTimeout(timeoutId);
    } else {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, page, sort, direction]);

  const renderPagination = () => {
    if(Array.isArray(products) && products.length > 0){
      return (
        <Pagination
          size={12}
          currentPage={page}
          onPageChange={setPage}
          itemsPerPage={24}
          totalItems={200}
        />
      )
    }
  }
  return (
    <div className="container-fluid h-100 d-flex flex-column">
      <div className="row mx-0 align-items-center mb-3 border-bottom">
        <div className="col-12 col-md-3 ps-0">
          <h1>Products</h1>
        </div>
        <div className="col-12 col-lg-9 px-0 align-items-center">
          <div className="row">
            <div className="col-12 col-lg-8 mb-3 px-0">
              <div className="row">
                <input
                  type="text"
                  className="form-control bg-white"
                  placeholder="Search..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
            </div>
            <div
              className="col-12 px-0 ps-lg-3 mb-4 col-lg-4 text-right"
              style={{ minWidth: "145px" }}
            >
              <Link to="new/edit" className="btn btn-primary w-100">
                + {usersTranslations.addBtn}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div style={{ overflowY: "auto" }}>
        <ProductsTable
          products={products}
          setSort={setSort}
          direction={direction}
          selected={sort}
          setDirection={setDirection}
        />
        {renderPagination()}
      </div>
    </div>
  );
};

export default AdminProducts;
