import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import AvatarDeleteFile from "./AvatarDeleteFile";

const AvatarFileCard = ({ 
  fileName,
  fileId,
  type,
  openaiFileId,
  assistantId,
  editable,
  onEdit
 }) => {
  const [name, setName] = useState('');
  const { modalComponent } = useContext(ModalContext);
 
  useEffect(() => {
    handleFileNameLength();
  }, []);

  const handleFileNameLength = () => {
    if(fileName.length > 15) {
      const updatedName = `${fileName.substring(0, 15)}..`;
      setName(updatedName);
    } else {
      setName(fileName);
    }
  }

  const handleDeleteFile = () => {
    const deleteData = {
      fileId,
      openaiFileId,
      assistantId
    }

    modalComponent(
      'Are you sure to delete this file?',
      <AvatarDeleteFile 
        fileName={fileName}
        fileType={type}
        deleteData={deleteData}
      />
    )
  }

  const renderEditBtn = () => {
    if(editable) {
      return (
        <i onClick={onEdit} className="fas fa-edit me-3 fs-4 text-accent cursor-pointer"/>
      )
    }
  }

  return (
    <div className="card w-100 bg-light mb-2">
      <div className="card-header d-flex justify-content-between align-items-center">
        <h3 className="mb-0">{name}.{type}</h3>

        <div className="">
          {renderEditBtn()}
          <i onClick={handleDeleteFile} className="fas fa-trash fs-4 text-primary cursor-pointer"/>
        </div>
      </div>
    </div>
  );
};

export default AvatarFileCard;
