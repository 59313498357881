
import { useContext } from "react";
import { TemplatesContext } from "../../context/TemplatesContext";

const useTemplatePrompt = () => {
  const { template } = useContext(TemplatesContext);

  const processFieldName = (field) => {
    field = field.replaceAll(" ", "_");
    field = field.replaceAll(":", "_");
    return field;
  };

  const updatePromptValues = (currentPrompt, key, currentValue) => {
    let trimKey = String(key).trim();
    trimKey = processFieldName(trimKey);
    if (trimKey.indexOf("[") !== -1) {
      const start = trimKey.indexOf("[") + 1;
      trimKey = trimKey.substring(0, start - 1);
      const keyIndex = key.indexOf("[");
      key = key.substring(0, keyIndex);
    }
    const stringToReplace = `[${key}]`;
    currentPrompt = String(currentPrompt).replaceAll(
      stringToReplace,
      currentValue
    );

    return currentPrompt;
  };

  const getTemplatePrompt = () => {
    let content = template?.content;

    template?.fields?.forEach((field) => {
      content = updatePromptValues(content, field.name, field.value);
    });

    return content;
  }

  return getTemplatePrompt;
}

export default useTemplatePrompt;