import React, { useContext, useEffect, useState } from "react";
import { SuperFetchContext } from "../context/SuperFetchContext";
import SuperFetchForm from "../components/super_fetch/SuperFetchForm";
import SuperFetchGenerateBar from "../components/super_fetch/SuperFetchGenerateBar";
import SuperFetchOutputContainer from "../components/super_fetch/SuperFetchOutputContainer";
import SuperFetchFeedbackForm from "../components/super_fetch/SuperFetchFeedbackForm";
import { AvatarsContext } from "../context/AvatarsContext";
import { AuthContext } from "../context/AuthContext";
import { MessagesContext } from "../context/MessagesContext";
import MessagesService from "../services/MessagesService";
import useTranslations from "../hooks/useTranslations";
import { OrganizationsContext } from "../context/OrganizationsContext";

const SuperFetch = () => {
  const [outputQty, setOutputQty] = useState(1);
  const [generatingFetch, setGeneratingFetch] = useState(false);

  const { user } = useContext(AuthContext);
  const translations = useTranslations();
  const { enhanced, setEnhancing, setEnhanced } = useContext(MessagesContext);
  const { organization } = useContext(OrganizationsContext);

  const {
    getSuperFetchAvatar,
    super_fetch_avatar,
    saveAvatarFile,
  } = useContext(AvatarsContext);

  const {
    getSuperFetchPrompt,
    getModifiedSuperFetchPrompt,
    outputs,
    setOutputs,
    loading,
    feedback,
    toggleSmallDevice,
    smallDevice,
    resetFeedbackInput,
    toggleFormActive,
    setLoading,
    getTemplatePrompt,
    validateInputsLength,
    clearInputs
  } = useContext(SuperFetchContext);

  useEffect(() => {
    handleScreenWidth();
    // handleSuperFetchAvatar();
    getSuperFetchAvatar();
    return () => {
      window.removeEventListener("resize", () => {});
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (enhanced !== null && enhanced !== undefined) {
      if (generatingFetch) {
        generateFetch();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enhanced]);

  const generateFetch = async () => {
    const assistant_id = user.defaultAssistant?.id;
    const avatar_id = user.defaultAssistant?.avatar_id;
    const file = super_fetch_avatar?.file;

    setGeneratingFetch(false);

    if (file !== null && file !== undefined)
      await saveAvatarFile(file, assistant_id, avatar_id);

    if (feedback.formActive) {
      getModifiedSuperFetchPrompt(outputQty, assistant_id, enhanced);
    } else {
      getSuperFetchPrompt(outputQty, assistant_id, enhanced);
    }

    setEnhanced(null);
    setEnhancing(false);
  };

  const handleScreenWidth = () => {
    window.screen.width >= 1200
      ? toggleSmallDevice(false)
      : toggleSmallDevice(true);

    window.addEventListener("resize", () => {
      window.screen.width >= 1200
        ? toggleSmallDevice(false)
        : toggleSmallDevice(true);
    });
  };

  const increaseOutputs = () => {
    if (outputQty < 5 && !loading) {
      setOutputQty(outputQty + 1);
    }
  };

  const decreaseOutputs = () => {
    if (outputQty > 1 && !loading) {
      setOutputQty(outputQty - 1);
    }
  };

  const handleGenerate = async () => {
    const inputsValid = validateInputsLength();
    if (inputsValid) {
      setOutputs([]);
      setLoading(true);
      setGeneratingFetch(true);
      const content = getTemplatePrompt();
      handleEnhance(content);
    }
  };

  const handleEnhance = (content) => {
    setEnhancing(true);

    const enhanceData = {
      content: `
        Below I will give you some instructions and considerations so that you can improve the instruction fields and make it a prompt taking into account each consideration that I give you: 
        
        Consider the next to improve the Instructions:
          -You will give me the same instructions in the same format
          -Consider the instructions will be used for be sended and make a response
          -You must not modify the content and structure, and you must not add more instructions or lines.
          -Every field begins by a "-", don't modify the fields and not add more fields, the only thing you will improve is the instruction after each field.
          -Write the instruction to get the result, not the result itself. 
          -Consider the words within brackets will be changed to introduce user inputs in the instruction so don't modify it and use it where i can identify to insert the user inputs  

        Instructions: 
        ${content}

      `,
      enhance: true,
      stream: false,
    };

    if (
      organization.organization_id &&
      organization.organization_id !== undefined
    ) {
      enhanceData.organization_id = organization.organization_id;
    }

    MessagesService.postMessage(enhanceData).catch(err => {
      setEnhancing(false);
      setLoading(false);
      setGeneratingFetch(false);
    });
  };

  const renderForm = () => {
    if (feedback.formActive) {
      return <SuperFetchFeedbackForm />;
    } else {
      return <SuperFetchForm />;
    }
  };

  const backToEdit = () => {
    resetFeedbackInput();
    toggleFormActive(false);
  };

  const renderFormContent = () => {
    let containerHeight = feedback.formActive ? "max-content" : "100%";

    if (smallDevice && feedback.formActive) {
      containerHeight = "50%";
    }

    return (
      <div
        className={`col-12 col-xl-6 position-relative
        ${smallDevice && feedback.formActive ? "pb-0 pt-2 " : ""}`}
        style={{
          height: containerHeight,
        }}
      >
        <div className={`row ${!feedback.formActive ? "d-none" : ""}`}>
          <div className="col px-0">
            <button
              className={`btn btn-accent me-3 mb-3 `}
              onClick={backToEdit}
            >
              <i className="fa fa-arrow-left me-2"></i>
              Back to Edit
              {translations.superfetch.backToEdit}
            </button>
          </div>
        </div>

        <div 
          className={`d-flex w-100 justify-content-end 
          ${feedback.formActive ? "d-none" : ""}`}
        >
          <button
            type="button"
            className="btn ms-auto btn-outline-primary small"
            onClick={clearInputs}
            disabled={loading}
          >
            <i className="fas fa-trash small me-2"/>
            Clean
          </button>
        </div>

        <div
          className={`row super-fetch__form ${!feedback.formActive ? "mb-3" : ""
            }`}
          style={{
            overflowY: "auto",
            marginBottom: feedback.formActive ? "120px" : "",
          }}
        >
          <div className="col-12 px-0">
            {renderForm()}
          </div>
        </div>

        <div
          className={`row align-items-center super-fetch__generate-container
          ${smallDevice ? "px-0" : ""}`}
        >
          <SuperFetchGenerateBar
            outputs={outputs}
            outputQty={outputQty}
            handleGenerate={handleGenerate}
            increaseOutputs={increaseOutputs}
            decreaseOutputs={decreaseOutputs}
          />
        </div>
      </div>
    );
  };

  const renderOutputsContent = () => {
    return (
      <SuperFetchOutputContainer/>
    );
  };

  return (
    <div className="container-fluid d-flex bg-white rounded-3 flex-column h-100">
      <div className="row mb-3">
        <div className={`col-12 pb-0 ${smallDevice ? "px-0" : ""}`}>
          <h1 className=" text-capitalize text-gradient mb-0 d-inline-block">
            Super Fetch
          </h1>
        </div>
      </div>

      <div className="row" style={{ flex: 1, overflow: "hidden" }}>
        {renderFormContent()}
        {renderOutputsContent()}
      </div>
    </div>
  );
};

export default SuperFetch;
