import React, { useRef, useState, useContext, useEffect } from "react";
import ConversationUpgrade from "../components/conversations/ConversationUpgrade";
import ConversationHeader from "../components/conversations/ConversationHeader";
import MessageInputForm from "../components/messages/MessageInputForm";
import { ConversationsContext } from "../context/ConversationsContext";
import TemplateList from "../components/templates/TemplatesList";
import { TemplatesContext } from "../context/TemplatesContext";
import { MessagesContext } from "../context/MessagesContext";
import MessageCard from "../components/messages/MessageCard";
import useTranslations from "../hooks/useTranslations";
import { AuthContext } from "../context/AuthContext";
// import useEmitSocket from "../hooks/useEmitSocket";
import { setupTooltips } from "../utils";
import { navigate } from "@reach/router";
import { AvatarsContext } from "../context/AvatarsContext";
import LoadingCard from "../components/global/LoadingCard";

const SingleConversation = ({
  conversation_id,
  disableTemplatesBtn,
  addGenerateBtn,
  handleGenerateBtn,
  titleGradient,
}) => {
  const refContainer = useRef(null);
  // const { cancelChatStream } = useEmitSocket();

  const [page, setPage] = useState(1);
  const [firstLoad, setFirstLoad] = useState(true);
  const [smallDevice, setSmallDevice] = useState(false);
  const [loadingTopMessages, setLoadingTopMessages] = useState(false);
  const [currentIntent, setCurrentIntent] = useState(null);
  const [lastScrollHeight, setLastScrollHeight] = useState(0);


  const translations = useTranslations();
  const { user } = useContext(AuthContext);
  const { conversationAvatar } = useContext(AvatarsContext);
  const { template, templates, getPublicTemplates } =
    useContext(TemplatesContext);


  const {
    conversation,
    getSingleConversation,
    setConversation,
    setPropertyConversation,
  } = useContext(ConversationsContext);

  const {
    max,
    spinner,
    messages,
    setPrompt,
    generating,
    saveMessage,
    clearMessages,
    // setGenerating,
    setConversation: setMessagesConversation,
    getConversationMessages,
  } = useContext(MessagesContext);


  useEffect(() => {
    handleScreenWidth();
    getSingleConversation(conversation_id);
    getPublicTemplates();

    return () => {
      setConversation(null);
      setMessagesConversation(null);
      window.removeEventListener("resize", () => { });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearMessages();
    if (!template || template === null) {
      setPrompt("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation_id]);

  useEffect(() => {
    if (Array.isArray(messages)) {
      setupTooltips();
      handleSetScrollBottom();
      handlePrevMessagesLoaded();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  useEffect(() => {
    fetchMessages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, page]);

  const handlePrevMessagesLoaded = () => {
    if(loadingTopMessages) setContainerPosition(lastScrollHeight);

    const container = refContainer?.current;
    setLastScrollHeight(container?.scrollHeight);
    setLoadingTopMessages(false);
  }

  const setContainerPosition = (newScrollHeight) => {
    const container = refContainer?.current;
    container.scrollTop = container.scrollHeight - newScrollHeight;
  }


  const handleScreenWidth = () => {
    window.screen.width >= 1200 ? setSmallDevice(false) : setSmallDevice(true);

    window.addEventListener("resize", () => {
      window.screen.width >= 1200
        ? setSmallDevice(false)
        : setSmallDevice(true);
    });
  };

  const fetchMessages = () => {
    getConversationMessages(conversation_id, { page });
  };

  const handleSetScrollBottom = () => {
    if(currentIntent) clearTimeout(currentIntent);

    const intent = setTimeout(() => {
      if (firstLoad) handleScrollBottom();
      if(!loadingTopMessages) handleScrollBottom();
    }, 50);

    setCurrentIntent(intent);
  }

  const handleScrollBottom = () => {
    if (refContainer.current) {
      refContainer.current.scrollTo({
        top: refContainer.current.scrollHeight,
        behavior: 'smooth',
      });
    }
   
    if (firstLoad) setFirstLoad(false);
  };

  const handleScroll = () => {
    const container = refContainer?.current;
    const scrollTop = container?.scrollTop;
    if (scrollTop === 0 && Array.isArray(messages)) {
      if (messages.length < max) {
        setLoadingTopMessages(true);
        setPage(page + 1);

      }
    }
  };

  const handleMessage = ({ prompt, learnFromPrevResponse }) => {
    const data = {
      content: prompt,
      stream: false,
      conversation_id,
      learnFromPrevResponse
    }

    const avatarMessageData = {
      avatarMessage: true,
      assistant_id: conversationAvatar?.assistant_id,
      content: prompt,
      saveOnConversation: true,
      conversation_id,
    }

    if (conversationAvatar) {
      saveMessage(avatarMessageData, fetchMessages);
    } else {
      saveMessage(data, fetchMessages);
    }
  };

  const renderMax = () => {
    if (spinner) return <div className="spinner-border" />;
    if (Array.isArray(messages)) {
      if (messages.length >= max) {
        return (
          <div className="d-flex mt-3 justify-content-center">
            <span className="border bg-accent badge badge-pill mb-3 m-auto d-inline-block">
              {translations.conversation.max_messages}
            </span>
          </div>
        );
      }
    }
  };

  const renderMessages = () => {
    if (Array.isArray(messages)) {
      return messages.map((message, index) => {
        return (
          <MessageCard
            message={message}
            key={message.message_id}
            handleCallback={fetchMessages}
            prevMessage={messages[index - 1]}
          />
        );
      });
    }
  };

  const renderSpinner = () => {
    if (generating) {
      return (
        <LoadingCard text={translations.conversations.spinner} /> 
      );
    }
  };

  const renderContinue = () => {
    if (!generating && Array.isArray(messages)) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage && lastMessage !== null) {
        if (lastMessage.finish_reason === "length") {
          return (
            <button
              className="btn border"
              onClick={() => handleMessage("Continue generating last response")}
            >
              <i className="fa fa-forward me-2"></i>{" "}
              {translations.conversation.continue}
            </button>
          );
        }
      }
    }
  };

  const handleBack = () => {
    setPropertyConversation("showTemplates", false);
  };

  const renderContent = () => {
    let componentActive = true;
    if (conversation?.showTemplates && smallDevice) componentActive = false;

    if (!user.has_access) {
      return <ConversationUpgrade />;
    }

    if (componentActive) {
      return (
        <div
          className={`position-relative h-100 d-flex  flex-column flex-nowrap align-items-center px-0
          ${conversation?.showTemplates ? "col-12 col-md-6 " : "col-12"}  
          `}
          style={{
            order: 1,
          }}
        >
          <ConversationHeader titleGradient={titleGradient} />
          <div
            className="row overflow-hidden position-relative"
            style={{
              flex: 1,
            }}
          >
            <div id="messages" ref={refContainer} className="px-4 h-100">
              {renderMax()}
              {renderMessages()}
              {renderSpinner()}
              {renderContinue()}
            </div>
          </div>
          <MessageInputForm
            disableTemplatesBtn={disableTemplatesBtn}
            spinner={generating}
            handleSubmit={handleMessage}
            addGenerateBtn={addGenerateBtn}
            handleGenerateBtn={handleGenerateBtn}
          />
        </div>
      );
    }
  };

  const handleApply = (templateId) => {
    navigate(`/templates/apply-template/${templateId}/${conversation_id}`);
  };

  const fetchTemplates = (params) => {
    getPublicTemplates(params);
  };

  const renderTemplates = () => {
    if (conversation?.showTemplates) {
      return (
        <article
          className="h-100 br-10 bg-white position-relative px-3 py-3
          col-12 col-xl-5 conversation__templates-list"
          style={{
            order: 2,
          }}
        >
          <TemplateList
            title={translations.templates.title}
            templates={templates}
            handleApply={handleApply}
            fetchTemplates={fetchTemplates}
            handleBackBtn={handleBack}
            disableAddBtn
            filtersActive
            titleGradient
            backBtn={smallDevice}
          />
        </article>
      );
    }
  };

  return (
    <div
      id="conversation"
      onScroll={handleScroll}
      className=" h-100 d-flex row justify-content-around"
      style={{
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      {renderTemplates()}
      {renderContent()}
    </div>
  );
};

export default SingleConversation;
