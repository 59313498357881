import React, { useContext } from "react";
import { ConversationsContext } from "../../context/ConversationsContext";
import { ModalContext } from "../../context/ModalContext";
import ConversationForm from "./ConversationForm";
import useTranslations from "../../hooks/useTranslations";
import useWindowSize from "../../hooks/useWindowSize";

const ConversationHeader = ({ titleGradient }) => {
  const translations = useTranslations();
  const { conversation } = useContext(ConversationsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const isSmallDevice = useWindowSize(1200);

  const handleEdit = () => {
    modalComponent(
      translations.conversation.edit,
      <ConversationForm handleCancel={clearModal} />
    );
  };

  const renderName = () => {
    if (conversation && conversation !== null) {
      return (
        <h1
          className={`h5 bold ${titleGradient ? "text-gradient" : ""
            }  d-inline-block mb-0 w-100`}
          style={{
            textWrap: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
        >
          {conversation.name}
        </h1>
      );
    }
  };

  const renderActions = () => {
    if (conversation && conversation !== null) {
      return (
        <div>
          <button
            type="button"
            onClick={handleEdit}
            className="btn border btn-round btn-sm"
          >
            <i className="fa fa-edit"></i>
          </button>
        </div>
      );
    }
  };

  return (
    <div
      id="conversation-header"
      className="row mx-0 br-10 align-items-center px-2 shadow bg-white py-2"
      style={{
        height: !isSmallDevice ? '85px' : "60px"
      }}
    >
      <div className="col-10 ">{renderName()}</div>
      <div className="col-2  text-end">{renderActions()}</div>
    </div>
  );
};
export default ConversationHeader;
