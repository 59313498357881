import {
  STRIPE_PRODUCTS_RECEIVED,
  STRIPE_PRICES_RECEIVED,
} from "../types/stripes";

const stripesReducer = (state, { type, payload }) => {
  switch (type) {
    case STRIPE_PRODUCTS_RECEIVED:
      return { ...state, products: payload };
    case STRIPE_PRICES_RECEIVED:
      return { ...state, prices: payload };
    default:
      return { ...state };
  }
};

export default stripesReducer;
