import { useCallback, useContext, useEffect, useRef, useState } from "react";

import { createReactEditorJS } from "react-editor-js";
import {
  EDITOR_JS_TOOLS,
  parseBlock,
  htmlToBlocks,
} from "../utils/editor-tools";
import DocumentTools from "../components/common/DocumentTools";
import { SuperFetchContext } from "../context/SuperFetchContext";
import { DocumentsContext } from "../context/DocumentsContext";
import useWindowSize from "../hooks/useWindowSize";
import useFilesConverter from "../hooks/global/useFilesConverter";
import DocumentHeader from "../components/documents/DocumentHeader";
import { ModalContext } from "../context/ModalContext";
import "../css/custom.css";
import showdown from "showdown";
import { MessagesContext } from "../context/MessagesContext";

const ReactEditorJS = createReactEditorJS();
const defaultBlocks = [
  {
    id: "sheNwCUP5A",
    type: "header",
    data: {
      text: "Start writing here!",
      level: 2,
    },
  },
];

const SingleDocument = ({ documentId }) => {
  const [blocks, setBlocks] = useState([]);
  const [asideActive, setAsideActive] = useState(false);
  const { export2Doc, export2pdf } = useFilesConverter();

  const { outputs } = useContext(SuperFetchContext);
  const { alert } = useContext(ModalContext);
  const { threadMessages, runingThread } = useContext(MessagesContext);
  const {
    loadingOutputs,
    setLoadingOutputs,
    saveDocument,
    document: doc,
    getSingleDocument,
    setDocument,
    getDocumentConverted,
    documentBuffer,
    currentContent,
    setCurrentContent
  } = useContext(DocumentsContext);

  const editorCore = useRef(null);
  const smallDevice = useWindowSize(1200);

  const converter = new showdown.Converter({ tables: true, strikethrough: true, noHeaderId: true, tasklists: true });

  useEffect(() => {
    getSingleDocument(documentId);
    return () => {
      setDocument(null);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!smallDevice) {
      setAsideActive(true);
    } else {
      setAsideActive(false);
    }
  }, [smallDevice]);

  useEffect(() => {
    if (documentBuffer !== null) {
      export2pdf(documentBuffer);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentBuffer]);

  useEffect(() => {
    if (doc) {
      handleDocumentBlocks();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doc]);

  const handleDocumentBlocks = () => {
    if (doc.content?.length > 0) {
      setTimeout(() => {
        const currentBlocks = htmlToBlocks(doc.content);
        handleRender(currentBlocks);
      }, 100);
    } else {
      setTimeout(() => {
        handleRender(defaultBlocks);
      }, 100);
    }
  };

  useEffect(() => {
    if (outputs && outputs !== null && outputs.length > 0) {
      if (loadingOutputs) {
        setLoadingOutputs(false);

        const output = outputs[0].content[0].text.value;

        let currentParagraphs = converter.makeHtml(output);
        const newBlocks = [...blocks, ...htmlToBlocks(currentParagraphs)];

        handleRender(newBlocks);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outputs]);

  useEffect(() => {
    if (threadMessages && threadMessages !== null && threadMessages.length > 0) {
      const currentMessage = threadMessages[threadMessages.length -1]
      if(runingThread && currentMessage.role === 'assistant') {
        const output = currentMessage.content;
        let currentParagraphs = converter.makeHtml(output);
        const newBlocks = [...blocks, ...htmlToBlocks(currentParagraphs)];

        handleRender(newBlocks);
      }
    }
  }, [threadMessages]);

  const toggleAside = () => {
    setAsideActive(!asideActive);
  };

  const handleBlocksToHTML = (blocks) => {
    let newContent = "";
    blocks.forEach((block) => {
      newContent += parseBlock(block);
    });
    setCurrentContent(newContent);
  };

  const handleSaveDocumentContent = () => {
    if (currentContent.length < 65000) {
      saveDocument({ ...doc, content: currentContent });
    } else {
      alert("The file length is too large, please remove text to save");
    }
  };

  const createWord = () => {
    export2Doc("document");
  };

  const createPDF = () => {
    getDocumentConverted(documentId);
  };

  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const handleSave = useCallback(async () => {
    const savedData = await editorCore.current.save();
    setBlocks(savedData.blocks);
    handleBlocksToHTML(savedData.blocks);
  }, []);

  const handleRender = useCallback(async (newBlocks) => {
    await editorCore.current.render({ blocks: newBlocks });
    await handleSave();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDocumentTools = () => {
    if (asideActive) {
      return (
        <DocumentTools
          createPDF={createPDF}
          createWord={createWord}
          toggleAside={toggleAside}
          asideActive={asideActive}
          sideBarWidth={350}
        />
      );
    }
  };

  return (
    <div className="container-fluid py-3 d-flex flex-column h-100 bg-white rounded-3">
      <div
        className="row position-relative"
        style={{ flex: 1, overflow: "hidden" }}
      >
        <div
          className="h-100"
          style={{
            overflowY: "auto",
            flex: 1,
            padding: smallDevice ? "0" : "1rem",
          }}
        >
          <DocumentHeader
            doc={doc}
            handleSaveDocumentContent={handleSaveDocumentContent}
            smallDevice={smallDevice}
            toggleAside={toggleAside}
            asideActive={asideActive}
            title={doc?.name}
          />
          <div className="react-editor">
            <ReactEditorJS
              onChange={handleSave}
              onInitialize={handleInitialize}
              defaultValue={{ blocks }}
              tools={EDITOR_JS_TOOLS}
            />
          </div>
        </div>
        {renderDocumentTools()}
      </div>
    </div>
  );
};

export default SingleDocument;
