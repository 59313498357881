import { Link } from "@reach/router";
import CampaignsHandler from "./CampaignsHandler";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import getThumbnailUrl from "../../../utils/thumbnails";
import bunny from "../../../assets/bunny.png";
import { navigate } from "@reach/router";

const CampaignCard = ({ campaign }) => {
    const { user, user_organization } = useContext(AuthContext);
    const { handleEditCampaign, handleDeleteCampaign } = CampaignsHandler()
    const isSmallDevice = useWindowSize(1200)

    const handleAddUsers = () => {
        navigate(`/organization/campaigns/${campaign.campaign_id}/users`);
    }

    const renderCampaignButtons = () => {
        if (user.staff && user.staff !== null || user_organization != null && user_organization?.user_type?.name !== 'Member') {
            return (
                <div className="col-3 p-0 d-flex justify-content-end mt-2">
                    <button
                        id="navbarDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        className="btn"
                    >
                        <i className="fa fa fa-ellipsis-v"></i>
                    </button>
                    <ul className="dropdown-menu bg-white border" aria-labelledby="navbarDropdown">
                        <li className="p-2 ps-3 pe-3 dropdown-item text-dark cursor-pointer" onClick={() => handleEditCampaign(campaign)}>
                            <i className="fa fa-edit me-2"></i>Edit Campaign
                        </li>
                        <li className="p-2 ps-3 pe-3 dropdown-item text-dark cursor-pointer" onClick={() => handleDeleteCampaign(campaign)}>
                            <i className="fa fa-trash me-2"></i>Delete Campaign
                        </li>
                        <li className="p-2 ps-3 pe-3 dropdown-item text-dark cursor-pointer" onClick={handleAddUsers}>
                            <i className="fa fa-users me-2"></i>Add Users
                        </li>
                    </ul>
                </div>
            );
        }
        return null;
    };


    const getThumbnail = () => {
        return campaign.thumbnail != null ? getThumbnailUrl(campaign.thumbnail) : bunny
    }

    return (
        <div className="col-sm-6 col-md-4 mt-3">
            <div className="card mb-3 bg-light h-100 d-flex flex-column justify-content-between" style={{ padding: !isSmallDevice ? "1rem" : "1rem 1rem .5rem 1rem" }}>
                <img
                    src={getThumbnail()}
                    alt={`Thumbnail for Campaign #${campaign.campaign_id}`}
                    style={{
                        minHeight: "50%",
                        maxHeight: "50%",
                        maxWidth: "100%",
                        objectFit: 'cover',
                        borderRadius: 5
                    }}
                />
                <h4 className="text-capitalize mt-1 mb-0">{campaign.name}</h4>
                <p className="text-capitalize p-0 m-0 ellipsis">
                    <i className="fas fa-user me-2"></i>
                    {/* <span className="bold">Author:</span>  */}
                    {`${campaign.user.name} ${campaign.user.last_name}`}
                </p>
                <div className="row d-flex align-items-center">
                    <div className="col-9 p-0">
                        <Link to={`/organization/campaigns/${campaign.campaign_id}`}>
                            <button className="btn btn-primary w-100">
                                Open
                            </button>
                        </Link>
                    </div>
                    {renderCampaignButtons()}
                </div>
            </div>
        </div>
    );
};

export default CampaignCard;
