import React, { useContext, useEffect, useState } from "react";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import { ModalContext } from "../../../context/ModalContext";
import ImageInput from "../../common/ImageInput";
import getThumbnailUrl from "../../../utils/thumbnails";

const CampaignForm = ({ onSave, onCancel, campaignToEdit }) => {
    const [campaign, setCampaign] = useState(null);
    const [currentThumbnail, setCurrentThumbnail] = useState("");
    const { organization } = useContext(OrganizationsContext);
    const { alert } = useContext(ModalContext);

    useEffect(() => {
        if (campaignToEdit) {
            setCampaign(campaignToEdit);
            handleThumbnailChange(campaignToEdit.thumbnail);
        }
    }, [campaignToEdit]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCampaign({ ...campaign, [name]: value });
    };

    const handleThumbnailChange = (thumbnail) => {
        if (typeof thumbnail === 'string') {
            setCurrentThumbnail(getThumbnailUrl(thumbnail));
        } else {
            setCurrentThumbnail("");
        }
    };

    const handleImageChange = (value) => {
        setCampaign({ ...campaign, ["thumbnail"]: value });
        handleThumbnailChange(value);
    };

    const handleSave = () => {
        if (organization != null && organization.organization_id != null) {
            onSave({ ...campaign, organization_id: organization.organization_id });
        } else {
            alert("Please select an organization");
        }
    };

    return (
        <div>
            <form>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                        Campaign Name
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={campaign?.name || ""}
                        onChange={handleChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                        Description
                    </label>
                    <textarea
                        className="form-control"
                        name="description"
                        rows="3"
                        value={campaign?.description || ""}
                        onChange={handleChange}
                    ></textarea>
                </div>
                <div className="mb-5">
                    <label htmlFor="image" className="form-label">
                        Upload Image
                    </label>
                    <div className="d-flex align-items-center">
                        {currentThumbnail && (
                            <img
                                src={currentThumbnail}
                                alt={`Thumbnail for Campaign: ${campaign?.name}`}
                                style={{
                                    maxWidth: "25%",
                                    objectFit: "cover",
                                    marginRight: "1rem",
                                }}
                            />
                        )}
                        <ImageInput
                            value={campaign?.thumbnail}
                            modifier={(value) => handleImageChange(value)}
                        />
                    </div>
                </div>
                <div className="row">
                    <button type="button" className="col-12 col-md-6 btn" onClick={onCancel}>
                        Cancel
                    </button>
                    <button type="button" className="col-12 col-md-6 btn btn-primary" onClick={handleSave}>
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CampaignForm;
