import Embed from "@editorjs/embed";
import Table from "@editorjs/table";
import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
// import Warning from '@editorjs/warning'
// import Code from '@editorjs/code'
import LinkTool from "@editorjs/link";
// import Image from '@editorjs/image'
// import Raw from '@editorjs/raw'
import Header from "@editorjs/header";
// import Quote from '@editorjs/quote'
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import HTMLReactParser from "html-react-parser";
// import Delimiter from '@editorjs/delimiter'
// import InlineCode from '@editorjs/inline-code'
// import SimpleImage from '@editorjs/simple-image'

export const EDITOR_JS_TOOLS = {
  // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
  // paragraph: Paragraph,
  embed: Embed,
  table: Table,
  list: List,
  // warning: Warning,
  // code: Code,
  linkTool: LinkTool,
  image: Image,
  // raw: Raw,
  header: Header,
  // quote: Quote,
  marker: Marker,
  checklist: CheckList,
  // delimiter: Delimiter,
  // inlineCode: InlineCode,
  // simpleImage: SimpleImage,
};

const renderListItems = (items) =>
  items.map((item) => `<li>${item}</li>`).join("");

export const parseBlock = (block) => {
  if (block.type === "table") {
    const renderTd = (td) => td.map((td) => `<td>${td}</td>`).join("");
    const tableRows = block.data.content
      .map((tr) => `<tr>${renderTd(tr)}</tr>`)
      .join("");
    return `<table>${tableRows}</table>`;
  }

  if (block.type === "list") {
    if (block.data.style === "ordered") {
      return `<ol>${renderListItems(block.data.items)}</ol>`;
    } else {
      return `<ul>${renderListItems(block.data.items)}</ul>`;
    }
  }
  if (block.type === "quote") {
    return `<div ${block.data.alignment === "center" ? "className='text-center'" : ""
      }><quote>${block.data.text}</quote><p>${block.data.caption}</p></div>`;
  }
  if (block.type === "header") {
    return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`;
  }
  if (block.type === "paragraph") {
    return `<p>${block.data.text}</p>`;
  }
};

export const htmlToBlocks = (html) => {
  const regex =
    /(<(?:p|table|ol|ul|div|h[1-6])[^>]*>[\s\S]*?<\/(?:p|table|ol|ul|div|h[1-6])>)/g;
  const htmlBlocks = html.match(regex) || [];
  const blocks = [];

  htmlBlocks.forEach(htmlString => {
    if (htmlString.includes("<table>")) {
      let tableString = htmlString.replace(/\n/g, '').replace(/\s{2,}/g, ' ');

      const tableRegex = /<table>(.*?)<\/table>/;
      const rowRegex = /<tr>(.*?)<\/tr>/g;
      const cellRegex = /<t[dh]>(.*?)<\/t[dh]>/g;


      function cleanCellContent(cell) {
        return cell.replace(/<[^>]*>/g, '').trim();
      }

      let tableContent = [];

      const tableMatches = tableString.match(tableRegex);
      if (!tableMatches) return tableContent;
      const tableContentString = tableMatches[1];

      let rowMatches;
      while ((rowMatches = rowRegex.exec(tableContentString)) !== null) {
        const rowContent = rowMatches[1];
        let cells = [];

        let cellMatches;
        while ((cellMatches = cellRegex.exec(rowContent)) !== null) {
          const cellContent = cellMatches[1];
          const cleanedContent = cleanCellContent(cellContent);
          if (cleanedContent !== '') {
            cells.push(cleanedContent);
          }
        }

        if (cells.length > 0) {
          tableContent.push(cells);
        }
      }

      blocks.push({
        type: "table",
        data: {
          withHeadings: false,
          content: tableContent,
        },
      });

    } else if (/(<(?:h[1-6])[^>]*>[\s\S]*?<\/(?:h[1-6])>)/.test(htmlString)) {
      const headerRegex = /<h([1-6])>(.*?)<\/h[1-6]>/;
      const match = htmlString.match(headerRegex);

      if (!match) {
        return null;
      }

      const level = parseInt(match[1]);
      const text = match[2];

      blocks.push({
        type: "header",
        data: {
          text: text,
          level: level,
        },
      });
    } else {
      blocks.push({ type: "paragraph", data: { text: htmlString } });
    }
  });

  return blocks;
};

// export const htmlToBlocks = (html) => {
//   const htmlBlocks = HTMLReactParser(html);
//   if (!Array.isArray(htmlBlocks)) {
//     return [{ type: "paragraph", data: { text: htmlBlocks } }];
//   }
//   const blocks = [];
//   htmlBlocks.forEach((block) => {
//     if (block.type === "p") {
//       blocks.push({ type: "paragraph", data: { text: block.props.children } });
//     } else if (block.type[0] === "h") {
//       const level = block.type[1];
//       blocks.push({
//         type: "header",
//         data: {
//           text: block.props.children,
//           level,
//         },
//       });
//     } else if (block.type === "ol") {
//       blocks.push({
//         type: "list",
//         data: {
//           style: "ordered",
//           items: block.props.children.map(({ props }) => {
//             if (Array.isArray(props.children)) {
//               return props.children[0];
//             }
//             return props.children;
//           }),
//         },
//       });
//     } else if (block.type === "ul" && Array.isArray(block.props.children)) {
//       blocks.push({
//         type: "list",
//         data: {
//           style: "unordered",
//           items: block.props.children.map(({ props }) => props.children),
//         },
//       });
//     }
//   });
//   return blocks;
// };
