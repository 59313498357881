import React, { useRef, useState, useContext, useEffect } from "react";
import { TemplatesContext } from "../../context/TemplatesContext";
import useTranslations from "../../hooks/useTranslations";

const FieldCard = ({ field, fieldIndex }) => {
  const [isOpen, setIsOpen] = useState("false");

  const translations = useTranslations();
  const btnCollapseRef = useRef(null);

  const { template, setPropertyTemplate } = useContext(TemplatesContext);

  useEffect(() => {
    validateField();
  }, [field.options, field.label, field.type]);

  const collapseCard = () => {
    const isExpanded = btnCollapseRef?.current.getAttribute("aria-expanded");
    setIsOpen(isExpanded);
  };

  const addOption = () => {
    let newOptions = [
      ...field.options,
      {
        name: "",
        state: "valid",
        validating: false,
      },
    ];

    const newField = {
      ...field,
      options: newOptions,
    };

    handleChangeField(newField);
  };

  const changeFieldOptions = (newOpts) => {
    const newField = {
      ...field,
      options: newOpts,
    };

    handleChangeField(newField);
  };

  const deleteOption = (optIndex) => {
    const newOptionsArr = [...field.options];
    newOptionsArr.splice(optIndex, 1);

    changeFieldOptions(newOptionsArr);
  };

  const handleChangeLabel = (event) => {
    const label = event.target.value;

    const newField = {
      ...field,
      label,
      isValidLabel: true,
      invalidMax: false,
      invalidMin: false
    };  

    if(label.length <= 0) {
      newField.validLabel = false; 
      newField.invalidMin = true;
    }

    if(label.length > 254){
      newField.validLabel = false;
      newField.invalidMax = true;

      newField.label = field.label;
    } 

 

    handleChangeField(newField);
  };

  const handleFieldType = (fieldType) => {
    const newField = {
      ...field,
      type: fieldType,
    };

    if (field.options !== null) {
      field.options = String(field.options).split(",");
      if (fieldType === "select" && field.options.length <= 0) {
        newField.options = [
          {
            name: "",
            state: "valid",
            validating: false,
          },
        ];
      }
    }

    handleChangeField(newField);
  };

  const handleChangeOption = (event, optIndex) => {
    const newOptions = [...field.options];
    const currentOpt = newOptions[optIndex];
    currentOpt.name = event.target.value;
    currentOpt.validating = true;

    currentOpt.name.length > 0
      ? (currentOpt.state = "valid")
      : (currentOpt.state = "invalid");

    changeFieldOptions(newOptions);
  };

  const handleChangeField = (newFieldValue) => {
    const newFields = [...template.fields];
    newFields[fieldIndex] = newFieldValue;

    setPropertyTemplate("fields", newFields);
  };

  const validateField = () => {
    let isValid = true;
    let validOptionsLength = true;
    const updatedField = {
      ...template.fields[fieldIndex],
    };

    
    if (!field.isValidLabel) isValid = false;

    if (field.options !== null) {

      if (field.options.length > 0 && field.type === "select") {
        field.options.forEach((opt) => {
          if (opt.state !== "valid") {
            isValid = false;
          }
        });
      }

      if (field.options.length <= 0 && field.type === "select") {
        validOptionsLength = false;
        isValid = false;
      }
    }

    
    updatedField.isValidField = isValid;
    updatedField.validOptionsLength = validOptionsLength;
    handleChangeField(updatedField);
  };

  const renderFieldOptions = () => {
    if (field.options !== null && field.options.length > 0) {
      return field.options?.map((opt, optIndex) => {
        let invalidOption = false;
        if (opt.validating && opt.state === "invalid") invalidOption = true;

        if (typeof opt.name === "string") {
          return (
            <div className="d-flex flex-column w-100 mb-3" key={optIndex}>
              <div
                className={`input-group  w-100
              ${invalidOption ? "border border-danger" : ""}`}
              >
                <span
                  className={`input-group-text bg-primary
                  ${invalidOption ? "text-danger" : "text-dark"}`}
                >
                  {optIndex + 1}
                </span>

                <input
                  id={`input-option-${optIndex + 1}`}
                  type="text"
                  className={`form-control z-2 bg-white`}
                  aria-label={`option-${optIndex + 1}`}
                  aria-describedby={`basic-addon-${optIndex}`}
                  value={opt.name}
                  onChange={(event) => handleChangeOption(event, optIndex)}
                />

                <button
                  type="button"
                  className="btn btn-primary d-flex justify-content-center align-items-center"
                  style={{
                    width: "10%",
                  }}
                  onClick={() => deleteOption(optIndex)}
                >
                  <i
                    style={{ height: "max-content" }}
                    className=" fs-6 fa fa-times"
                  ></i>
                </button>
              </div>

              <span
                className={`text-danger 
                ${invalidOption ? "" : "d-none"}`}
              >
                The input can't be empty
              </span>
            </div>
          );
        }
      });
    }
  };

  return (
    <div
      className={`card bg-light
      ${field.isValidField ? "" : "border border-danger"}`}
    >
      <button
        ref={btnCollapseRef}
        onClick={collapseCard}
        className="btn position-relative "
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#fieldCollapse-${fieldIndex}`}
        aria-expanded="false"
        aria-controls={`fieldCollapse-${fieldIndex}`}
        style={{
          borderRadius: "8px",
          minHeight: "60px",
        }}
      >
        <h4 className="text-start m-0">{field.name}</h4>

        <i
          style={{ height: "max-content" }}
          className={`fa fa-chevron-${
            isOpen === "true" ? "up" : "down"
          } position-absolute 
          top-0 bottom-0 end-0 m-auto me-4`}
        ></i>
      </button>

      <div className="collapse" id={`fieldCollapse-${fieldIndex}`}>
        <div className="card-body">
          <section className="mb-3">
            <label>Field Label</label>
            <input
              type="text"
              value={field.label}
              className={`form-control bg-white ${
                field.isValidLabel ? "" : "border border-danger"
              }
              border`}
              onChange={handleChangeLabel}
            />

            <span
              className={`text-danger 
              ${!field.invalidMin ? "d-none" : ""}`}
            >
              The input can't be empty
            </span>

            <span
              className={`text-danger 
              ${!field.invalidMax ? "d-none" : ""}`}
            >
              The input max length is 254 characters
            </span>
          </section>

          <section className="d-flex flex-column mb-3">
            <label>Field Type</label>
            <div className="btn-group border br-25" role="group">
              <button
                type="button"
                onClick={() => handleFieldType("text")}
                className={`btn ${
                  field.type === "text" ? "btn-primary" : "bg-white"
                }`}
              >
                {translations.templates.field.types.text}
              </button>

              <button
                type="button"
                onClick={() => handleFieldType("number")}
                className={`btn ${
                  field.type === "number" ? "btn-primary" : "bg-white"
                }`}
              >
                {translations.templates.field.types.number}
              </button>

              <button
                type="button"
                onClick={() => handleFieldType("select")}
                className={`btn ${
                  field.type === "select" ? "btn-primary" : "bg-white"
                }`}
              >
                {translations.templates.field.types.select}
              </button>
            </div>
          </section>

          <section
            className={`mb-3 ${field.type === "select" ? "" : "d-none"}
            d-flex flex-column justify-content-around align-items-start`}
          >
            <label className="">Options</label>
            <span
              className={`text-danger mb-2
              ${field.validOptionsLength ? "d-none" : ""}`}
            >
              Select type must have at least one option
            </span>
            {renderFieldOptions()}

            <button
              type="button"
              className="btn btn-outline-primary w-25"
              onClick={addOption}
              style={{
                fontSize: "12px",
              }}
            >
              Add Option
            </button>
          </section>
        </div>
      </div>
    </div>
  );
};

export default FieldCard;
