

import React, { useContext, useEffect } from "react";
import { GroupsContext } from "../../../context/GroupsContext";
import { OrganizationsContext } from "../../../context/OrganizationsContext";

const GroupForm = ({ handleCancel, spinner }) => {
  const { group, setPropertyGroup, saveGroup } = useContext(GroupsContext);
  const { organization } = useContext(OrganizationsContext);

  useEffect(() => {
    const { organization_id } = organization;
    setPropertyGroup("organization_id", organization_id);
  }, []);

  const handleChangeGroup = (event) => {
    setPropertyGroup('name', event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    saveGroup(group);
  }

  return (
    <div className="px-0">
      <form onSubmit={handleSubmit} className="">
        
        <label>Group Name</label>
        <input 
          className="form-control" 
          value={group?.name} 
          onChange={handleChangeGroup}
        />

        <div className="row mt-3">
          <div className="col-6 ps-0">
            <button
              type="submit"
              disabled={spinner}
              className="btn btn-primary w-100"
            >
              {spinner ? <div className="spinner-border"></div> : "Save"}
            </button>
          </div>

          <div className="col-6 pe-0">
            <button
              type="button"
              onClick={handleCancel}
              className="btn btn-link w-100 text-muted"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>

     
    </div>
  );
};

export default GroupForm;
