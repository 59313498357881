import React, { useContext } from "react";
import { CampaignsContext } from "../../../context/CampaignsContext";
import { ModalContext } from "../../../context/ModalContext";
import CampaignResourceForm from "./CampaignResourceForm";

const CampaignResourceHandler = (filter) => {
    const { modalComponent, clearModal } = useContext(ModalContext);
    const { deleteCampaignResource, saveCampaignResource } = useContext(CampaignsContext);

    const resourceTitle = filter.slice(0, -1).charAt(0).toUpperCase() + filter.slice(1)

    const handleCancel = () => {
        clearModal();
    };

    const handleCreateCampaignResource = () => {
        modalComponent(
            `Add ${resourceTitle}`,
            <CampaignResourceForm
                onCancel={handleCancel}
                onSave={saveCampaignResource}
                resourceType={filter}
            />
        );
    };


    const handleDeleteCampaignResource = (content, type, goBack) => {

        const resource = content[type]
        const resource_id = resource[`${type}_id`]

        const onDelete = () => {
            deleteCampaignResource({ resource_id, campaign_id: content.campaign_id, type });
            if (goBack) {
                window.history.go(-1);
            }
        };

        modalComponent(
            `Delete ${resourceTitle} from the Campaign?`,
            <div className="w-100 d-flex mt-3">
                <button className="btn me-1 w-50" onClick={clearModal}>
                    Cancel
                </button>
                <button className="btn btn-danger ms-1 w-50 text-white" onClick={onDelete}>
                    {`Delete ${resourceTitle}`}
                </button>
            </div>
        );
    };

    return {
        handleCreateCampaignResource,
        handleDeleteCampaignResource,
    };
};

export default CampaignResourceHandler;
