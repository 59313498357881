import React, { useState, useContext, useEffect } from "react";
import { UserWorkflowsContext } from "../../context/UserWorkflowsContext";
import {
  getTemplatesFields,
  processFieldsToFormQuestioons,
  processObjectToValues,
} from "../../utils/templates";
import DynamicForm from "../global/DynamicForm";

const UserWorkflowFieldForm = ({ handleCancel }) => {
  const [formQuestions, setFormQuestions] = useState(null);
  const { user_workflow, setValueUserWorkflow, saveUserWorkflow } =
    useContext(UserWorkflowsContext);

  useEffect(() => {
    if (user_workflow && user_workflow !== null) {
      const { user_workflow_templates } = user_workflow;
      const templates = user_workflow_templates.map(({ template }) => template);
      const fields = getTemplatesFields(templates);
      const questions = processFieldsToFormQuestioons(fields);
      setFormQuestions(questions);
    }
  }, [user_workflow]);

  const handleSubmit = (values) => {
    values = processObjectToValues(values);
    saveUserWorkflow({ ...user_workflow, values });
  };

  const renderFormFields = () => {
    if (Array.isArray(formQuestions) && user_workflow !== null) {
      return (
        <DynamicForm
          questions={formQuestions}
          saveAction={handleSubmit}
          handleCancel={handleCancel}
          object={user_workflow.object}
          modifier={setValueUserWorkflow}
        />
      );
    }
  };

  return <div className="container-fluid px-0">{renderFormFields()}</div>;
};

export default UserWorkflowFieldForm;
