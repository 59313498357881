const products = {
  titleAdd: "Create Product",
  titleEdit: "Edit Product",
  name: "Product name",
  description: "Product Description",
  price: "Price",
  word_amount: "Word amount",
  short_description: "Short Description",
  sale_price: "Sale Price",
  is_subscription: "Is subscription?",
  expiration_days: "Expiration Days",
  subscription_period: "Subscription Period",
  subscription_interval: "Subscription Interval",
  limit_per_user: "Limit per User",
  free_trial_period: "Free Trial Period",
  free_trial_words: "Free Trial Words Amount",
  user_limit: "Purchases Limit",
  stripe_product_id: "Stripe Product Id",
  stripe_price_id: "Stripe Price Id",
  available: "Available",
  day: "Day(s)",
  year: "Year(s)",
  month: "Month(s)",
  invalid_name: "Product must have a name.",
  invalid_description: "Product must have a description.",
  invalid_price: "Product must have a valid price greater than 0.",
  invalid_word_amount: "Product must have a valid word amount greater than 0.",
  invalid_stripe_price_id:
    "If stripe_product_id is provided, stripe_price_id must also be provided.",
  invalid_subscription_interval: "If stripe_price_id is provided, subscription_interval must also be provided.",
  invalid_subscription_period: "If stripe_price_id is provided, subscription_period must also be provided."
};

export default products;
