import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import SecureInput from "./SecureInput";

const AdvancedSection = () => {
  const {
    reset_password_email_webhook_url,
    welcome_email_webhook_url,
    stripe_public_key,
    stripe_private_key,
    stripe_webhook_key,
    openai_secret_key,
    openai_model,

    setAppConfigProperty,
  } = useContext(AppConfigContext);

  return (
    <div
      className="container-fluid d-flex flex-column pb-3"
      style={{ height: "calc(100% - 40px)", overflowY: "auto" }}
    >
      <h4>Open AI</h4>
      <div className="row mb-3 mt-3">
        <div className="col-12 col-md-6 ps-0">
          <SecureInput
            smallTitle
            type={"text"}
            hideValue
            label={"API Key"}
            value={openai_secret_key}
            handleChange={(event) => {
              setAppConfigProperty("openai_secret_key", event.target.value);
            }}
          />
        </div>
        <div className="col-12 col-md-6 pe-0">
          <SecureInput
            smallTitle
            type={"text"}
            hideValue
            label={"Model"}
            value={openai_model}
            handleChange={(event) => {
              setAppConfigProperty("openai_model", event.target.value);
            }}
          />
        </div>
      </div>

      <h4>Stripe</h4>
      <div className="row mb-3">
        <div className="col-12 col-md-6 ps-0">
          <SecureInput
            smallTitle
            type={"text"}
            hideValue
            label={"Public Key"}
            value={stripe_public_key}
            handleChange={(event) => {
              setAppConfigProperty("stripe_public_key", event.target.value);
            }}
          />
        </div>
        <div className="col-12 col-md-6 pe-0">
          <SecureInput
            smallTitle
            type={"text"}
            hideValue
            label={"Private Key"}
            value={stripe_private_key}
            handleChange={(event) => {
              setAppConfigProperty("stripe_private_key", event.target.value);
            }}
          />
        </div>
      </div>

      <div className="row mb-3">
        <SecureInput
          smallTitle
          type={"text"}
          hideValue
          label={"Webhook Key"}
          value={stripe_webhook_key}
          handleChange={(event) => {
            setAppConfigProperty("stripe_webhook_key", event.target.value);
          }}
        />
      </div>

      <h4>Lead Connector</h4>

      <div className="row mb-3">
        <div className="col-12 col-md-6 ps-0">
          <SecureInput
            smallTitle
            type={"text"}
            hideValue
            label={"Welcome Email Webhook URL"}
            value={welcome_email_webhook_url}
            handleChange={(event) => {
              setAppConfigProperty(
                "welcome_email_webhook_url",
                event.target.value
              );
            }}
          />
        </div>
        <div className="col-12 col-md-6 pe-0">
          <SecureInput
            smallTitle
            type={"text"}
            hideValue
            label={"Reset Password Email Webhook URL"}
            value={reset_password_email_webhook_url}
            handleChange={(event) => {
              setAppConfigProperty(
                "reset_password_email_webhook_url",
                event.target.value
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AdvancedSection;
