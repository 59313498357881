

import { Link } from "@reach/router";
import React, { useContext, useEffect } from "react";
import { ModalContext } from "../../context/ModalContext";
import { DocumentsContext } from "../../context/DocumentsContext";
import useAppInputs from "../../hooks/global/useAppInputs"
import { AuthContext } from "../../context/AuthContext";

const inputsForm = [
  {
    label: 'Name',
    value: '',
    type: 'text',
    isValid: true,
  },
  {
    label: 'Tags',
    value: '',
    type: 'tag',
    isValid: true,
    required: false
  },
]

const DocumentForm = () => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { setDocument, saveDocument, document, setPropertyDocument } = useContext(DocumentsContext);
  const { user } = useContext(AuthContext);


  const { renderAllInputs, inputs, validateInputs } = useAppInputs(inputsForm, 12);


  useEffect(() => {
    
    return () => {
      setDocument(null);
    }
  }, []);


  useEffect(() => {
    if(document !== null) {
      inputsForm[0].value = document?.name ? document.name : '';
      inputsForm[1].value = document?.tags ? document.tags : '';
    }
  }, [document]);

  useEffect(() => {
    setPropertyDocument("name", inputs[0].value);
    setPropertyDocument("tags", inputs[1].value);
  }, [inputs]);


  const handleCancel = () => {
    setDocument(null);
    clearModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validInputs = validateInputs();

    if(validInputs) {
      saveDocument(document);
    }
  }


  return (
    <form onSubmit={handleSubmit} className="px-0">
      {renderAllInputs()}

      <div className="row mt-3">
        <div className="col-6">
          {" "}
          <button
            type="button"
            onClick={handleCancel}
            className="btn btn-link w-100 text-muted"
          >
            Cancel
          </button>
        </div>

        <div className="col-6">
          <button
            type="submit"
            className="btn btn-primary w-100"
          >
            {"Save"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default DocumentForm;
 