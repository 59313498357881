const campaigns = {
  title: "Campaigns",
  search: "Search Campaigns...",
  noCampaigns: "No campaigns found in this organization",
  button: "Add",
  giveAccess: "Give access to the campaign",
  revokeAccess: "Remove access to the campaign",
};

export default campaigns;
