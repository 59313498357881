const admin = {
  users: {
    delete: "Eliminar Cliente",
    giveAccess: "Agregar Acceso",
    generateLink: "Generar Link",
    sendEmail: "Enviar Correo",
    loginAccount: "Iniciar Sesión",
    loginAccountButton: "Entrar",
    recoveryPass: "Reestablecer Contraseña",
    totalPurchases: "Compras Totales",
    edit: "Editar información",
    customer: "Cliente",
    purchases: "Compras",
    invoices: "Cargos",
    all: "Todo",
    titleEdit: "Editar Cliente",
    titleAdd: "Agregar Cliente",
    addBtn: "Agregar",
    saveBtn: "Guardar",
    cancelBtn: "Cancelar",
    name: "Nombre",
    lastName: "Apellido",
    email: "Correo Electrónico",
    phone: "Teléfono",
    publicity: "¿Cómo te enteraste de nosotros?",
    role: "Rol",
    searchBy: "Buscar usuario por nombre o correo",
    search: "Buscar...",
    addUser: "Agregar Usuario",
    editUser: "Editar Usuario",
    deleteUser: "Eliminar Usuario",
    deleteLabel: "Esta seguro de eliminar el siguiente usuario:",
    deleteBtn: "Eliminar",
  },
};
export default admin;
