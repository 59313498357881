import api from "./api";
import { getArgs } from "../utils";

const route = "/templates";

export default {
  getUserTemplates: () => api.get(`${route}`),
  getAllTags: (filters) => api.get(`${route}/tags?${getArgs(filters)}`),
  getAllTypes: (filters) => api.get(`${route}/types?${getArgs(filters)}`),
  getTemplates: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getAllTemplates: (filters) => api.get(`${route}/all?${getArgs(filters)}`),
  getPublicTemplates: (filters) =>
    api.get(`${route}/public?${getArgs(filters)}`),
  getTemplatesByTag: (tag, filters) =>
    api.get(`${route}/tag/${tag}?${getArgs(filters)}`),
  getTemplatesByType: (type, filters) =>
    api.get(`${route}/type/${type}?${getArgs(filters)}`),
  getSingleTemplate: (template_id) => api.get(`${route}/${template_id}`),
  postTemplate: (template) => api.post(route, { ...template }),
  putTemplate: (template) => api.put(route, { ...template }),
  favoriteTemplate: (template_id) =>
    api.post(`${route}/favorite`, { template_id }),
  deleteTemplate: (Template) => api.delete(`${route}/${Template}`),
};
