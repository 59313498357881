import React, { useContext, useEffect } from "react";
import useTranslations from "../../hooks/useTranslations";
import { ModalContext } from "../../context/ModalContext";

const SelectLanguage = () => {
  const { lang, setLang, general, setDefaultLang } = useTranslations();

  const { setTitle, clearModal } = useContext(ModalContext);

  useEffect(() => {
    setDefaultLang()
  }, []);

  useEffect(() => {
    setTitle(general.language);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  return (
    <div className="container-fluid px-0">
      <div className="row mb-3">
        <div className="col">
          <div
            onClick={() => setLang("en")}
            className={`btn ${
              lang === "en" ? "btn-accent" : "border"
            } card p-3 text-center shadow`}
          >
            <h3 className={lang === "en" ? "text-white" : ""}>English</h3>
            {lang === "en" ? <i className="fa fa-check-circle" /> : <p></p>}
          </div>
        </div>
        <div className="col">
          <div
            onClick={() => setLang("es")}
            className={`btn ${
              lang === "es" ? "btn-accent" : "border"
            } card p-3 text-center shadow`}
          >
            <h3 className={lang === "es" ? "text-white" : ""}>Español</h3>
            {lang === "es" ? <i className="fa fa-check-circle" /> : <p></p>}
          </div>
        </div>
      </div>
      <button onClick={clearModal} className="btn btn-primary w-100">{general.save}</button>
    </div>
  );
};

export default SelectLanguage;
