
import {
  SET_WORKFLOW,
  CREATE_WORKFLOW,
  WORKFLOWS_RECEIVED,
  SET_PROPERTY_WORKFLOW,
} from "../types/workflows";

const schema = {

}

const workflowsReducer = (state, { type, payload }) => {
  switch (type) {
    case WORKFLOWS_RECEIVED:
      return { ...state, workflows: payload };
    case SET_WORKFLOW:
      return { ...state, workflow: payload };
    case CREATE_WORKFLOW:
      return { ...state, workflow: schema };
    case SET_PROPERTY_WORKFLOW: {
      const { key, value } = payload;
      const workflow = { ...state.workflow };
      workflow[key] = value;
      return { ...state, workflow };
    }
    default:
      return { ...state};
  }
};

export default workflowsReducer;
