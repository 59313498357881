import React from "react";
import { Link } from "@reach/router";
import { formatMonto } from "../../utils";
import ProductTestPurchase from "./ProductTestPurchase";
import { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";

const ProductRow = ({ product, extraFields }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleTest = () => {
    modalComponent(
      "Test Product Purchase",
      <ProductTestPurchase
        product_id={product.product_id}
        handleCancel={clearModal}
      />
    );
  };

  const renderExtraFields = () => {
    if (Array.isArray(extraFields)) {
      return extraFields.map((field) => <td>{product[field.key]}</td>);
    }
  };

  const renderTest = () => {
    const { webhook_url } = product;
    if (webhook_url && webhook_url !== null) {
      return (
        <button onClick={handleTest} className="btn btn-link btn-sm text-accent">
          <i className="fas fa-vial" /> Test
        </button>
      );
    }
  };

  return (
    <tr className="p-2 border-bottom small align-middle hover-light">
      <td className="td-id">{product.product_id}</td>
      <td>
        <Link to={`${product.product_id}/edit`}>
          <i className="fa fa-eye me-2"></i>
          {product.name}
        </Link>
      </td>
      <td>{product.word_amount === null ? <span className="text-primary small">Unlimited</span> : formatMonto(product.word_amount)}</td>
      <td>${formatMonto(product.price)}</td>
      {renderExtraFields()}
      <td>{renderTest()}</td>
    </tr>
  );
};

export default ProductRow;
