
import React, { createContext, useReducer, useContext } from 'react';
import GroupsService from '../services/GroupsService';
import GroupsReducer from '../reducers/GroupsReducer';
import {
  GROUPS_RECEIVED,
  SET_GROUP,
  CREATE_GROUP,
  SET_PROPERTY_GROUP,
} from "../types/groups";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";

const initialState = {
  groups: null,
  group: {
    name: ''
  },
};

export const GroupsContext = createContext(initialState);

export const GroupsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GroupsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const getGroupsByOrganization = (organization_id) => {
    GroupsService.getOrganizationGroups(organization_id)
      .then((response) => {
        const { groups } = response.data;
        dispatch({ type: GROUPS_RECEIVED, payload: groups });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleGroup = (group_id) => {
    GroupsService.getSingleGroup(group_id)
      .then((response) => {
        const { group } = response.data;
        dispatch({ type: SET_GROUP, payload: group });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setGroup = (group) => {
    dispatch({ type: SET_GROUP, payload: group });
  };

  const createGroup = () => {
    dispatch({ type: CREATE_GROUP });
  };

  const setPropertyGroup = (key, value) => {
    dispatch({ type: SET_PROPERTY_GROUP, payload: { key, value } });
  };

  const saveGroup = (group, callback) => {
      dispatch({ type: SHOW_SPINNER });
      let service = GroupsService.putGroup;

      if(isNaN(parseInt(group.group_id))) {
        service = GroupsService.postGroup;
      }

      service(group).then((res) => {
        clearModal();
        setGroup(res.data.group);
        getGroupsByOrganization(group.organization_id);

        dispatch({ type: HIDE_SPINNER });
        success("Group saved.");
        if(typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const addUserToGroup = (user_id, group_id) => {
    return new Promise((resolve, reject) => {
      GroupsService.addUserToGroup(user_id, group_id)
      .then(res => {
        success("User added.");
        getSingleGroup(group_id);
        resolve();
      })
      .catch(err => {
        console.log(err);
        reject(err)
      });
    });
  }

  const deleteGroup = (group, callback) => {
    const { group_id, organization_id } = group;
    dispatch({ type: SHOW_SPINNER });

    GroupsService.deleteGroup(group_id).then(() => {
      getGroupsByOrganization(organization_id);
      success("Group deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();

      if(typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

  const deleteUserFromGroup = (user_id, group_id) => {
    return new Promise((resolve, reject) => {
      GroupsService.deleteUserFromGroup(user_id, group_id)
      .then(res => {
        success("User deleted");
        getSingleGroup(group_id);
        resolve();
      })
      .catch(err => {
        console.log(err);
        reject(err)
      });
    });
  }

 

  return (
    <GroupsContext.Provider
      value={{
        ...state,
        setGroup,
        getGroupsByOrganization,
        saveGroup,
        deleteGroup,
        createGroup,
        getSingleGroup,
        setPropertyGroup,
        addUserToGroup,
        deleteUserFromGroup
      }}
    >
      {children}
    </GroupsContext.Provider>
  );
};
