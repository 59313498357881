export const processFieldsFromTemplate = (template) => {
  const { content } = template;
  const regex = /\[[^\]]*\]/g;
  const fields = String(content).match(regex);
  const fieldResult = [];
  fields.forEach((field) => {
    field = String(field);
    field = field.replace(/[\[\]']+/g, "");
    fieldResult.push(field);
  });
  return fieldResult;
};

export const processOptionsToField = (field, options) => {
  let optionResult = options.join("/");
  return `${field}[${optionResult}]`;
};

export const getTemplatesFields = (templates) => {
  let fieldsResult = [];
  if (Array.isArray(templates)) {
    templates.forEach(({ fields }) => {
      let fieldSplit = String(fields).split(",");
      fieldSplit.forEach((singleField) => {
        fieldsResult.push(singleField);
      });
    });
  }
  return fieldsResult;
};

export const processFieldsToFormQuestioons = (fields) => {
  const regex = /\[[^\]]*\]/g;
  let questions = [];
  if (Array.isArray(fields)) {
    fields.forEach((field) => {
      if (String(field).includes("[")) {
        let options = String(String(field).match(regex)[0]);
        options = options.replace(/[\[\]']+/g, "");
        options = options.split("/");
        const start = field.indexOf("[") + 1;
        field = field.substring(0, start - 1);
        questions.push({
          id: field,
          label: field,
          type: "select",
          required: true,
          options: options.map((option) => ({
            value: option,
            name: option,
            label: option,
          })),
        });
      } else {
        questions.push({
          id: field,
          label: field,
          type: "text",
          required: true,
        });
      }
    });
  }
  //Remove duplicate questions by using a set
  let questionsSet = new Set();
  questions.forEach((question) => {
    questionsSet.add(question.id);
  });
  let questionsResult = [];
  questionsSet.forEach((questionId) => {
    //Prioritize questions with options over open ended questions
    const optionsQuestion = questions.find(
      ({ id, options }) => id === questionId && options
    );
    if (optionsQuestion !== undefined) {
      questionsResult.push(optionsQuestion);
    } else {
      const currentQuestion = questions.find(({ id }) => id === questionId);
      if (currentQuestion !== undefined) questionsResult.push(currentQuestion);
    }
  });
  return questionsResult;
};

export const processValuesToObject = (values) => {
  values = String(values).split(",");
  let object = {};
  values.forEach((value) => {
    if (value && value !== null) {
      value = String(value);
      let valueSplit = value.split("=");
      if (valueSplit.length > 1) {
        object[valueSplit[0]] = valueSplit[1];
      }
    }
  });
  return object;
};

export const processObjectToValues = (object) => {
  let valuesArray = [];
  Object.keys(object).forEach((key) => {
    if (object[key] !== undefined && object[key] !== null) {
      valuesArray.push(`${key}=${object[key]}`);
    }
  });
  return valuesArray.join(",");
};

export const processFieldName = (field) => {
  field = field.replaceAll(" ", "_");
  field = field.replaceAll(":", "_");
  return field;
};

export const processValuesToPrompt = (template, templateFields) => {
  let currentPrompt = template.content;
  String(template.fields)
    .split(",")
    .forEach((key) => {
      let trimKey = String(key).trim();
      trimKey = processFieldName(trimKey);
      if (trimKey.indexOf("[") !== -1) {
        const start = trimKey.indexOf("[") + 1;
        trimKey = trimKey.substring(0, start - 1);
        const keyIndex = key.indexOf("[");
        key = key.substring(0, keyIndex);
      }
      let currentValue = templateFields[trimKey];
      const stringToReplace = `[${key}]`;
      currentPrompt = String(currentPrompt).replaceAll(
        stringToReplace,
        currentValue
      );
    });
  return currentPrompt;
};
