import React, { useContext, useEffect } from "react";
import Sorry from "./Sorry";
import Upgrade from "./Upgrade";
import Avatars from "./Avatars";
import Profile from "./Profile";
import Settings from "./Settings";
import ThankYou from "./ThankYou";
import Admin from "./admin/Admin";
import Templates from "./Templates";
import Tutorials from "./Tutorials";
import Purchases from "./Purchases";
import SingleAvatar from "./SingleAvatar";
import Conversations from "./Conversations";
import SingleTutorial from "./SingleTutorial";
import TopicsGenerator from "./TopicsGenerator";
import HashtagGenerator from "./HashtagGenerator";
import Sidebar from "../components/global/Sidebar";
import { Router, useLocation } from "@reach/router";
import { AuthContext } from "../context/AuthContext";
import SingleConversation from "./SingleConversation";
import MenuMobile from "../components/navbar/MenuMobile";
import TemplateTags from "./TemplateTags";
import PublicTemplates from "./PublicTemplates";
import TemplateTypes from "./TemplateTypes";
import Workflows from "./Workflows";
import UserWorkflows from "./UserWorkflows";
import SingleUserWorkflow from "./SingleUserWorkflow";
import SingleWorkflow from "./SingleWorkflow";
import SingleWorkflowConversation from "./SingleWorkflowConversation";
import BreadcrumbNav from "../components/global/BreadcrumbNav";
import SingleTemplate from "./SingleTemplate";
import TemplateApply from "./TemplateApply";
import SuperFetch from "./SuperFetch";
import Documents from "./Documents";
import SingleDocument from "./SingleDocument";

import useTranslations from "../hooks/useTranslations";
import TemplateApplyAvatar from "./TemplateApplyAvatar";
import ChatWidgetGenerator from "./ChatWidgetGenerator";

import Campaigns from "./organizations/campaign/Campaigns";
import OrganizationGroups from "./organizations/OrganizationGroups/OrganizationGroups";
import SingleCampaign from "./organizations/campaign/SingleCampaign";
import Organizations from "./organizations/organizations/Organizations";
import OrganizationPanel from "./organizations/OrganizationPanel/OrganizationPanel";
import OrganizationUserForm from "../components/organization/organizations/OrganizationUserForm";
import OrganizationSettings from "./organizations/organizations/OrganizationSettings";
import SingleOrganizationGroup from "./organizations/OrganizationGroups/SingleOrganizationGroup";
import OrganizationUser from "./organizations/OrganizationPanel/OrganizationUser";
import OrganizationGroupUser from "./organizations/OrganizationGroups/OrganizationGroupUser";
import CampaignDocumentOutput from "./CampaignDocumentOutput";
import CampaignUsers from "./organizations/campaign/CampaignUsers";
import { CapacitorContext } from "../context/CapacitorContext";

const Panel = () => {
  const location = useLocation();
  const { getCurrentUser } = useContext(AuthContext);
  const { platform } = useContext(CapacitorContext);
  const translations = useTranslations();

  useEffect(() => {
    getCurrentUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

 

  return (
    <div
      className={`position-relative container-fluid px-0 px-md-3 d-flex flex-row align-items-center overflow-hidden`}
      style={{
        height: platform === "web" ? "100%" : "calc(100% - 47px)",
        marginTop: platform === "web" ? "" : "47px",
      }}
    >
      <Sidebar />
      <MenuMobile />
      <div className="position-relative content overflow-hidden row flex-column px-0 me-0 flex-nowrap">
        <BreadcrumbNav />
        <Router
          className="position-relative px-0 overflow-hidden card shadow-sm bg-white pt-5 pb-3 pt-md-3 rounded-3"
          style={{ flex: 1 }}
        >
          <Sorry path="/sorry" />
          <Admin path="/admin/*" />
          <Upgrade path="/upgrade" />
          <Profile path="/profile" />
          <Conversations path="/conversation/tags/:tag" />
          <Conversations path="/*" />
          <SuperFetch path="/super-fetch" />
          <Avatars path="/avatars" />
          <Settings path="/settings" />
          <Purchases path="/billing" />
          <Tutorials path="/tutorials" />
          <ChatWidgetGenerator path="/chat-widget-generator" />

          {/* Documents */}
          <Documents path="/documents" />
          <SingleDocument path="/documents/:documentId" />
          <CampaignDocumentOutput path="campaign/:campaignId/documents/:documentId" />

          {/* Workflows */}
          <Workflows path="/workflows" />
          <SingleWorkflow path="/workflows/:workflow_id" />

          {/* User Workflows */}
          <UserWorkflows path="/user_workflows" />
          <SingleUserWorkflow path="/user_workflows/:user_workflow_id" />
          <SingleWorkflowConversation path="/user_workflows/:user_workflow_id/conversation/:conversation_id" />

          {/* Templates */}
          <Templates path="/templates" />
          <TemplateTags path="/templates/tags" />
          <TemplateTypes path="/templates/types" />
          <SingleTemplate
            path="/templates/create-template"
            title={translations?.templates?.create_template}
          />
          <SingleTemplate
            path="/templates/edit-template/:idTemplate"
            title={translations?.templates?.card.edit}
          />
          <TemplateApply path="/templates/apply-template/:idTemplate/:idConversation" />
          <TemplateApplyAvatar path="/templates/apply-avatar/:idTemplate/:idAvatar" />
          <PublicTemplates size="lg" path="/templates/tags/:tag" />
          <PublicTemplates size="lg" path="/templates/types/:type" />

          <TopicsGenerator path="/topics" />
          <HashtagGenerator path="/hashtags" />
          <ThankYou path="/thankyou/:purchase_id" />
          <SingleAvatar path="/avatars/:avatar_id/*" />
          <SingleTutorial path="/tutorials/:tutorial_id" />
          <SingleConversation
            titleGradient
            path="/conversations/:conversation_id"
          />

          {/* Organization */}
          <OrganizationPanel path="/organization/admin-panel" />
          <OrganizationUser path="/organization/admin-panel/users/:userId" />
          <OrganizationUserForm path="/organization/admin-panel/add-user" />

          <Campaigns path="/organization/campaigns" />
          <SingleCampaign path="/organization/campaigns/:campaign_id" />
          <CampaignUsers path="/organization/campaigns/:campaign_id/users" />

          <Organizations path="admin/organizations/" isAdminView={true} />
          <Organizations path="/organizations/" />
          <OrganizationSettings path="/organizations/organization-settings/:organization_id" />

          <OrganizationGroups path="/organization/groups" />
          <SingleOrganizationGroup path="/organization/groups/:groupId" />
          <OrganizationGroupUser path="/organization/groups/:groupId/users/:userId" />
        </Router>
      </div>
    </div>
  );
};

export default Panel;
