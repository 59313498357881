const products = {
  titleAdd: "Crear Producto",
  titleEdit: "Editar Producto",
  name: "Nombre del Producto",
  description: "Descripción del Producto",
  price: "Precio",
  word_amount: "Cantidad de Palabras",
  short_description: "Descripción Corta",
  sale_price: "Precio de oferta",
  is_subscription: "¿Es cargo recurrente?",
  expiration_days: "Dias de Expiración",
  subscription_period: "Periodo de cargos",
  limit_per_user: "Limite por usuario",
  free_trial_period: "Periodo de prueba gratis",
  user_limit: "Limite de Compras",
  stripe_product_id: "ID de Producto de Stripe",
  stripe_price_id: "ID de Precio de Stripe",
  available: "Disponible",
  invalid_name: "El producto debe tener un nombre.",
  invalid_description: "El producto debe tener una descripción.",
  invalid_price: "El producto debe tener un precio válido mayor a 0.",
  invalid_word_amount:
    "El producto debe tener una cantidad válida de palabras mayor a 0.",
  invalid_stripe_price_id:
    "Si se proporciona stripe_product_id, también debe proporcionar stripe_price_id.",
  invalid_subscription_interval: "Si se proporciona stripe_price_id, también debe proporcionar subscription_interval.",
  invalid_subscription_period: "Si se proporciona stripe_price_id, también debe proporcionar subscription_period."
};

export default products;
