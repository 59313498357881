import React, { useEffect, useState } from "react";

const useWindowSize = (smallSize) => {
  const [smallDevice, setSmallDevice] = useState(false);

  useEffect(() => {
    handleWindowSize();
    window.addEventListener("resize", handleWindowSize);

    return () => {
      window.removeEventListener("resize", handleWindowSize);
    }
  }, []);

  const handleWindowSize = () => {
    if (window.screen.width <= smallSize) {
      setSmallDevice(true);
    } else {
      setSmallDevice(false);
    }
  };


  return smallDevice;
}

export default useWindowSize;