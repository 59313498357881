import admin from "./admin";
import auth from "./auth";
import avatar from "./avatar";
import avatars from "./avatars";
import campaigns from "./campaigns";
import conversation from "./conversation";
import conversations from "./conversations";
import documents from "./documents";
import general from "./general";
import generator from "./generator";
import invoices from "./invoices";
import messages from "./messages";
import organization from "./organization";
import outputs from "./outputs";
import purchases from "./purchases";
import superfetch from "./superfetch";
import templates from "./templates";
import trainings from "./trainings";
import user_workflows from "./user_workflows";
import workflows from "./workflows";
import products from "./products";

const en = {
  products,
  openai: {
    error: 'Sorry, OpenAI is having problems with your request.'
  },
  menu: {
    settings: "Settings",
    logout: "Log Out",
  },
  form: {
    cancel: "Cancel",
    confirm: "Save",
  },
  topics: {
    title: "Trending Topics Generator",
  },
  hashtags: {
    title: "Hashtags Generator",
  },
  user_avatars: {
    title: "My Avatars",
  },
  user_workflows,
  conversations,
  conversation,
  organization,
  superfetch,
  templates,
  trainings,
  documents,
  campaigns,
  workflows,
  generator,
  invoices,
  purchases,
  messages,
  outputs,
  general,
  avatars,
  avatar,
  admin,
  auth
};

export default en;
