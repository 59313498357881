
export const DOCUMENTS_RECEIVED = "DOCUMENTS_RECEIVED";
export const SET_DOCUMENT = "SET_DOCUMENT";
export const SET_CURRENT_CONTENT = "SET_CURRENT_CONTENT";
export const SET_DOCUMENT_BLOCKS = "SET_DOCUMENT_BLOCKS";
export const SET_CAMPAIGN_DOCUMENT = "SET_CAMPAIGN_DOCUMENT";
export const SET_DOCUMENT_BUFFER = "SET_DOCUMENT_BUFFER";
export const SET_LOADING_OUTPUTS = "SET_LOADING_OUTPUTS";
export const CREATE_DOCUMENT = "CREATE_DOCUMENT";
export const SET_PROPERTY_DOCUMENT = "SET_PROPERTY_DOCUMENT";
export const USER_AVATAR_DOCUMENT_OUTPUT = "USER_AVATAR_DOCUMENT_OUTPUT";
export const SET_CONTEXT_AVATAR_OUTPUT = "SET_CONTEXT_AVATAR_OUTPUT";
