import React, { createContext, useReducer, useContext } from "react";
import UserWorkflowsService from "../services/UserWorkflowsService";
import UserWorkflowsReducer from "../reducers/UserWorkflowsReducer";
import {
  SET_USERWORKFLOW,
  CREATE_USERWORKFLOW,
  USERWORKFLOWS_RECEIVED,
  SET_PROPERTY_USERWORKFLOW,
  SET_VALUE_USERWORKFLOW,
} from "../types/user_workflows";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import useTranslations from "../hooks/useTranslations";

const initialState = {
  user_workflows: null,
  user_workflow: null,
};

export const UserWorkflowsContext = createContext(initialState);

export const UserWorkflowsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(UserWorkflowsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const translations = useTranslations();

  const getUserWorkflows = () => {
    UserWorkflowsService.getUserWorkflows()
      .then((response) => {
        const { user_workflows } = response.data;
        dispatch({ type: USERWORKFLOWS_RECEIVED, payload: user_workflows });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleUserWorkflow = (user_workflow_id) => {
    UserWorkflowsService.getSingleUserWorkflow(user_workflow_id)
      .then((response) => {
        const { user_workflow } = response.data;
        dispatch({ type: SET_USERWORKFLOW, payload: user_workflow });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setUserWorkflow = (user_workflow) => {
    dispatch({ type: SET_USERWORKFLOW, payload: user_workflow });
  };

  const createUserWorkflow = () => {
    dispatch({ type: CREATE_USERWORKFLOW });
  };

  const setPropertyUserWorkflow = (key, value) => {
    dispatch({ type: SET_PROPERTY_USERWORKFLOW, payload: { key, value } });
  };

  const setValueUserWorkflow = (key, value) => {
    dispatch({ type: SET_VALUE_USERWORKFLOW, payload: { key, value } });
  };

  const saveUserWorkflow = (user_workflow, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = UserWorkflowsService.putUserWorkflow;
    if (isNaN(parseInt(user_workflow.user_workflow_id))) {
      service = UserWorkflowsService.postUserWorkflow;
    }
    service(user_workflow)
      .then(() => {
        success(translations.user_workflows.saved);
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteUserWorkflow = (user_workflow_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    UserWorkflowsService.deleteUserWorkflow(user_workflow_id)
      .then(() => {
        success(translations.user_workflows.deleted);
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <UserWorkflowsContext.Provider
      value={{
        ...state,
        setUserWorkflow,
        getUserWorkflows,
        saveUserWorkflow,
        deleteUserWorkflow,
        createUserWorkflow,
        setValueUserWorkflow,
        getSingleUserWorkflow,
        setPropertyUserWorkflow,
      }}
    >
      {children}
    </UserWorkflowsContext.Provider>
  );
};
