const general = {
  save: "Save",
  confirm: "OK",
  options: "Options",
  cancel: "Cancel",
  words: "Words",
  unlimited: "Unlimited",
  copy: "Copy to clipboard",
  clipboard: "Copied to clipboard",
  favorites: "Show favorites only",
  delete_undone: "This action CANNOT be undone",
  delete: "Delete",
  create: "Create",
  name: "Name",
  edit: "Edit",
  description: "Description",
  insert_file: "Insert File",
  file_types: "File Types",
  upload: "Upload",
  download: "Download",
  language: "Language"
};
export default general;
