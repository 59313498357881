import { useContext } from "react";
import { TemplatesContext } from "../../context/TemplatesContext";


const useValidateInputs = () => {
  const { template, setPropertyTemplate } = useContext(TemplatesContext);

  const validateFields = () => {
    let validFields = true;
    const updatedFields = [];

    template.fields.forEach((field) => {
      const newField = { ...field };

      if (field.value.length <= 0) {
        validFields = false;
        newField.isValidField = false;
      }

      updatedFields.push(newField);
    });

    if (!validFields) {
      setPropertyTemplate("fields", updatedFields);
    }

    return validFields;
  };

  return {
    validateFields
  }
}

export default useValidateInputs;