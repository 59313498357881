import React from "react";
import WorkflowCard from "./WorkflowCard";
import useTranslations from "../../hooks/useTranslations";

const WorkflowList = ({ size, workflows, handleCallback }) => {
  const translations = useTranslations();

  const renderWorkflows = () => {
    if (Array.isArray(workflows)) {
      if (workflows.length === 0) {
        return <p>{translations.workflows.empty}</p>;
      }
      return workflows.map((workflow) => (
        <div
          key={workflow.workflow_id}
          className={size === "lg" ? "col-12" : "col-12 col-md-6 col-xl-4"}
        >
          <WorkflowCard workflow={workflow} handleCallback={handleCallback} />
        </div>
      ));
    }
  };

  return <div className="row">{renderWorkflows()}</div>;
};

export default WorkflowList;
