import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../../context/ModalContext";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import { GroupsContext } from "../../../context/GroupsContext";

const AddGroupUsersForm = () => {
  const [userId, setUserId] = useState();
  const [availableUsers, setAvailableUsers] = useState([]);

  const { clearModal } = useContext(ModalContext);
  const { organization } = useContext(OrganizationsContext);
  const { group, addUserToGroup } = useContext(GroupsContext);

  useEffect(() => {
    handleAvailableUsers();
  }, []);

  useEffect(() => {
    setDefaultValue();
  }, [availableUsers]);

  const setDefaultValue = () => {
    const defaultUser = availableUsers[0]?.user.user_id;
    setUserId(defaultUser);
  }

  const handleAvailableUsers = () => {
    const currentAvailableUsers = organization.users.filter(organization_user => {
      let isAvailableUser = true;

      group.group_users.forEach(group_user => {
        const group_user_id = group_user.user.user_id;
        const current_user_id = organization_user.user.user_id;

        if(group_user_id === current_user_id) isAvailableUser = false;
      });

      if(isAvailableUser) return organization_user;
    });

    setAvailableUsers(currentAvailableUsers);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if(userId && availableUsers.length > 0){
      await addUserToGroup(userId, group.group_id);
    }
    clearModal();
  }

  const handleChangeSelect = (event) => {
    setUserId(event.target.value);
  }

  const renderSelectUser = () => {
    return(
      <select 
        className="form-control"
        onChange={handleChangeSelect}
      >
        {
          availableUsers.map(obj => {
            const { name, last_name, user_id } = obj.user;

            return (
              <option 
                key={user_id}
                value={user_id}
              >
                {name} {last_name}
              </option>
            )
          })
        }
      </select>
    )
  }

  return(
    <form onSubmit={handleSubmit}>
      <label >Select users</label>
      {renderSelectUser()}

      <div className="row mt-3">
        <div className="col-6 ps-0">
          <button type="submit" className="btn btn-primary w-100">Save</button>
        </div>

        <div className="col-6 pe-0">
          <button
            type="button"
            onClick={clearModal}
            className="btn btn-link w-100 text-muted"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
} 

export default AddGroupUsersForm;