import React, { useContext, useEffect, useState } from "react";
import { AvatarsContext } from "../../context/AvatarsContext";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import TextareaInput from "../common/TextareaInput";
import useTranslations from "../../hooks/useTranslations";

const AvatarInputContext = ({ 
  isEditing, 
  setIsEditing, 
  handleCancel, 
  handleSave,
  loading
}) => {
  const [textInputValid, setTextInputValid] = useState(true);
  const [inputValue, setInputValue] = useState('');

  const { avatar } = useContext(AvatarsContext);

  const translations = useTranslations();

  useEffect(() => {
    setInputValue(avatar?.context);
  }, [avatar]);

  const handleChangeContext = (value) => {
    setInputValue(value);

    if(value.length >= 1) {
      setTextInputValid(true);
    } else {
      setTextInputValid(false);
    }
  }

  const handleValidateLength = (value) => {
    if(value.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const handleSaveContext = () => {
    const validInput = handleValidateLength(inputValue);

    if(validInput) {
      handleSave(inputValue);
    } else {
      setTextInputValid(false);
    }
  }

  const renderIcon = () => {
    if(loading) {
      return <div className="spinner-border position-absolute top-0 bottom-0 
      end-0 m-auto me-3"></div>
    } else {
      return(
        <i 
          className="position-absolute top-0 bottom-0 
          end-0 fas fa-bookmark fs-4 m-auto me-3"
          style={{
            height: 'max-content'
          }}
        />
      )
    }
  }

  const renderForm = () => {
    if(isEditing) {
      return(
        <div className="col-12 px-0 h-100 ">
         <TextareaInput
            label={translations.avatar.context_header}
            description={translations.avatar.context_description}
            valid={textInputValid}
            value={inputValue}
            handleChange={handleChangeContext}
            smallDescription={false}
          />
          <div className="row">
            <div className="col-6 ps-0">
              <button
                className="btn btn-white position-relative mt-3 
                w-100 ps-3 underline"
                onClick={handleCancel}
              >
                
                <b><u>{translations.general.cancel}</u></b>
              </button>
            </div>

            <div className="col-6 pe-0">
              <button
                type="button"
                className="btn w-100 btn-primary position-relative mt-3"
                onClick={handleSaveContext}
                disabled={loading}
              >
                {translations.general.save}
                {renderIcon()}
              </button>
            </div>
          </div>

        </div>
      )
    }
  }

  const renderContext = () => {
    if(!isEditing) {
      return(
        <div className="card bg-light px-0 h-100" style={{overflowY: 'hidden'}}>
          <div className="card-header px-3 d-flex justify-content-between align-items-center">
            <h3 className="mb-0 ">{translations.avatar.context_header}</h3>

            <button 
              className="btn  btn-accent small"
              onClick={() => setIsEditing(true)}
            >
              <span className="hide-mobile small me-2">{translations.general.edit}</span> 
              <i className="small fas fa-edit"/>
            </button>
          </div>

          <div className="card-body px-3" style={{overflowY: 'auto'}}>
            <p>{avatar?.context}</p>
          </div>
        </div>
      )
    }
  }

  return (
    <div 
      className="row" 
      style={{
        height: 'calc((100% - 162px)/2)', 
        minHeight: '215px'
      }}
    >
      {renderContext()}
      {renderForm()}
    </div>
  );
};

export default AvatarInputContext;
