import { Link } from "@reach/router";
import React from "react";

const UserWorkflowTemplateCard = ({
  user_workflow_id,
  conversation_id,
  template,
}) => {
  const renderTags = () => {
    if (template.tags !== null) {
      let tags = String(template.tags).split(",");
      return tags.map((tag) => (
        <span key={tag} className="badge badge-pill bg-accent m-1">
          {tag}
        </span>
      ));
    }
  };

  return (
    <div className="card position-relative bg-light pb-2 mb-3">
      <div className="card-body pe-5">
        <div className="row align-items-center">
          <h3>{template.name}</h3>
          <div className="container-fluid px-2">
            <span className="badge badge-pill bg-accent m-1">
              {template.type}
            </span>
            {renderTags()}
          </div>
        </div>
        <Link
          to={`/user_workflows/${user_workflow_id}/conversation/${conversation_id}`}
          className="btn btn-view btn-primary px-2"
        >
          <i className="fa fa-chevron-right"></i>
        </Link>
      </div>
    </div>
  );
};

export default UserWorkflowTemplateCard;
