import api from "./api";

const route = "/stripe";

const StripeService = {
  getStripeProducts: () => api.get(`${route}/products`),
  getStripeProductPrices: (stripe_product_id) =>
    api.get(`${route}/prices/${stripe_product_id}`),
};

export default StripeService;
