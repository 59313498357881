import api from "./api";
const route = "/campaigns";

export default {
  getCampaigns: (organization_id, query, sortBy) =>
    api.get(`${route}/organization/${organization_id}query?query=${query}&sortBy=${sortBy}`),
  getSingleCampaign: (campaign_id, type, query) =>
    api.get(
      `${route}/${campaign_id}/resources?resources=${type}&query=${query}`
    ),

  postCampaign: (campaign) =>
    api.post(route, campaign, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),

  putCampaign: (campaign) =>
    api.put(route, campaign, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),

  deleteCampaign: (campaign_id) =>
    api.delete(`${route}/campaign/${campaign_id}`),

  postCampaignResource: (campaignResource) =>
    api.post(`${route}/campaign-resource/`, campaignResource),

  putCampaignResource: (campaignResource) =>
    api.put(`${route}/campaign-resource/`, campaignResource),

  deleteCampaignResource: (campaignResource) =>
    api.delete(
      `${route}/campaign-resource/${campaignResource.type}/${campaignResource.resource_id}/${campaignResource.campaign_id}`
    ),

  putCampaignResource: (campaignResource) =>
    api.put(`${route}/campaign-resource/`, campaignResource),

  deleteCampaignResource: (campaignResource) =>
    api.delete(
      `${route}/campaign-resource/${campaignResource.type}/${campaignResource.resource_id}/${campaignResource.campaign_id}`
    ),

  giveCampaignAccess: ({ campaign_id, user_id, group_id }) =>
    api.post(`${route}/give-access/${campaign_id}`, { user_id, group_id }),

  revokeAccessCampaign: ({ campaign_id, user_id, group_id }) =>
    api.post(`${route}/revoke-access/${campaign_id}`, { user_id, group_id }),
};
