import FilesService from "../../services/FilesService";

export const useUploadFiles = () => {
  const uploadFiles = async (filesData) => {
    const files = [];

    if(Array.isArray(filesData) && filesData.length > 0) {
      await Promise.all(
        filesData.map(async (input) => {
          return new Promise(async (resolve, reject) => {
            try {
              if(input.file) {
                const formData = FilesService.getFormData(input.file);
                const fileData = (await FilesService.postFile(formData)).data.file;
                const fileName = fileData.src;
  
                files.push({
                  key: input.key,
                  fileName
                });
              }
              resolve();
            } catch (error) {
              reject(error);
            }
          })
        })
      );
    }

    return files;
  }

  return {
    uploadFiles
  }
}