import React, { createContext, useReducer, useContext } from "react";
import StripesService from "../services/StripesService";
import StripesReducer from "../reducers/StripesReducer";
import {
  STRIPE_PRICES_RECEIVED,
  STRIPE_PRODUCTS_RECEIVED,
} from "../types/stripes";
import { ModalContext } from "./ModalContext";

const initialState = {
  products: null,
  prices: null,
};

export const StripesContext = createContext(initialState);

export const StripesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(StripesReducer, initialState);

  const { alert } = useContext(ModalContext);

  const getStripeProducts = () => {
    StripesService.getStripeProducts()
      .then((response) => {
        const { products } = response.data;
        dispatch({ type: STRIPE_PRODUCTS_RECEIVED, payload: products });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getStripeProductPrices = (stripe_product_id) => {
    StripesService.getStripeProductPrices(stripe_product_id)
      .then((response) => {
        const { prices } = response.data;
        dispatch({ type: STRIPE_PRICES_RECEIVED, payload: prices });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const extractStripePriceName = (data)  => {
    // Extract necessary fields
    const priceInCents = data.unit_amount || 0;
    const currency = (data.currency || 'usd').toUpperCase();
    const recurring = data.recurring || {};
    const frequency = recurring.interval || 'One-Time';
    const id = data.id || 'unknown';
  
    // Convert price to dollars
    const priceInDollars = (priceInCents / 100).toFixed(2);
  
    // Construct name
    const name = `$${priceInDollars} ${currency} / ${frequency} (ID: ${id})`;
  
    return name;
  }

  return (
    <StripesContext.Provider
      value={{
        ...state,
        getStripeProducts,
        getStripeProductPrices,
        extractStripePriceName,
      }}
    >
      {children}
    </StripesContext.Provider>
  );
};
