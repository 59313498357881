import {
  SET_OUTPUT,
  OUTPUTS_RECEIVED,
  APPEND_OUTPUT,
  SETUP_SOCKET,
  PREPEND_OUTPUT,
} from "../types/outputs";

const OutputsReducer = (state, { type, payload }) => {
  switch (type) {
    case SETUP_SOCKET:
      return { ...state, socket: payload };
    case PREPEND_OUTPUT: {
      let outputs = state.outputs;
      if (Array.isArray(outputs)) outputs = [...outputs];
      else outputs = [];
      const first = outputs[0];
      let avatar_id;
      if (first) {
        avatar_id = first.avatar_id;
      }
      const index = outputs.findIndex(
        ({ output_id }) => parseInt(output_id) === parseInt(payload.output_id)
      );
      if (index === -1) {
        if ((avatar_id && payload.avatar_id === avatar_id) || !avatar_id) {
          outputs = [payload, ...outputs];
        }
      } else {
        outputs[index] = payload;
      }
      return { ...state, outputs: [...outputs] };
    }
    case OUTPUTS_RECEIVED:
      return { ...state, outputs: payload };
    case SET_OUTPUT:
      return { ...state, output: payload };
    case APPEND_OUTPUT:
      return { ...state, outputs: [payload, ...state.outputs] };
    default:
      return { ...state };
  }
};
export default OutputsReducer;
