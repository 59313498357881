const trainings = {
  saved: "Training saved",
  synced:
    "Training syncing. It may take up to 15 minutes to complete a training.",
  refined:
    "Training refining. It may take up to 15 minutes to complete a training.",
  executed:
    "Training initiated. It may take up to 15 minutes to complete a training.",
};

export default trainings;
