import React, { useContext } from "react";
import useTemplateInputs from "../../hooks/templates/useTemplateInputs";
import useValidateInputs from "../../hooks/templates/useValidateInputs";
import useTemplatePrompt from "../../hooks/templates/useTemplatePrompt";
import { AuthContext } from "../../context/AuthContext";
import SuperFetchService from "../../services/SuperFetchService";
import { DocumentsContext } from "../../context/DocumentsContext";
import LoadingCard from "../global/LoadingCard";
import { useDocumentText } from "../../hooks/documents/useDocumentText";

const TemplateAsideApply = ({ template }) => {
  const { renderTemplateFields } = useTemplateInputs();
  const { validateFields } = useValidateInputs();
  const getTemplatePrompt = useTemplatePrompt();
  const { getContextPrompt } = useDocumentText();

  const { user } = useContext(AuthContext);
  const { setLoadingOutputs, loadingOutputs } = useContext(DocumentsContext);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleGenerate();
  }

  const handleGenerate = () => {
    const validFields = validateFields();

    if (validFields) {
      setLoadingOutputs(true);
      getOutput();
    }
  }

  const getOutput = () => {
    const currentPrompt = getTemplatePrompt();
    const modifiedPrompt = getContextPrompt(currentPrompt);

    SuperFetchService.getOutput({
      messages: [modifiedPrompt],
      assistant_id: user.defaultAssistant.id
    });
  }

  const renderLoading = () => {
    if(loadingOutputs){
      return(
        <LoadingCard/>
      )
    }
  }

  return (
    <form onSubmit={handleSubmit} className="container px-0">
      <div className="card p-2 border shadow">
        <h3 className="text-muted ">{template?.name}</h3>
        <p className="text-muted">{template?.description}</p>
      </div>
      

      <div className="row">
        <div className="col-12 px-0">
          {renderTemplateFields('bg-white')}
        </div>
      </div>

      <div className="row mt-3">
        {renderLoading()}
        <div className="col-6 px-0">
          <button
            type="submit"
            disabled={loadingOutputs}
            className="btn btn-primary w-100 small"
          >
            <i className="fas fa-chevron-left me-2"/>
            Generate
          </button>
        </div>
      </div>
    </form>
  );
};

export default TemplateAsideApply;
