import React from "react";

const TemplateTagCard = ({ tag, setCurrentTag, size }) => {
  return (
    <div
      onClick={() => setCurrentTag(tag)}
      className={`ps-0 col-12 ${size === "lg" ? "col-xxl-4 col-xl-6 col-md-6 col-sm-6" : ""} cursor-pointer`}
    >
      <div className="card px-4 py-5 text-center mb-3 bg-light ">
        <h4 className="text-capitalize">{tag}</h4>
      </div>
    </div>


  );
};

export default TemplateTagCard;
