import { useContext } from "react"
import { DocumentsContext } from "../../context/DocumentsContext"


export const useDocumentText = () => {
  const { currentContent } = useContext(DocumentsContext);

  const getContextPrompt = (currentPrompt) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(currentContent, "text/html");
    const currentDocumentText = htmlDoc.body.textContent || "";

    let prompt;
    if (currentDocumentText.length > 0) {
      prompt = `
      ${currentDocumentText}

      -Use the above text to make a response for the next request:

      ${currentPrompt}
    `;
    } else {
      prompt = currentPrompt;
    }

    prompt = prompt.replace(/^(?!\s*$)\s+/gm, "");
    return prompt;
  }

  return { getContextPrompt };
}