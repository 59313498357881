const campaigns = {
  title: "Campañas",
  search: "Buscar Campañas...",
  noCampaigns: "No se encontraron campañas en esta organización",
  button: "Añadir",
  giveAccess: "Dar acceso a la campaña",
  revokeAccess: "Retirar acceso a la campaña",
};

export default campaigns;
