import React, { useContext } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";

const ContentSection = () => {
  const {
    title,
    tagline,
    app_name,
    high_ticket_offer_name,
    high_ticket_offer_link,
    landing_image_src,
    landing_logo_src,
    favicon_url,
    images_inputs,

    feature1_title,
    feature2_title,
    feature3_title,
    feature1_description,
    feature2_description,
    feature3_description,
    benefit1_title,
    benefit2_title,
    benefit3_title,
    benefit1_description,
    benefit2_description,
    benefit3_description,

    setAppConfigProperty,
  } = useContext(AppConfigContext);

  const handleChangeInput = (key) => {
    return (event) => {
      const value = event.target.value;
      setAppConfigProperty(key, value);
    };
  };

  const renderLandingLogo = () => {
    const src = landing_logo_src;

    return (
      <img
        className="bg-light border p-0"
        src={src}
        alt="landing"
        style={{
          width: "100%",
          height: "300px",
          objectFit: "contain",
        }}
      />
    );
  };

  const renderFavicon = () => {
    const src = favicon_url;

    return (
      <img
        className="bg-light border p-0"
        src={src}
        alt="favicon"
        style={{
          width: "100%",
          height: "300px",
          objectFit: "contain",
        }}
      />
    );
  };

  const handleChangeFile = (key) => {
    return (event) => {
      const currentFile = event.target.files[0];
      const fileSrc = URL.createObjectURL(currentFile);

      const fileData = {
        file: currentFile,
        key,
      };

      setAppConfigProperty("images_inputs", [...images_inputs, fileData]);
      setAppConfigProperty(key, fileSrc);
    };
  };

  return (
    <div
      className="container-fluid d-flex flex-column pb-3"
      style={{ height: "calc(100% - 40px)", overflowY: "auto" }}
    >
      <label className="bold mt-3">App Name</label>
      <input
        type="text"
        value={app_name}
        className="form-control"
        onChange={handleChangeInput("app_name")}
      />
      <div className="row mt-3">
        <div className="col-6 ps-0">
          <label className="bold">Title</label>
          <input
            type="text"
            value={title}
            className="form-control"
            onChange={handleChangeInput("title")}
          />
        </div>
        <div className="col-6 pe-0">
          <label className="bold">Tagline</label>
          <input
            type="text"
            value={tagline}
            className="form-control"
            onChange={handleChangeInput("tagline")}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6 ps-0">
          <label className="bold">High Ticket Offer Name</label>
          <input
            type="text"
            value={high_ticket_offer_name}
            className="form-control"
            onChange={handleChangeInput("high_ticket_offer_name")}
          />
        </div>
        <div className="col-6 pe-0">
          <label className="bold">High Ticket Offer Link</label>
          <input
            type="text"
            value={high_ticket_offer_link}
            className="form-control"
            onChange={handleChangeInput("high_ticket_offer_link")}
          />
        </div>
      </div>

      <div className="row mt-3">
        <label className="bold px-0">Landing Image Url</label>
        <input
          type="text"
          value={landing_image_src}
          className="form-control"
          onChange={handleChangeInput("landing_image_src")}
        />
      </div>

      <div className="row mt-3">
        <div className="col-6 ps-0">
          <label className="bold">Landing Logo</label>
          {renderLandingLogo()}
          <input
            type="file"
            className="form-control mt-3"
            onChange={handleChangeFile("landing_logo_src")}
          />
        </div>
        <div className="col-6 pe-0">
          <label className="bold">Favicon</label>
          {renderFavicon()}
          <input
            type="file"
            className="form-control mt-3"
            onChange={handleChangeFile("favicon_url")}
          />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6 ps-0">
          <div className="card border shadow p-3">
            <h3 className="w-max-content text-gradient">Feature 1</h3>
            <label className="bold">Title</label>
            <input
              type="text"
              value={feature1_title}
              className="form-control"
              onChange={handleChangeInput("feature1_title")}
            />

            <label className="mt-2 bold">Description</label>
            <textarea
              value={feature1_description}
              className="form-control"
              onChange={handleChangeInput("feature1_description")}
            />
          </div>
        </div>

        <div className="col-6 pe-0">
          <div className="card border shadow p-3">
            <h3 className="w-max-content text-gradient">Feature 2</h3>
            <label className="bold">Title</label>
            <input
              type="text"
              value={feature2_title}
              className="form-control"
              onChange={handleChangeInput("feature2_title")}
            />

            <label className="mt-2 bold">Description</label>
            <textarea
              value={feature2_description}
              className="form-control"
              onChange={handleChangeInput("feature2_description")}
            />
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6 ps-0">
          <div className="card border shadow p-3">
            <h3 className="w-max-content text-gradient">Feature 3</h3>
            <label className="bold">Title</label>
            <input
              type="text"
              value={feature3_title}
              className="form-control"
              onChange={handleChangeInput("feature3_title")}
            />

            <label className="mt-2 bold">Description</label>
            <textarea
              value={feature3_description}
              className="form-control"
              onChange={handleChangeInput("feature3_description")}
            />
          </div>
        </div>
        <div className="col-6 pe-0">
          <div className="card border shadow p-3">
            <h3 className="w-max-content text-gradient">Benefit 1</h3>
            <label className="bold">Title</label>
            <input
              type="text"
              value={benefit1_title}
              className="form-control"
              onChange={handleChangeInput("benefit1_title")}
            />

            <label className="mt-2 bold">Description</label>
            <textarea
              value={benefit1_description}
              className="form-control"
              onChange={handleChangeInput("benefit1_description")}
            />
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-6 ps-0">
          <div className="card border shadow p-3">
            <h3 className="w-max-content text-gradient">Benefit 2</h3>
            <label className="bold">Title</label>
            <input
              type="text"
              value={benefit2_title}
              className="form-control"
              onChange={handleChangeInput("benefit2_title")}
            />

            <label className="mt-2 bold">Description</label>
            <textarea
              value={benefit2_description}
              className="form-control"
              onChange={handleChangeInput("benefit2_description")}
            />
          </div>
        </div>
        <div className="col-6 pe-0">
          <div className="card border shadow p-3">
            <h3 className="w-max-content text-gradient">Benefit 3</h3>
            <label className="bold">Title</label>
            <input
              type="text"
              value={benefit3_title}
              className="form-control"
              onChange={handleChangeInput("benefit3_title")}
            />

            <label className="mt-2 bold">Description</label>
            <textarea
              value={benefit3_description}
              className="form-control"
              onChange={handleChangeInput("benefit3_description")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentSection;
