import React from "react";
import useTranslations from "../../hooks/useTranslations";

const AvatarTraining = ({ avatarName, handleBegin }) => {
  const translations = useTranslations();
  return (
    <div
      className="card bg-light p-3 pt-0 d-flex flex-column 
      align-items-center justify-content-around"
      style={{ height: "200px" }}
    >
      <div className="card-header py-0">
        <h3 className="fs-3 mb-0 text-primary text-gradient ">
          {translations.avatar.train_title}
        </h3>
      </div>

      <h3>
        <span className="text-accent fs-4 mx-2">
          <b>{avatarName}</b>
        </span>{" "}
        {translations.avatar.context_empty}
      </h3>

      <button className="btn btn-primary" onClick={handleBegin}>
        {translations.avatar.train_start}
      </button>
    </div>
  );
};

export default AvatarTraining;
