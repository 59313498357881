import React, { useContext, useState } from "react";
import { ModalContext } from "../../context/ModalContext";
import { AvatarsContext } from "../../context/AvatarsContext";

const AvatarDeleteFile = ({ fileName, fileType, deleteData }) => {
  const [loading, setLoading] = useState(false);

  const { deleteAvatarFile, avatar } = useContext(AvatarsContext);
  const { clearModal } = useContext(ModalContext);

  const deleteFile = async () => {
    setLoading(true);
    await deleteAvatarFile(deleteData);
    setLoading(false);
    clearModal();
  }

  const renderIcon = () => {
    if(loading) {
      return <div className="spinner-border position-absolute top-0 bottom-0 
      end-0 m-auto me-3"></div>
    } else {
      return (
        <i className="fa fa-trash ms-3"></i>
      )
    }
  }

  return (
    <div className="row px-0">
      <div className="col-12 px-0">
        <p className="">File: <span className="text-accent">{fileName}.{fileType}</span></p>
        <p>This action <span className="text-danger">CANNOT</span> be undone.</p>
      </div>
      
      <div className="row px-0">
        <div onClick={clearModal} className="col-6 ps-0">
          <button className="btn text-muted w-100">Cancel</button>
        </div>

        <div className="col-6 pe-0">
          <button
            onClick={deleteFile}
            className="btn btn-danger position-relative w-100"
            disabled={loading}
          >
            Delete
            {renderIcon()}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AvatarDeleteFile;
