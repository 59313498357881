import React, { useContext, useEffect } from "react";

const TemplateGenerateCard = ({ data, handleApply }) => {
  return (
    <div className="card bg-white rounded-3 mb-3 shadow border" style={{ height: "115" }}>
      <div className="card-header bg-white rounded-3 pb-3 pt-3 border-0">
        <h6
          className=" bold"
          style={{ textOverflow: "ellipsis" }}
        >
          {data?.name}
        </h6>

        <p className="mb-2">{data?.description}</p>
        <button
          type="button"
          className="w-100 small btn btn-accent"
          onClick={() => handleApply(data)}
        >
          Apply
          <i className="fa fa-chevron-right ms-2"></i>
        </button>
      </div>
    </div>
  );
};

export default TemplateGenerateCard;
