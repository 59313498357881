const avatars = {
  title: "Avatares",
  saved: "Avatar guardado",
  add_context: "Contexto de Avatar",
  select_context: "Selecciona un avatar para agregar como contexto.",
  search: "Buscar avatares...",
  deleted: "Avatar eliminado",
  synced:
    "Avatar sincronizando. El entrenamiento puede tomar hasta 15 minutos.",
  combined:
    "Combinando Avatares. El entrenamiento puede tomar hasta 15 minutos.",
  delete_confirm: "¿Estás seguro que deseas eliminar el avatar",
  empty: "Aún no tienes ningún avatar.",
  upgrade: "Crear avatares es una función de pago.",
};

export default avatars;
