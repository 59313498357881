import { useContext } from "react";
import { DocumentsContext } from "../../context/DocumentsContext";
import useWindowSize from "../../hooks/useWindowSize";
import { AppConfigContext } from "../../context/AppConfigContext";

const DocumentHeader = ({
  handleSaveDocumentContent,
  toggleAside,
  asideActive,
  title,
}) => {
  const { loadingOutputs } = useContext(DocumentsContext);
  const { app_name } = useContext(AppConfigContext);
  const smallDevice = useWindowSize(1200);

  const renderDisplayBtn = () => {
    if (smallDevice) {
      return (
        <button
          onClick={toggleAside}
          className={`btn small me-0 ${
            asideActive ? "btn-primary" : "btn-outline-accent"
          }`}
        >
          {!smallDevice && <span className="me-2">Tools</span>}
          <i className="fas fa-wrench"></i>
        </button>
      );
    }
  };

  const renderTitle = () => {
    if (title) {
      return (
        <p
          style={{ width: "max-content" }}
          className="text-capitalize mb-0 bold w-max p-0"
        >
          {title}
        </p>
      );
    }

    if (loadingOutputs && !title) {
      return (
        <>
          <p
            style={{ width: "max-content" }}
            className="text-capitalize mb-0 me-2 w-max p-0"
          >
            {app_name} is looking for an answer...
          </p>
          <div
            className="spinner-border"
            style={{ width: 20, height: 20 }}
          ></div>
        </>
      );
    } else {
      return (
        <p
          style={{ width: "max-content" }}
          className="text-capitalize mb-0 bold w-max p-0"
        >
          {"Your tone and style"}
        </p>
      );
    }
  };

  return (
    <div className="w-100 d-flex p-0 justify-content-between">
      <div className="d-flex flex-row align-items-center p-0">
        {renderTitle()}
      </div>
      <div className="d-flex justify-content-end p-0">
        <button
          onClick={() => handleSaveDocumentContent()}
          className="btn btn-outline-primary small me-2"
        >
          {!smallDevice && <span className="me-2">Save</span>}
          <i className="fas fa-save"></i>
        </button>
        {renderDisplayBtn()}
      </div>
    </div>
  );
};

export default DocumentHeader;
