const outputs = {
  form: {
    defaultPrompt: "Write a tweet",
  },
  rated: "Output rated.",
  saved: "Output saved.",
  deleted: "Output deleted.",
  generated: "Outputs queued. They will appear under 'Outputs' tab.",
};
export default outputs;
