import React, { useContext, useEffect, useState } from "react";
import ReactSelect from "react-select";
import TextareaInput from "../common/TextareaInput";
import useTranslations from "../../hooks/useTranslations";

import { AvatarsContext } from "../../context/AvatarsContext";
import { DocumentsContext } from "../../context/DocumentsContext";
import { MessagesContext } from "../../context/MessagesContext";
import { useDocumentText } from "../../hooks/documents/useDocumentText";

const DocumentAvatarTools = ({}) => {
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [validPrompt, setValidPrompt] = useState(true);

  const { avatars, getAvatars } = useContext(AvatarsContext);
  const { currentContent } = useContext(DocumentsContext);
  const { runingThread, saveMessage } = useContext(MessagesContext);
  const translations = useTranslations();
  const { getContextPrompt } = useDocumentText();

  useEffect(() => {
    getAvatars();
  }, []);

  const options = avatars?.map((current) => {
    return {
      label: current.name,
      value: current.avatar_id,
    };
  });

  const handleChangePrompt = (value) => {
    value.length <= 0 ? setValidPrompt(false) : setValidPrompt(true);
    setPrompt(value);
  };

  const handleSubmit = () => {
    const modifiedPrompt = getContextPrompt(prompt);

    saveMessage({
      avatarMessage: true,
      content: modifiedPrompt,
      assistant_id: selectedAvatar.assistant_id,
    });
  };

  const renderSpinner = () => {
    if (runingThread) {
      return (
        <div className="col-12 px-0 mt-3">
          <div className="bg-white rounded-3 shadow border fw-bold w-100">
            <p className="loading fs-6 m-1 text-gradient">
              {translations.conversations.spinner}
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="row">
      <ReactSelect
        className="basic-single w-100 mt-2 px-0"
        classNamePrefix="document-avatar-selector"
        isSearchable
        onChange={(newValue) => {
          const selected = avatars.find((obj) => {
            return obj.avatar_id === newValue.value;
          });

          setSelectedAvatar(selected);
        }}
        name="color"
        options={options}
      />

      <div className="col-12 px-0 mt-3">
        <TextareaInput
          label={"Send a message to your avatar"}
          placeholder={"Considering this document, write a summary about it..."}
          valid={validPrompt}
          value={prompt}
          handleChange={handleChangePrompt}
          maxHeight={"200px"}
          smallDescription
          smallContent
          bgWhite
        />
      </div>

      {renderSpinner()}

      <div className="col-12 px-0 mt-3">
        <button
          className="btn btn-primary small "
          type="button"
          disabled={
            selectedAvatar === null ||
            !selectedAvatar ||
            !validPrompt ||
            prompt.length <= 0 ||
            runingThread
          }
          onClick={handleSubmit}
        >
          <i className="fa fa-paper-plane me-2"></i>
          {translations.conversation.input.send}
        </button>
      </div>
    </div>
  );
};

export default DocumentAvatarTools;
