
import {
  SET_GROUP,
  CREATE_GROUP,
  GROUPS_RECEIVED,
  SET_PROPERTY_GROUP,
} from "../types/groups";

const schema = {
  name: ''
}

const groupsReducer = (state, { type, payload }) => {
  switch (type) {
    case GROUPS_RECEIVED:
      return { ...state, groups: payload };
    case SET_GROUP:
      return { ...state, group: payload };
    case CREATE_GROUP:
      return { ...state, group: schema };
    case SET_PROPERTY_GROUP: {
      const { key, value } = payload;
      const group = { ...state.group };
      group[key] = value;
      return { ...state, group };
    }
    default:
      return { ...state};
  }
};

export default groupsReducer;
