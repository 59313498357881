import api from "./api";
import { getArgs } from "../utils";

const route = "/trainings";

const TrainingsService = {
  getMyTrainings: (filters) => api.get(`${route}?${getArgs(filters)}`),
  getPublicTrainings: () => api.get(`${route}/public/all`),
  getSingleTraining: (training_id) => api.get(`${route}/${training_id}`),
  generateOutput: (training) => api.post(route, { ...training }),
  postTraining: (training) => api.post(route, { ...training }),
  putTraining: (training) => api.put(route, { ...training }),
  executeTraining: (training_id) =>
    api.post(`${route}/execute`, { training_id }),
  syncTraining: (training_id) => api.post(`${route}/sync`, { training_id }),
  refineTraining: (training_id) => api.post(`${route}/refine`, { training_id }),
  deleteTraining: (training_id) => api.delete(`${route}/${training_id}`),
};
export default TrainingsService;
