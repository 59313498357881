import {
  SET_USERWORKFLOW,
  CREATE_USERWORKFLOW,
  USERWORKFLOWS_RECEIVED,
  SET_PROPERTY_USERWORKFLOW,
  SET_VALUE_USERWORKFLOW,
} from "../types/user_workflows";
import { processValuesToObject } from "../utils/templates";

const schema = {};

const userWorkflowsReducer = (state, { type, payload }) => {
  switch (type) {
    case USERWORKFLOWS_RECEIVED:
      return { ...state, user_workflows: payload };
    case SET_USERWORKFLOW: {
      const user_workflow = payload;
      user_workflow.object = processValuesToObject(user_workflow.values);
      return { ...state, user_workflow };
    }
    case CREATE_USERWORKFLOW:
      return { ...state, user_workflow: schema };
    case SET_PROPERTY_USERWORKFLOW: {
      const { key, value } = payload;
      const userWorkflow = { ...state.user_workflow };
      userWorkflow[key] = value;
      return { ...state, user_workflow: userWorkflow };
    }
    case SET_VALUE_USERWORKFLOW: {
      const { key, value } = payload;
      const userWorkflow = { ...state.user_workflow };
      userWorkflow.object[key] = value;
      return { ...state, user_workflow: userWorkflow };
    }
    default:
      return { ...state };
  }
};

export default userWorkflowsReducer;
