const purchases = {
  date: "Fecha",
  type: "Tipo",
  total: "Total",
  status: "Estado",
  product: "Producto",
  actions: "Acciones",
  customer: "Cliente",
  discount: "Descuento",
  expiration: "Expira en",
  empty: "No has hecho ninguna compra",
  numberOfDays: "Número de Días",
  purchaseTotal: "Total Pagado",
  isGift: "¿Es Regalo?",
  payMentMethod: "Método de Pago",
  editPurchase: "Editar Compra",
  expirationDays: "Días de Vigencia",
  cancel: "Cancelar",
  savePurchase: "Guardar Compra",
  active: "Activa",
  completed: "Completada",
  pending: "Pendiente",
  cancelled: "Cancelada",
  revoked: "Revocada",
  giveAccess: "Extender Acceso",
  cancelMessage: `Si Cancelas la compra, el cliente disfrutará sus beneficios hasta que
      termine su vigencia y no se hará ningún cargo recurrente adicional.
      Esta Acción NO puede deshacerse.`,
  revokeMessage: `Si Revocas la compra, el cliente dejará de tener acceso a los
      beneficios de esta compra inmediatamente.`,
  activateMessage: `Si activas la compra, el cliente recibirá acceso a TBM Online y la
      cantidad de créditos incluidas en el paquete inmediatamente.`,
};
export default purchases;
