import React from "react";
import { useContext } from "react";
import { UserWorkflowsContext } from "../../context/UserWorkflowsContext";
import { useEffect } from "react";
import { getValue } from "../../utils";

const UserWorkflowForm = ({ handleCancel, workflow_id, user_workflow_id }) => {
  const {
    user_workflow,
    saveUserWorkflow,
    createUserWorkflow,
    getSingleUserWorkflow,
    setPropertyUserWorkflow,
  } = useContext(UserWorkflowsContext);

  useEffect(() => {
    if (isNaN(user_workflow_id)) {
      createUserWorkflow(workflow_id);
    } else {
      getSingleUserWorkflow(user_workflow_id);
    }
  }, [user_workflow_id]);

  useEffect(() => {
    if (user_workflow !== null) {
      if (!user_workflow.workflow_id) {
        setPropertyUserWorkflow("workflow_id", workflow_id);
      }
    }
  }, [user_workflow]);

  const handleSubmit = (e) => {
    e.preventDefault();
    saveUserWorkflow(user_workflow);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>Name</label>
      <input
        type="text"
        className="form-control mb-3"
        value={getValue(user_workflow, "name")}
        onChange={(e) => setPropertyUserWorkflow("name", e.target.value)}
      />
      <div className="row align-items-center">
        <div className="col-6">
          <button
            type="button"
            onClick={handleCancel}
            className="btn w-100 text-muted"
          >
            Nevermind
          </button>
        </div>
        <div className="col-6">
          <button type="submit" className="btn w-100 btn-primary">
            Start
          </button>
        </div>
      </div>
    </form>
  );
};

export default UserWorkflowForm;
