

import React from "react";
import { Link } from "@reach/router";
import useTranslations from "../../../hooks/useTranslations";

const OrganizationTableRow = ({
  user,
  role,
  extraFields,
  showCancelReason,
  link,
  onDelete,
  deleteField,
}) => {

  const translation = useTranslations();

  const renderName = () => {
    const { name, last_name } = user;
    return `${name !== null ? name : ""} ${last_name !== null ? last_name : ""
      }`;
  };

  const renderDelete = () => {
    if (onDelete && deleteField) {
      return  <td className="text-end">
        <button
          className="btn small hover-light text-danger p-0 m-0"
          onClick={() => onDelete({ user_id: user.user_id })}
        >
          {translation.campaigns.revokeAccess.split(' ')[0]}
          <i className="fa fa-ban ms-1"></i>
        </button>
      </td>
    }
  }

  const renderExtraFields = () => {
    if (Array.isArray(extraFields)) {
      return extraFields.map((field) => <td key={field.key} className="text-center">{user[field.key]}</td>);
    }
  };

  const renderCancel = () => {
    if (showCancelReason) {
      if (Array.isArray(user.purchases)) {
        const purchase = user.purchases.find(
          (purchase) => purchase.cancel_reason !== null
        );
        return <td>{purchase ? purchase.cancel_reason : ""}</td>;
      }
    }
  };

  const renderRole = () => {
    if (role) {
      return <td>
        {role}
      </td>
    }
  }

  return (
    <tr className="p-2 border-bottom small align-middle hover-light">
      <td className="td-id">{user?.user_id}</td>
      <td>
        <Link to={link}>
          <i className="fa fa-eye me-2"></i>
          {renderName()}
        </Link>
      </td>
      <td>
        <a
          target="_blank"
          rel="noreferrer"
          href={`mailto:${user?.email}`}
          className="text-secondary"
        >
          <i className="fa fa-envelope me-2"></i>
          {user?.email}
        </a>
      </td>
      {renderRole()}
      {renderDelete()}
      {renderExtraFields()}
    </tr>
  );
};

export default OrganizationTableRow;
