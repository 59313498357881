import React from "react";
import Tags from "../global/Tags";

const WorkflowTemplateCard = ({ template }) => {
  return (
    <div className="card position-relative bg-light pb-2 mb-3">
      <div className="card-body w-75">
        <div className="row align-items-center">
          <h3>{template.name}</h3>
          <p>{template.description}</p>
          <div className="container-fluid px-2">
            <span className="badge badge-pill bg-accent m-1">
              {template.type}
            </span>
            <Tags tags={template.tags} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkflowTemplateCard;
