import React, { useContext } from "react";
import { SuperFetchContext } from "../../context/SuperFetchContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import TextareaInput from "../common/TextareaInput";

const SuperFetchFeedbackForm = () => {
  const { 
    feedback, 
    changeFeedbackInput, 
    changeFeedbackLiked,
  } = useContext(SuperFetchContext);
  const { app_name } = useContext(AppConfigContext);

  const hanldeLikeOutput = () => {
    changeFeedbackLiked(true);
  }

  const handleDislikeOutput = () => {
    changeFeedbackLiked(false);
  }

  return(
    <form 
      className="position-relative " 
    >
      <div className={`row mb-4 px-0`}>

          <label className="mb-1 me-4 px-0">
            <b>Did you like the response?</b>
          </label>

        <div className="px-0">
          <i 
            onClick={hanldeLikeOutput} 
            className={`fa fa-thumbs-up fs-3 me-3 cursor-pointer 
            ${feedback.liked ? 'text-primary' : ''}`}
          />
          <i 
            onClick={handleDislikeOutput} 
            className={`fa fa-thumbs-down fs-3 cursor-pointer
            ${feedback.liked === false ? 'text-primary' : ''}`}
          />
        </div>
 


      </div>

      <div className={` mb-4 w-100`} style={{marginBottom: '50px'}}>
        <TextareaInput
          label={`Try again telling ${app_name} the changes you want: `}
          valid
          value={feedback.input.value}
          handleChange={changeFeedbackInput}
        />
      </div>
    </form>
 
  )
};

export default SuperFetchFeedbackForm;
