

const DocumentDownloadButtons = ({ createPDF, createWord }) => {

    return (
        <div className="w-100">
            <p className="mb-3 bold">
                Download as:
            </p>
            {/* <button className="w-100 btn btn-accent small me-2 mb-3" onClick={createPDF}>
                PDF
                <i className="fas fa-file-pdf ms-2"></i>
            </button> */}
            <button className="w-100  btn btn-accent small" onClick={createWord}>
                WORD
                <i className="fas fa-file-word ms-2"></i>
            </button>
        </div>
    );
};

export default DocumentDownloadButtons;
