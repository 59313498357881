import React, { useContext } from "react";
import { CampaignsContext } from "../../../context/CampaignsContext";
import { ModalContext } from "../../../context/ModalContext";
import CampaignForm from "./CampaingForm";


const CampaignsHandler = () => {
    const { modalComponent, clearModal } = useContext(ModalContext);
    const { deleteCampaign, saveCampaign } = useContext(CampaignsContext);

    const handleCancel = () => {
        clearModal();
    };

    const handleCreateCampaign = () => {
        modalComponent(
            "Create Campaign",
            <CampaignForm
                onCancel={handleCancel}
                onSave={saveCampaign}
            />
        );
    };

    const handleEditCampaign = (campaign) => {

        modalComponent(
            `Edit ${campaign.name}`,
            <CampaignForm
                onCancel={handleCancel}
                campaignToEdit={campaign}
                onSave={saveCampaign}
            />
        );
    };

    const handleDeleteCampaign = (campaign, goBack) => {
        const onDelete = () => {
            deleteCampaign(campaign.campaign_id);
            if (goBack) {
                window.history.go(-1)
            }
        };

        modalComponent(
            `Delete ${campaign.name}?`,
            <div className="w-100 d-flex mt-3">
                <button className="btn me-1 w-50" onClick={clearModal}>
                    Cancel
                </button>
                <button className="btn btn-danger ms-1 w-50 text-white" onClick={onDelete}>
                    Delete Campaign
                </button>
            </div>
        );
    };

    return {
        handleCreateCampaign,
        handleEditCampaign,
        handleDeleteCampaign,
    };
};

export default CampaignsHandler;
