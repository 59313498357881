import React, { useState } from "react";


const useFilterByText = () => {
  const [query, setQuery] = useState('');

  const filter = (text) => {
    let match = false;
    if (text.includes(query.toLowerCase())) match = true;

    return match
  }

  const filterArray = (objArr, key) => {
    const filteredArray = objArr?.filter(obj => {
      const text = obj[key].toLowerCase();

      if(filter(text)) return obj;
    });

    return filteredArray
  }

  return {
    query,
    setQuery,
    filterArray
  }


}


export default useFilterByText;
