import React, { createContext, useReducer } from "react";
import AppConfigReducer from "../reducers/AppConfigReducer";
import AppConfigService from "../services/AppConfigService";
import { SET_CONFIG, SET_SINGLE_CONFIG } from "../types/appconfig";

const initialState = {
  images_inputs: []
};

export const AppConfigContext = createContext(initialState);

export const AppConfigProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppConfigReducer, initialState);

  const getAppConfig = (isAdmin) => {
    let service = AppConfigService.getAppConfig;
    if(isAdmin) {
      service = AppConfigService.getAdminAppConfig;
    }

    service().then((res) => {
      const { config } = res.data;
      dispatch({ type: SET_CONFIG, payload: config });
    });
  };

  const setAppConfigProperty = (key, value) => {
    dispatch({ type: SET_SINGLE_CONFIG, payload: { key, value } });
  };

  const updateAppConfig = async (payload) => {
    return AppConfigService.putAppconfig(payload);
  };

  return (
    <AppConfigContext.Provider
      value={{ ...state, getAppConfig, setAppConfigProperty, updateAppConfig }}
    >
      {children}
    </AppConfigContext.Provider>
  );
};
