const trainings = {
    saved: "Entrenamiento guardado",
    synced:
      "Sincronizando entrenamiento. El entrenamiento puede tomar hasta 15 minutos.",
    refined:
      "Refinando entrenamiento. El entrenamiento puede tomar hasta 15 minutos.",
    executed:
      "Entrenamiento iniciado. El entrenamiento puede tomar hasta 15 minutos.",
}

export default trainings;