const templates = {
  all: "All",
  tag: "Tag",
  type: "Type",
  title: "Templates",
  create: "Add",
  create_template: "Create Template",
  apply_template: "Apply Template",
  confirm: "OK",
  back: "Back",
  saved: "Template saved.",
  search: "Search templates",
  deleted: "Template deleted.",
  field: {
    types: {
      text: "Text",
      number: "Number",
      select: "Select",
    },
  },
  apply: "Choose Conversation",
  favorites: "Show favorites only",
  favorite_saved: "Favorite saved",
  confirm_delete:
    "Are you sure you want to delete this template? This action CANNOT be reversed.",
  card: {
    more: "More",
    public: "Public",
    edit: "Edit Template",
    apply: "Apply Template",
    delete: "Delete Template",
  },
};
export default templates;
