import React, { useContext, useEffect, useState } from "react";
import { ModalContext } from "../../../context/ModalContext";
import { CampaignsContext } from "../../../context/CampaignsContext";
import SearchableSelect from "../../global/SearchableSelect";
import { DocumentsContext } from "../../../context/DocumentsContext";
import { TemplatesContext } from "../../../context/TemplatesContext";

const CampaignResourceForm = ({ onSave, onCancel, resourceType, resourceToEdit }) => {
    const [resource, setResource] = useState(null);
    const { campaign } = useContext(CampaignsContext);
    const { alert } = useContext(ModalContext);
    const [resources, setResources] = useState([]);
    const { getDocuments, documents } = useContext(DocumentsContext);
    const { getPublicTemplates, templates } = useContext(TemplatesContext);

    useEffect(() => {
        getResources(resourceType);
    }, [resourceType]);

    useEffect(() => {

        if (resourceType === 'document' && documents != null) {
            setResources(documents);
        }

        if (resourceType === 'template' && templates != null) {
            setResources(templates);
        }
    }, [documents, templates]);


    const getResources = (type) => {
        if (type === 'document') {
            getDocuments();
        }

        if (type === 'template') {
            getPublicTemplates();
        }
    };

    useEffect(() => {
        if (resourceToEdit) {
            setResource(resourceToEdit);
        }
    }, [resourceToEdit]);


    const handleSave = () => {

        if (campaign != null && campaign.campaign_id != null) {
            onSave({ resource_id: resource.value, campaign_id: campaign.campaign_id, type: resourceType });
        } else {
            alert("Please select a campaign");
        }
    };


    const renderSelect = () => {

        const resourceIdName = resourceType;

        if (resources != null) {
            return (
                <SearchableSelect
                    items={resources}
                    itemText="name"
                    itemValue={`${resourceIdName}_id`}
                    placeholderText={`Select a ${resourceType}...`}
                    modifier={(selectedResource) => setResource(selectedResource)}
                />
            );
        }
    };

    return (
        <div>
            <form>
                <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                        {resourceType.slice(0, -1).charAt(0).toUpperCase() + resourceType.slice(1)}
                    </label>
                    {renderSelect()}
                </div>
                <div className="row">
                    <button type="button" className="col-12 col-md-6 btn" onClick={onCancel}>
                        Cancel
                    </button>
                    <button type="button" className="col-12 col-md-6 btn btn-primary" onClick={handleSave}>
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CampaignResourceForm;
