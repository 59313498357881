import React, { useState, useEffect, useContext } from "react";
import { TemplatesContext } from "../context/TemplatesContext";
import TemplateTypeCard from "../components/templates/TemplateTypeCard";
import useTranslations from "../hooks/useTranslations";

const TemplateTypes = ({ size }) => {
  const [query, setQuery] = useState("");
  const translations = useTranslations();
  const { types, spinner, clearTemplates, getAllTypes } =
    useContext(TemplatesContext);

  useEffect(() => {
    if (query === "") {
      clearTemplates();
    }
    if (!spinner) {
      getAllTypes({ query });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const renderTemplates = () => {
    if (Array.isArray(types)) {
      if (types.length === 0) {
        return <p>No templates available.</p>;
      }
      return types.map(({ type }) => (
        <TemplateTypeCard key={type} type={type} />
      ));
    }
    if (spinner) return <div className="spinner-border"></div>;
  };

  return (
    <div id="templates" className="container-fluid ps-0">
      <h1 className="mb-0 text-capitalize text-gradient d-inline-block">
        Templates by Types
      </h1>
      <input
        type="text"
        value={query}
        className="form-control my-3"
        placeholder={translations.templates.search}
        onChange={(e) => setQuery(e.target.value)}
      />
      <div className="row">{renderTemplates()}</div>
    </div>
  );
};

export default TemplateTypes;
