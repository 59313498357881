import React, { useContext } from "react";
import { SuperFetchContext } from "../../context/SuperFetchContext";
import { ModalContext } from "../../context/ModalContext";
import SuperFetchSaveForm from "./SuperFetchSaveForm";
// import SuperFetchSaveAvatar from "./SuperFetchSaveAvatar";

const SuperFetchGenerateBar = ({
  outputs,
  outputQty,
  handleGenerate,
  increaseOutputs,
  decreaseOutputs,
}) => {
  const { loading, feedback } = useContext(SuperFetchContext);
  const { modalComponent } = useContext(ModalContext);

  const renderLoading = () => {
    if (loading) {
      return (
        <div
          className="spinner-border me-2
        position-absolute top-0 bottom-0 end-0 m-auto"
        ></div>
      );
    }
  };

  const handleSaveAsTemplate = () => {
    modalComponent("Save Super Fetch as Template", <SuperFetchSaveForm />);
  };

  // const handleSaveAsAvatar = () => {
  //   modalComponent("Save Super Fetch as Avatar", <SuperFetchSaveAvatar />);
  // };

  const renderButtons = () => {
    if (feedback.formActive) {
      return [
        <div key="template" className="col-6 px-0">
          <button
            type="button"
            className="btn btn-accent w-100"
            onClick={handleSaveAsTemplate}
          >
            <span className="hide-mobile">Save for Later</span>
            <i className="fas fa-shapes ms-2"></i>
          </button>
        </div>
        // <div key="avatar" className="col-6 pe-0">
        //   <button
        //     type="button"
        //     className="btn btn-accent w-100"
        //     onClick={handleSaveAsAvatar}
        //   >
        //     <span className="hide-mobile">Save as Avatar</span>
        //     <i className="fa fa-users ms-2"></i>
        //   </button>
        // </div>,
      ];
    }
  };

  return (
    <div className="row">
      {renderButtons()}
      <div className="col-6 col-md-8 mt-2 px-0 position-relative">
        <button
          onClick={handleGenerate}
          className="btn btn-primary w-100"
          disabled={loading}
        >
          Generate
          <i
            className={`fa fa-arrow-right ms-2 ${loading ? "d-none" : ""}`}
          ></i>
        </button>
        {renderLoading()}
      </div>
      <div className="col-6 col-md-4 mt-2 px-0">
        <div className="d-flex align-items-center justify-content-end">
          <p className="mb-0 me-2">Outputs:</p>
          <button
            style={{ height: "max-content", cursor: "default" }}
            className="btn btn-accent cursor-defalut me-2"
            disabled={loading}
          >
            {outputQty}
          </button>

          <div className="d-inline-flex flex-column">
            <i
              className="fa fa-chevron-up cursor-pointer mb-2"
              onClick={increaseOutputs}
            ></i>

            <i
              className="fa fa-chevron-down cursor-pointer"
              onClick={decreaseOutputs}
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperFetchGenerateBar;