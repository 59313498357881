

import React, { useContext, useEffect } from "react";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import CustomerData from "../../../components/customers/CustomerData";
import useTranslations from "../../../hooks/useTranslations";
import { navigate } from "@reach/router";
import { ModalContext } from "../../../context/ModalContext";
import { GroupsContext } from "../../../context/GroupsContext";
import moment from "moment";

const OrganizationUser = ({ userId, groupId }) => {

  const {
    getSingleUserOrganization, 
    user_organization: currentUser,
    organization
  } = useContext(OrganizationsContext);

  const { group, getSingleGroup, deleteUserFromGroup } = useContext(GroupsContext);
  const { modalComponent, clearModal } = useContext(ModalContext);

  const translations = useTranslations();
  const usersTranslations = translations.admin.users;

  useEffect(() => {
    getViewData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, groupId, organization]);

  const lastUseDate = moment(currentUser?.last_use).format('YYYY-MM-DD');
  const totalWords = currentUser?.user?.total_words;


  const getViewData = () => {
    const organization_id = organization?.organization_id;
    
    getSingleUserOrganization(userId, organization_id);
    getSingleGroup(groupId);
  }


  const handleDelete = () => {
    modalComponent(
      "Delete User",
      <div>
        <p>
          Are you sure you want to <b>delete this user from group {group.name}?</b>
          All it data will be lost.
        </p>
        <p>This action CANNOT be undone.</p>
        <div className="row">
          <div onClick={clearModal} className="col-6">
            <button className="btn text-muted w-100">Cancel</button>
          </div>
          <div className="col-6">
            <button
              onClick={deleteUserGroup}
              className="btn btn-danger w-100 text-white"
            >
              <i className="fa fa-trash me-2"></i> Delete
            </button>
          </div>
        </div>
      </div>
    );
  }

  const deleteUserGroup = async () => {
    deleteUserFromGroup(userId, groupId)
      .then(res => {
        clearModal();
        navigate(`/organization/groups/${groupId}`);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const renderUsuario = () => {
    if (currentUser && currentUser !== null) {
      return (
        <div className="row">
          <div className="col-12">
            <CustomerData
                customer={currentUser.user}
                handleEdit={() => {}}
                hideEdit
            />

            <button 
              className="btn btn-outline-danger mt-3"
              onClick={handleDelete}
            >
              <i className="fa fa-trash me-2"></i> Delete User
            </button>
          </div>
        </div>
      );
    }

    return <div className="spinner-border"></div>;
  };

  return (
    <div className="container-fluid bg-white p-3 rounded-3 h-100">
      <div className="row mb-3 align-items-center">
        <div className="col-12 col-md-4 px-0">
          <h2 className="text-gradient mb-0 d-inline-block">{currentUser?.user_type.name}</h2>
        </div>
      </div>

      <div className="row position-relative" style={{overflowY: 'auto', flex: 1}}>
        <div className="col-12 col-md-6 px-0">
          <div className="card mb-3">{renderUsuario()}</div>
        </div>

        <div className="col-12 col-md-6">
          <h4>{usersTranslations.recoveryPass}</h4>

          <button
            className="btn btn-outline-dark me-2 my-1"
            // onClick={() => recoverPassword(customer.email)}
          >
            <i className="fa fa-envelope me-2"></i> {usersTranslations.sendEmail}
          </button>
          
          <button
            className="btn btn-outline-dark me-2 my-1"
            // onClick={() => getPasswordResetLink(customer.email)}
          >
            <i className="fa fa-link me-2"></i> {usersTranslations.generateLink}
          </button>

          <div className="card bg-light p-3 mt-3">
            <h4>Total Words</h4>
            <p>{totalWords}</p>
            <h4>Last use</h4>
            <p>{lastUseDate}</p>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default OrganizationUser;
