import React from "react";

const CardList = ({ children }) => {

  return (
    <div className="container-fluid d-flex align-items-start px-0" style={{ flex: 1, overflowY: 'auto' }}>
      <div className="row align-items-start ">
        {children}
      </div>
    </div>
  );
};

export default CardList;
