



import React, { useContext, useEffect } from "react";
import { CampaignsContext } from "../../context/CampaignsContext";

const SelectTemplateData = ({ title, description  }) => {

  useEffect(() => {

  }, []);

  return(
    <div className="col-12 rounded-2 bg-light py-2">
      <h3 className="mb-1">{title}</h3>
      <p className="small m-0">{description}</p>
    </div>
  )
};

export default SelectTemplateData;
