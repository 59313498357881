const invoices = {
  date: "Fecha",
  type: "Tipo",
  total: "Total",
  status: "Estado",
  product: "Producto",
  actions: "Acciones",
  customer: "Cliente",
  next: "Siguiente en",
  discount: "Descuento",
  empty: "No has recibido ningún cargo",
  purchase: "Compra",
  amount: "Monto",
  paymentMethod: "Método de pago",
  saveInvoice: "Guardar Cargo",
  statusCompleted: "Completado",
  statusActive: "Activo",
  statusPending: "Pendiente",
  statusFailed: "Fallido",
  noPurchase: "No Purchase",
  editInvoice: "Edit Cargo",
};

export default invoices;
