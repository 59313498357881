const conversations = {
  spinner: "Hold tight, we're working on your instruction",
  empty: "You don't have any conversations.",
  search: "Search in conversations...",
  deleted: "Conversation deleted.",
  saved: "Conversation saved.",
  archived: "Conversation archived.",
  add: "Add Conversation",
  title: "Conversations",
  button: "Add",
};
export default conversations;
