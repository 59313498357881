import React from "react";
import useTranslations from "../../hooks/useTranslations";

const LoadingCard = ({ text }) => {
  const translations = useTranslations()
  return (
    <div className="bg-white mb-3 rounded-3 shadow border fw-bold" style={{width: 'max-content'}}>
      <p className="loading fs-6 m-1 text-gradient">
        {text ? text : translations.conversations.spinner}
      </p>
    </div>
  );
};
export default LoadingCard;
