import { useEffect, useState } from "react";

const useLocalStorageOrganization = () => {
  const getStoredOrganization = () => {

    let storedOrganization = localStorage.getItem('selectedOrganization')

    if (storedOrganization != undefined && storedOrganization != null) {
      storedOrganization = JSON.parse(localStorage.getItem('selectedOrganization'));
      return storedOrganization;
    } else {
      return { name: "Personal", organization_id: null }
    }

  };

  const [organization, setOrganization] = useState(getStoredOrganization);

  useEffect(() => {
    localStorage.setItem('selectedOrganization', JSON.stringify(organization));
  }, [organization]);

  const storeOrganization = (newOrganization) => {
    localStorage.setItem('selectedOrganization', JSON.stringify(newOrganization));
    setOrganization(newOrganization);
  };

  return { getStoredOrganization, storeOrganization };
};

export default useLocalStorageOrganization
