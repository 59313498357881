import { Link } from "@reach/router";
import React from "react";

const UserWorkflowCard = ({ user_workflow }) => {
  return (
    <div className="card position-relative bg-light py-3 mb-3">
      <div className="card-body pb-2">
        <h3>{user_workflow.name}</h3>
        <p>{user_workflow.description}</p>
      </div>
      <Link
        to={`/user_workflows/${user_workflow.user_workflow_id}`}
        className="btn btn-primary btn-view"
      >
        <i className="fa fa-chevron-right"></i>
      </Link>
    </div>
  );
};

export default UserWorkflowCard;
