import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import {
  CREATE_TRAINING,
  TRAININGS_RECEIVED,
  SET_TRAINING,
  SET_PROPERTY_TRAINING,
} from "../types/trainings";

const schema = {
  training_id: "",
  name: "",
};

const TrainingsReducer = (state, { type, payload }) => {
  switch (type) {
    case TRAININGS_RECEIVED:
      return { ...state, trainings: payload };
    case SET_TRAINING:
      return { ...state, training: payload };
    case CREATE_TRAINING:
      return { ...state, training: schema };
    case SET_PROPERTY_TRAINING:
      const training = { ...state.training };
      const { key, value } = payload;
      training[key] = value;
      return { ...state, training };
    case SHOW_SPINNER:
      return { ...state, spinner: true };
    case HIDE_SPINNER:
      return { ...state, spinner: false };
    default:
      return { ...state };
  }
};
export default TrainingsReducer;
