import { SET_SOCKET } from "../types/socket";

const StaffReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SOCKET:
      return { ...state, socket: payload };
    default:
      return { ...state };
  }
};
export default StaffReducer;
