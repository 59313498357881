const outputs = {
  form: {
    defaultPrompt: "Escribe un tweet",
  },
  rated: "Resultado calificado.",
  saved: "Resultado guardado.",
  deleted: "Resultado eliminado.",
  generated: "Resultados en fila. Aparecerán en la pestaña de 'Resultados'",
};

export default outputs;
