import { useContext, useEffect, useState } from "react";
import { IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { DocumentsContext } from "../context/DocumentsContext";
import { ModalContext } from "../context/ModalContext";
import SelectDocumentData from "../components/campaign/SelectDocumentData";
import { CampaignsContext } from "../context/CampaignsContext";
import { AvatarsContext } from "../context/AvatarsContext";
import EditDocument from "../components/campaign/EditDocument";
import useWindowSize from "../hooks/useWindowSize";

const CampaignDocumentOutput = ({ documentId, campaignId }) => {
	const [tab, setTab] = useState("document");

	const {
		getSingleDocument,
		setDocument,
		setDefaultDocumentBlocks,
		getDocumentByCampaign,
		campaginDocument,
		setLoadingOutputs,
		loadingOutputs,
		setCampaignDocument
	} = useContext(DocumentsContext);

	const { getSingleCampaign } = useContext(CampaignsContext);
	const { modalComponent, clearModal } = useContext(ModalContext);
	const { getAvatars } = useContext(AvatarsContext);
	const isSmallDevice = useWindowSize(1200)

	useEffect(() => {
		getSingleCampaign(campaignId, "template", "");
		getAvatars();
		getSingleDocument(documentId, true);
		getDocumentByCampaign(documentId, campaignId);

		return () => {
			setDocument(null);
			setCampaignDocument(null);
		};
	}, []);

	const handleGenerate = () => {
		clearModal();
		setTab("edit");
		setLoadingOutputs(true);
	};

	const handleSelectTemplate = () => {
		modalComponent(
			"Format the Document",
			<SelectDocumentData
				campaginDocumentId={documentId}
				callback={handleGenerate}
			/>
		);
	};

	const renderTabs = () => {
		return (
			<IonSegment className="" value={tab}>
				<IonSegmentButton value="document" onClick={() => setTab("document")}>
					<IonLabel>Document</IonLabel>
				</IonSegmentButton>
				<IonSegmentButton value="edit" onClick={() => setTab("edit")}>
					<IonLabel>Edit </IonLabel>
				</IonSegmentButton>
			</IonSegment>
		);
	};

	const renderTabContent = () => {
		if (tab === "edit") {
			return <EditDocument />;
		} else {
			return (
				<div
					className="row flex-column"
					style={{ flex: 1, overflow: "hidden" }}
				>
					<div className="row" style={{ height: "50px" }}>
						<div className="col-6 px-0">
							<p className="mb-0 mt-1bold ellipsis">{campaginDocument?.name}</p>
						</div>

						<div className="col-6 px-0 text-end">
							<button
								className="btn btn-primary small"
								onClick={handleSelectTemplate}
								disabled={loadingOutputs}
							>
								Generate Document
								<i className="fas fa-arrow-right ms-2" />
							</button>
						</div>
					</div>
					<div
						dangerouslySetInnerHTML={{ __html: campaginDocument?.content }}
						style={{ overflowY: "auto", flex: 1 }}
					></div>
				</div>
			);
		}
	};

	return (
		<div className="container-fluid d-flex flex-column rounded-3 bg-white h-100 py-3" style={{ padding: isSmallDevice ? "1rem 0" : "" }}>
			<div className="row mb-3">
				<div className="col-12 text-end">{renderTabs()}</div>
			</div>
			{renderTabContent()}
		</div>
	);
};

export default CampaignDocumentOutput;
