import React, { useContext } from "react";
import useTranslations from "../../hooks/useTranslations";
import { TemplatesContext } from "../../context/TemplatesContext";

const TemplateDelete = ({ template, handleCancel, handleCallback }) => {
  const { deleteTemplate } = useContext(TemplatesContext);
  const translations = useTranslations();
  return (
    <div>
      <p>{translations.templates.confirm_delete}</p>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn w-100 text-muted">
            {translations.general.cancel}
          </button>
        </div>
        <div className="col-6">
          <button
            onClick={() => deleteTemplate(template.template_id, handleCallback)}
            className="btn w-100 btn-primary"
          >
            {translations.general.confirm}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TemplateDelete;
