import React, { useState } from "react";
import TagForm from "../../components/global/TagForm";

const useAppInputs = (inputsData, colNum) => {
  const [inputs, setInputs] = useState(inputsData);

  const handleChangeInputs = (event, index) => {
    const updatedInputs = [...inputs];
    const currentInput = updatedInputs[index];
    const newValue = event.target.value;

    currentInput.value = newValue;
    newValue.length > 0 ? currentInput.isValid = true : currentInput.isValid = false;

    setInputs(updatedInputs);
  }

  const handleChangeTagInput = (newValue, index) => {
    const updatedInputs = [...inputs];
    const currentInput = updatedInputs[index];

    currentInput.value = newValue;
    newValue.length > 0 ? currentInput.isValid = true : currentInput.isValid = false;

    setInputs(updatedInputs);
  }
  
  const renderSelect = (input, inputIndex) => {
    return(
      <div key={inputIndex} className={`col-${colNum} mb-2`}>
        <label>{input.label}</label>
        <select
          value={input.value}
          className={`form-control`}
          onChange={(event) => handleChangeInputs(event, inputIndex)}
        >
          {
            input.options.map(opt => {
              return (
                <option key={opt.value} value={opt.value}>{opt.label}</option>
              )
            })
          }
        </select>
      </div>
    )
  }

  const renderTagsInput = (input, inputIndex) => {
    
    return(
      <TagForm
        key={inputIndex}
        tagValues={input.value}
        modifier={(value) => handleChangeTagInput(value, inputIndex)}
      />
    )
  }

  const renderInput = (input, inputIndex) => {
    return(
      <div key={inputIndex} className={`col-${colNum} mb-2`}>
        <label>{input.label}</label>
        <input
          type={'text'}
          className={`form-control ${input.isValid ? '' : 'border border-danger'}`}
          value={input.value}
          onChange={(event) => handleChangeInputs(event, inputIndex)}
        />
        <span 
          className={`small text-danger
          ${input.isValid ? 'd-none' : ''
          }`}
        >
          The value can't be empty
        </span>
      </div>
    )
  }

  const renderAllInputs = () => {
    return inputs.map((input, index) => {
      if(input.type === 'text') return renderInput(input, index);
      if(input.type === 'select') return renderSelect(input, index);
      if(input.type === 'tag') return renderTagsInput(input, index);
    });
  }

  const validateInputs = () => {
    let allInputsValid = true;
    let newInputs = [...inputs];

    newInputs = newInputs.map((input, index) => {
      const updatedInput = {...input}

      if(updatedInput.value.length > 0) {
        updatedInput.isValid = true;
      } 

      if(updatedInput.value.length <= 0 && updatedInput.required !== false) {
        updatedInput.isValid = false;
        allInputsValid = false;
      }

      return updatedInput;
    });

    setInputs(newInputs);
    return allInputsValid;
  }

  const clearAllInputs = () => {
    const newInputs = inputs.map(input => {
      return {
        ...input,
        value: ''
      }
    });

    setInputs(newInputs);
  }

  return {
    renderAllInputs,
    validateInputs,
    inputs,
    clearAllInputs
  }
}

export default useAppInputs;