import React from "react";
import CustomerForm from "../../components/customers/CustomerForm";
import PanelTitle from "../../components/global/PanelTitle";
import useTranslations from "../../hooks/useTranslations";
import ProductForm from "../../components/products/ProductForm";

const AdminProductForm = ({ product_id }) => {
  const translations = useTranslations();
  const formAdd = translations.products.titleAdd;
  const formEdit = translations.products.titleEdit;

  return (
    <div className="container-fluid h-100" style={{ overflowY: 'auto'}}>
      <PanelTitle
        title={`${isNaN(product_id) ? formAdd : formEdit}`}
        hideButton
      />
      <div className="card no-scale p-4">
        <ProductForm product_id={product_id} />
      </div>
    </div>
  );
};

export default AdminProductForm;
