import React from "react";
import ProductRow from "./ProductRow";
import SortableHeader from "../global/SortableHeader";

const ProductsTable = ({
  products,
  extraFields,
  setSort,
  selected,
  direction,
  setDirection,
}) => {
  const renderProducts = () => {
    if (Array.isArray(products) && products.length > 0) {
      return products.map((product) => (
        <ProductRow
          key={product.product_id}
          product={product}
          extraFields={extraFields}
        />
      ));
    }

    return (
      <tr className="text-dark">
        <td colSpan={5}>No products available.</td>
      </tr>
    );
  };

  const renderExtraFields = () => {
    if (Array.isArray(extraFields)) {
      return extraFields.map((field) => <td>{field.label}</td>);
    }
  };

  return (
    <div className="table-responsive">
      <table className="table border">
        <thead className="bg-light border bold small">
          <tr>
            <SortableHeader
              column="product_id"
              label="#ID"
              selected={selected}
              setSort={setSort}
              direction={direction}
              setDirection={setDirection}
            />
            <SortableHeader
              column="name"
              label="Name"
              selected={selected}
              setSort={setSort}
              direction={direction}
              setDirection={setDirection}
            />
            <SortableHeader
              column="word_amount"
              label="Word Amount"
              selected={selected}
              setSort={setSort}
              direction={direction}
              setDirection={setDirection}
            />
            <SortableHeader
              column="price"
              label="Price"
              setSort={setSort}
              selected={selected}
              direction={direction}
              setDirection={setDirection}
            />
            {renderExtraFields()}
            <td>Actions</td>
          </tr>
        </thead>
        <tbody>{renderProducts()}</tbody>
      </table>
    </div>
  );
};

export default ProductsTable;
