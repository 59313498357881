import { useContext } from "react";
import { TemplatesContext } from "../../context/TemplatesContext";


const useTemplateInputs = () => {
  const { template, setPropertyTemplate } = useContext(TemplatesContext);

  const changeField = (newFieldValue, fieldIndex) => {
    const newFields = [...template.fields];
    newFields[fieldIndex] = newFieldValue;

    setPropertyTemplate("fields", newFields);
  };

  const handleChangeOption = (fieldIndex, option) => {
    const newField = {
      ...template.fields[fieldIndex],
    };

    newField.value = option;

    changeField(newField, fieldIndex);
  };

  const renderSelect = (field, fieldIndex, background) => {
    const fieldName = field.name.replace(/^\w/, (c) => c.toUpperCase());

    return (
      <div key={fieldIndex} className="dropdown mb-2 d-flex flex-column">

        <label>
          <b>{fieldName}</b>
        </label>
        <label className="small text-muted d-block mb-1">{field.label}</label>
        <button
          className={`
            form-control dropdown-toggle
            ${background ? background : ''}
          `}
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {field.value}
        </button>

        <ul
          className="dropdown-menu w-100"
          style={{
            padding: 0,
          }}
        >
          {field?.options?.map((opt, optIndex) => {
            return (
              <button
                key={optIndex}
                onClick={() => {
                  handleChangeOption(fieldIndex, opt);
                }}
                type="button"
                className="btn w-100 border form-control"
                style={{
                  borderRadius: 0,
                }}
              >
                {opt}
              </button>
            );
          })}
        </ul>
      </div>
    );
  };

  const renderInput = (field, fieldIndex, background) => {
    const fieldName = field.name.replace(/^\w/, (c) => c.toUpperCase());

    return (
      <div key={fieldIndex} className={`w-100 mb-2`}>
        <label>
          <b>{fieldName}</b>
        </label>

        <label className="small text-muted d-block mb-1">{field.label}</label>
        <input
          type={field.type !== "number" ? "text" : field.type}

          className={
            `form-control z-2 
            w-${field.type === "number" ? "25 text-center" : "100"} 
            ${field.isValidField ? "" : "border border-danger"}
            ${background ? background : ''}
          `}

          value={field.value}
          onChange={(event) => {
            handleChangeInput(event, field, fieldIndex);
          }}
        />

        <span
          className={`text-danger 
          ${field.isValidField ? "d-none" : ""}`}
        >
          The input can't be empty
        </span>
      </div>
    );
  };

  const handleChangeInput = (event, field, fieldIndex) => {
    const value = event.target.value;
    const isValidField = String(value).length > 0 ? true : false;

    const updatedField = {
      ...field,
      value,
      isValidField,
    };

    changeField(updatedField, fieldIndex);
  };

  const renderTemplateFields = (backgroundColor) => {
    if (!Array.isArray(template?.fields)) {
      return;
    }

    const currentFields = template?.fields?.sort((a, b) => {
      return a.order - b.order;
    });

    return currentFields?.map((field, fieldIndex) => {
      if (field.type === "select" && Array.isArray(field.options)) {
        return renderSelect(field, fieldIndex, backgroundColor);
      }

      return renderInput(field, fieldIndex, backgroundColor);
    });
  };

  return {
    renderSelect,
    renderTemplateFields
  }
}

export default useTemplateInputs;