const general = {
  save: "Guardar",
  words: "Palabras",
  options: "Opciones",
  unlimited: "Ilimitadas",
  favorites: "Mostrar solo favoritos",
  cancel: "Cancelar",
  confirm: "OK",
  copy: "Copiar al portapapeles",
  clipboard: "Copiado al portapapeles",
  delete_undone: "Esta acción NO se puede deshacer",
  delete: "Eliminar",
  create: "Crear",
  name: "Nombre",
  edit: "Editar",
  description: "Descripción",
  insert_file: "Agregar Archivo",
  file_types: "Tipos de archivo",
  upload: "Subir",
  download: "Descargar",
  language: "Idioma"
};

export default general;
