import React, { useContext, useEffect, useState } from "react";
import HeaderRow from "../../components/global/HeaderRow";
import Pagination from "../../components/global/Pagination";
import PanelTitle from "../../components/global/PanelTitle";
import EditUserForm from "../../components/users/EditUserForm";
import UserForm from "../../components/users/UserForm";
import UserRow from "../../components/users/UserRow";
import { ModalContext } from "../../context/ModalContext";
import { StaffContext } from "../../context/StaffContext";
import useTranslations from "../../hooks/useTranslations";

const AdminStaff = () => {
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState("");
  const { users, getStaff, deleteStaff, clearStaff } = useContext(StaffContext);

  const translations = useTranslations();

  const { modalComponent } = useContext(ModalContext);

  useEffect(() => {
    getStaff({ page, query });
    return clearStaff;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query]);

  const handleCreate = () => {
    modalComponent(translations.admin.users.addUser, <UserForm />);
  };

  const handleEdit = (user) => {
    modalComponent(translations.admin.users.editUser, <EditUserForm user={user} />);
  };

  const confirmDelete = (user) => {
    modalComponent(
      translations.admin.users.deleteUser,
      <div>
        <p>¿{translations.admin.users.deleteLabel} {user.user.email}?</p>
        <button
          className="btn btn-danger text-white"
          onClick={() => deleteStaff(user.staff_id)}
        >
          {translations.admin.users.deleteBtn}
        </button>
      </div>
    );
  };

  const renderStaffs = () => {
    if (Array.isArray(users)) {
      return users.map((user) => (
        <UserRow
          key={user.user_id}
          user={user}
          editUser={handleEdit}
          deleteUser={confirmDelete}
        />
      ));
    }
  };

  return (
    <div className="container-fluid">
      <PanelTitle title="Users" onClick={handleCreate} />
      <input
        type="text"
        className="form-control mt-1"
        placeholder="Search by email..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <HeaderRow headers={["Name", "Email", "Role", "Actions"]} />
      <div className="container-fluid px-0 mb-3">{renderStaffs()}</div>
      <Pagination modifier={setPage} />
    </div>
  );
};

export default AdminStaff;
