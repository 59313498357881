import api from "./api";

const route = "/appconfig";

const AppConfigService = {
  getAppConfig: () => api.get(route),
  getAdminAppConfig: () => api.get(`${route}/admin`),
  putAppconfig: (payload) => api.put(route, payload),
};

export default AppConfigService;
