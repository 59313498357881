import React from "react";
import CardList from "../../common/CardList";
import CampaignResourceCard from "./CampaignResourceCard";

const CampaignContentList = ({ content, type }) => {

    const renderContent = () => {
        if (Array.isArray(content)) {
            if (content.length === 0) {
                return <p className="p-0">{`No ${type}s found`}</p>;
            }
            return content.map((contentItem, index) => (
                <CampaignResourceCard
                    content={contentItem}
                    type={type}
                    key={index}
                >
                </CampaignResourceCard>
            ));
        }
    };

    return (
        <CardList
            className="row h-max align-items-start"
            style={{ overflowY: "auto", height: "max-content" }}
        >
            {renderContent()}
        </CardList>
    );
};

export default CampaignContentList;
