import admin from "./admin";
import auth from "./auth";
import avatar from "./avatar";
import avatars from "./avatars";
import campaigns from "./campaigns";
import conversation from "./conversation";
import conversations from "./conversations";
import documents from "./documents";
import general from "./general";
import generator from "./generator";
import invoices from "./invoices";
import messages from "./messages";
import organization from "./organization";
import outputs from "./outputs";
import purchases from "./purchases";
import superfetch from "./superfetch";
import templates from "./templates";
import trainings from "./trainings";
import user_workflows from "./user_workflows";
import workflows from "./workflows";
import products from "./products";

const es = {
  products,
  openai: {
    error: 'Lo sentimos, OpenAI esta teniendo problemas con tu solicitud.'
  },
  user_avatars: {
    title: "Mis Avatares",
  },
  form: {
    cancel: "Cancelar",
    confirm: "Guardar",
  },
  hashtags: {
    title: "Generador de Hashtags",
  },
  menu: {
    settings: "Ajustes",
    logout: "Salir",
  },
  topics: {
    title: "Generador de Temas de Tendencia",
  },
  user_workflows,
  conversations,
  conversation,
  organization,
  superfetch,
  templates,
  trainings,
  documents,
  campaigns,
  workflows,
  generator,
  invoices,
  purchases,
  messages,
  outputs,
  general,
  avatars,
  avatar,
  admin,
  auth
};

export default es;
