
import api from './api';
import { getArgs } from "../utils";
const route = '/documents';

export default {
  getDocuments: (organization_id, filters) => api.get(`${route}?organization_id=${organization_id}&${getArgs(filters)}`),
  getSingleDocument: (document_id) => api.get(`${route}/${document_id}`),
  getDocumentByCampaign: (campaing_document_id, campaing_id) => api.get(`${route}/${campaing_document_id}/campaigns/${campaing_id}`),
  getDocumentConverted: (document_id) => api.get(`${route}/doc-converted/${document_id}`, {
    responseType: "blob",
  }),
  postDocument: (document) => api.post(route, { ...document }),
  generateAvatarOutput: (data) => api.post(`${route}/avatar-output`, { ...data }),
  putDocument: (document) => api.put(route, { ...document }),
  deleteDocument: (Document) => api.delete(`${route}/${Document}`),
}