import React, { useContext } from "react";
import { UserWorkflowsContext } from "../../context/UserWorkflowsContext";
import { ModalContext } from "../../context/ModalContext";
import { navigate } from "@reach/router";

const ConfirmDeleteUserWorkflow = ({ user_workflow_id }) => {
  const { clearModal } = useContext(ModalContext);
  const { deleteUserWorkflow } = useContext(UserWorkflowsContext);
  return (
    <div>
      <p>
        Are you sure you want to delete this workflow? This will delete your
        field values and generated content.
      </p>
      <div className="row align-items-center">
        <div className="col-6">
          <button onClick={clearModal} className="btn w-100 text-muted">
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button
            onClick={() =>
              deleteUserWorkflow(user_workflow_id, () =>
                navigate("/user_workflows")
              )
            }
            className="btn w-100 btn-danger"
          >
            <i className="fa fa-trash"></i> Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteUserWorkflow;
