import { Link } from "@reach/router";
import React, { useContext, useEffect } from "react";
// import bunny from "../../assets/bunny.png";
import useDarkMode from "../../hooks/useDarkMode";
import { formatMonto, getValue } from "../../utils";
import { AuthContext } from "../../context/AuthContext";
import { AppConfigContext } from "../../context/AppConfigContext";
import { MenuContext } from "../../context/MenuContext";
import useTranslations from "../../hooks/useTranslations";
import UserMenu from "../menu/UserMenu";
import AdminMenu from "../menu/AdminMenu";
import AdminAnalyticsMenu from "../menu/AdminAnalyticsMenu";
import OrganizationPicker from "../menu/OrganizationPicker";
import OrganizationMenu from "../menu/OrganizationMenu";
import ToolsMenu from "../menu/ToolsMenu";
import { OrganizationsContext } from "../../context/OrganizationsContext";
import useCurrentOrganizationUser from "../../hooks/organizations/useCurrentOrganizationUser";

const Sidebar = () => {
  useDarkMode();

  const { tabs, getAppTabs, organizationTabs, setDefaultTabs } =
    useContext(MenuContext);
  const { user, user_organization } = useContext(AuthContext);
  const appconfig = useContext(AppConfigContext);
  // const { fetch_icon_url } = appconfig;

  const translations = useTranslations();
  const { lang } = translations;

  const { getCurrentUserType } = useCurrentOrganizationUser();
  const { organization, getUserOrganizations } =
    useContext(OrganizationsContext);

  useEffect(() => {
    getAppTabs();

    return () => {
      setDefaultTabs();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserOrganizations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, organization]);

  const currentUserRole = getCurrentUserType();
  const isOrganizationAdmin = currentUserRole === "Member" ? false : true;

  const renderAdmin = () => {
    if (user.staff && user.staff !== null && organization.name === "Personal") {
      return (
        <>
          <AdminMenu />
          <AdminAnalyticsMenu />
        </>
      );
    }
  };

  const renderCampaignMenu = () => {
    if (organizationTabs && organization.organization_id) {
      if (organizationTabs.campaign.name) {
        return (
          <Link
            to={organizationTabs.campaign?.link}
            key={organizationTabs.campaign?.link}
            className="btn small w-100 my-2 text-dark hover-success text-left"
          >
            <div className="row">
              <div className="col-2">
                <i className={organizationTabs.campaign.icon}></i>
              </div>
              <div className="col-10">
                {organizationTabs.campaign.name[lang]}
              </div>
            </div>
          </Link>
        );
      }
    }
  };

  const renderOrganizationsMenu = () => {
    if (
      organization.organization_id &&
      isOrganizationAdmin &&
      user_organization
    ) {
      return <OrganizationMenu />;
    }
  };

  const renderTabs = () => {
    if (Array.isArray(tabs)) {
      return tabs.map((tab) => (
        <Link
          to={tab.link}
          key={tab.link}
          className="btn small w-100 my-2 text-dark hover-success text-left"
          style={{ minWidth: "100%" }}
        >
          <div className="row w-100">
            <div className="col-2">
              <i className={tab.icon}></i>
            </div>
            <div className="col-10 pe-0" style={{ width: "max-content" }}>{tab?.name[lang]}</div>
          </div>
        </Link>
      ));
    }
  };

  const renderOrganizationsTabs = () => {
    if (organizationTabs) {
      return (
        <div>
          {<OrganizationPicker />}
          {renderOrganizationsMenu()}
        </div>
      );
    }
  };

  const renderFetch = () => {
    // if (["bemodo.ai", "localhost"].includes(window.location.hostname)) {
    //   return (
    //     <div className="row">
    //       <div className="col-2 ps-1">
    //         <img
    //           src={fetch_icon_url}
    //           alt="Fetch"
    //           style={{ maxWidth: 25, marginLeft: "-10" }}
    //           className="d-inline-block"
    //         />
    //       </div>
    //       <div className="col-10">Fetch</div>
    //     </div>
    //   );
    // }
    return (
      <div className="row">
        <div className="col-2">
          <i className="fa fa-comments" />
        </div>
        <div className="col-10">Chats</div>
      </div>
    );
  };

  return (
    <div
      className="sidebar bg-white d-flex flex-column 
      overflow-y-auto"
      style={{ top: 25 }}
    >
      <img
        src={
          getValue(user, "dark_mode", true)
            ? getValue(appconfig, "dark_logo")
            : getValue(appconfig, "light_logo")
        }
        style={{ maxWidth: "120px" }}
        className="my-4 d-block w-100 mx-auto"
        alt="logo"
      />
      <div className="sidebar-menu">
        <div className="px-3">
          <div className="text-center pt-2 border br-10 mb-3">
            <h4>{translations.general.words}</h4>
            <p className="mb-2">
              {user.available_words === 1000000 ? (
                <span>
                  <i className="fa fa-infinity me-2"></i>
                  {translations.general.unlimited}
                </span>
              ) : (
                formatMonto(user.available_words)
              )}
            </p>
          </div>
        </div>
        <Link
          to="/"
          className="btn small w-100 my-2 text-dark hover-success text-left"
        >
          {renderFetch()}
        </Link>
        {renderTabs()}
        {renderCampaignMenu()}
        {!user.has_access && (
          <Link
            to="/pricing"
            className="btn small w-100 my-2 text-dark hover-success text-left"
          >
            <div className="row">
              <div className="col-2">
                <i className="fa fa-star me-2"></i>
              </div>
              <div className="col-10">Upgrade</div>
            </div>
          </Link>
        )}
        <ToolsMenu />
        {renderAdmin()}
        {renderOrganizationsTabs()}
        <UserMenu />
      </div>
    </div>
  );
};

export default Sidebar;
