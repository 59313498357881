import React, { useContext, useEffect, useState } from "react";
import useTranslations from "../../../hooks/useTranslations";
import CampaignCard from "../../../components/organization/campaign/CampaignCard"
import CampaignsHandler from "../../../components/organization/campaign/CampaignsHandler";
import { CampaignsContext } from "../../../context/CampaignsContext";
import { OrganizationsContext } from "../../../context/OrganizationsContext";
import { AuthContext } from "../../../context/AuthContext";
import useCurrentOrganizationUser from "../../../hooks/organizations/useCurrentOrganizationUser";
import useWindowSize from "../../../hooks/useWindowSize";

const Campaigns = () => {
    const { getCurrentUserType } = useCurrentOrganizationUser();
    const { handleCreateCampaign } = CampaignsHandler();
    const translations = useTranslations();
    const currentUserRole = getCurrentUserType();
    const [query, setQuery] = useState("");
    const [sortBy, setSortBy] = useState("");
    const { getCampaigns, campaigns, campaign } = useContext(CampaignsContext)
    const { organization } = useContext(OrganizationsContext)
    const { user_organization } = useContext(AuthContext);
    const isOrganizationAdmin = currentUserRole === 'Member' ? false : true;

    const isSmallDevice = useWindowSize(1200);



    useEffect(() => {
        if (organization != null && organization.organization_id) {
            getCampaigns(organization.organization_id, query, sortBy);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization, campaign, query, user_organization, sortBy]);


    const renderCampaigns = () => {
        if (campaigns != null && campaigns.length > 0) {
            return campaigns.map((campaign) => (
                <CampaignCard campaign={campaign} key={campaign.campaign_id} className="mt-2" />
            ))
        } else {
            return <p>{translations.campaigns.noCampaigns}</p>
        }
    }

    const renderCampaignButton = () => {
        if (user_organization && isOrganizationAdmin) {
            return <div className="col-md-3 col-6 " style={{ padding: '0 10px' }}>
                <button
                    className="btn btn-primary col-12"
                    onClick={handleCreateCampaign}
                >
                    + {translations.campaigns.button}
                </button>
            </div>
        }
    }

    return (
        <div className="container-fluid d-flex flex-col bg-white card position-relative h-100" style={{ padding: !isSmallDevice ? "1rem" : "1rem 0" }}>
            <div className="row">
                <div className="col">
                    <h1 className="mb-0 text-capitalize text-gradient d-inline-block">
                        {translations?.campaigns.title}
                    </h1>
                </div>
            </div>

            <div className="row align-items-center mb-3 mt-3">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <input
                        type="text"
                        value={query}
                        className="form-control p-0 py-2"
                        placeholder={translations?.campaigns.search}
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </div>

                <div className="col-6 col-md-3">
                    <select
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                        className="form-control d-inline-block"
                    >
                        <option value="createdAt_desc">Last Created</option>
                        <option value="updatedAt_desc">Last Used</option>
                        <option value="name_asc">A-Z</option>
                        <option value="name_desc">Z-A</option>
                    </select>
                </div>
                {renderCampaignButton()}
            </div>
            <div
                className="row mt-3"
                style={{ overflowY: "auto" }}
            >
                {renderCampaigns()}
            </div>
        </div>
    );
};

export default Campaigns;
