import React, { useContext, useState } from "react";
import AvatarInputFiles from "./AvatarInputFiles";
import AvatarFileCard from "./AvatarFileCard";
import useTranslations from "../../hooks/useTranslations";
import { AvatarsContext } from "../../context/AvatarsContext";

const AvatarFilesSection = ({ avatar }) => {
  const [filesTab, setFilesTab] = useState("files");
  const [editMode, setEditMode] = useState(false);
  const [editingFile, setEditingFile] = useState({});

  const translations = useTranslations();

  const { updateAvatarFile, spinner } = useContext(AvatarsContext);

  const componentHeight = editMode ? 'calc((100% - 162px)/2)' : 'calc((100% - 225px)/2)'

  const renderEditableFileCards = () => {
    if (filesTab === "editable-files" && !editMode) {
      const currentEditableFiles = avatar?.avatar_files.filter((file) => {
        if (file.editable) return file;
      });

      if (currentEditableFiles?.length > 0) {
        return (
          <div className="px-0 col-12 h-100" style={{ overflowY: "auto" }}>
            {currentEditableFiles.map((file, index) => {
              const handleEdit = () => {
                setEditMode(true);
                setEditingFile(file);
              };

              return (
                <AvatarFileCard
                  key={file.file_data?.file_id}
                  fileName={file.file_data?.name}
                  fileId={file.file_data?.file_id}
                  type={file.file_data?.type}
                  openaiFileId={file.openai_file_id}
                  assistantId={avatar.assistant_id}
                  editable={true}
                  content={file.content}
                  onEdit={handleEdit}
                />
              );
            })}
          </div>
        );
      } else {
        return (
          <div className="col-12 px-0" style={{ flex: 1 }}>
            <div className="card bg-light p-3">
              <h3 className="text-primary">
                {translations.avatar.editable_files_empty}
              </h3>
              <p>{translations.avatar.editable_files_description}</p>
            </div>
          </div>
        );
      }
    }
  };

  const renderFileCards = () => {
    if (filesTab === "files" && !editMode) {
      const currentFiles = avatar?.avatar_files.filter((file) => {
        if (!file.editable) return file;
      });

      if (currentFiles?.length > 0) {
        return (
          <div className="px-0 col-12 h-100" style={{ overflowY: "auto", }}>
            {currentFiles.map((file, index) => {
              return (
                <AvatarFileCard
                  key={file.file_data?.file_id}
                  fileName={file.file_data?.name}
                  fileId={file.file_data?.file_id}
                  type={file.file_data?.type}
                  openaiFileId={file.openai_file_id}
                  assistantId={avatar.assistant_id}
                />
              );
            })}
          </div>
        );
      } else {
        return (
          <div className="col-12 px-0" style={{ flex: 1 }}>
            <div className="card bg-light p-3">
              <h3 className="text-primary">
                {translations.avatar.files_empty}
              </h3>
              <p>{translations.avatar.files_description}</p>
            </div>
          </div>
        );
      }
    }
  };

  const renderAvatarInputFiles = () => {
    const editable = filesTab === "editable-files";
    const currentFileTypes = editable ? ".txt" : ".docx, .pdf, .pptx, .txt";

    if(!editMode) {
      return (
        <AvatarInputFiles editable={editable} fileTypes={currentFileTypes} />
      );
    }
  };

  const handleUpdateFile = async () => {
    await updateAvatarFile(editingFile);
    setEditMode(false);
  }

  const renderIcon = () => {
    if(spinner) {
      return <div className="spinner-border position-absolute top-0 bottom-0 
      end-0 m-auto me-3"></div>
    } else {
      return(
        <i 
          className="position-absolute top-0 bottom-0 
          end-0 fas fa-bookmark fs-4 m-auto me-3"
          style={{
            height: 'max-content'
          }}
        />
      )
    }
  }

  const renderAvatarSaveEdit = () => {
    if(editMode) {
      return (
        <div className="row mt-5" style={{ height: "40px" }}>
          <div className="col-6 ps-0">
            <button 
              type="button"
              className="btn btn-link w-100 text-muted"
              onClick={() => setEditMode(false)}
            >
              Cancel
            </button>
          </div>

          <div className="col-6 pe-0">
            <button
              type="button"
              className="btn btn-primary w-100 position-relative"
              disabled={spinner}
              onClick={handleUpdateFile}
            >
              Save
              {renderIcon()}
            </button>
          </div>
        </div>
      )
    }
  }

  const renderEditFile = () => {
    if (editMode && editingFile) {
      return (
        <textarea
          rows="5"
          type="text"
          value={editingFile.content}
          className="form-control"
          style={{
            maxHeight: '100%'
          }}
          onChange={(e) => {
            const value = e.target.value;
            setEditingFile({
              ...editingFile,
              content: value,
            });
          }}
        />
      );
    }
  };

  return (
    <>
      <div
        className="row mt-5 flex-column"
        style={{
          height: componentHeight,
          minHeight: "215px",
          overflowY: "hidden",
        }}
      >
        <div className="col-12 px-0 h-100">
          <ul className="nav nav-tabs">
            <li
              onClick={() => {
                setFilesTab("files");
                setEditMode(false);
              }}
              className="nav-item h-max-content px-0 disable-hover-bg"
            >
              <a
                className={`nav-link ${filesTab === "files" ? "active" : ""}`}
                aria-current="page"
                href="#"
              >
                {translations.avatar.files}
              </a>
            </li>
            <li
              onClick={() => {
                setFilesTab("editable-files");
                setEditMode(false);
              }}
              className="nav-item h-max-content px-0 disable-hover-bg"
            >
              <a
                className={`nav-link ${
                  filesTab === "editable-files" ? "active" : ""
                }`}
                aria-current="page"
                href="#"
              >
                {translations.avatar.editable_files}
              </a>
            </li>
          </ul>

          <div className="row overflow-y-auto" style={{ height: 'calc(100% - 38.4px)'}}>
            {renderFileCards()}
            {renderEditableFileCards()}
            {renderEditFile()}
          </div>
        </div>
      </div>

      {renderAvatarInputFiles()}
      {renderAvatarSaveEdit()}
    </>
  );
};

export default AvatarFilesSection;
