import React from "react";
import { Link, useLocation } from "@reach/router";
import useWindowSize from "../../hooks/useWindowSize";

const BreadcrumbNav = () => {
    const location = useLocation();
    const isSmallDevice = useWindowSize(1200);
    let currentLink = "";

    const crumbs = location.pathname.split("/").filter((crumb) => crumb !== "" && isNaN(crumb));
    const removeNumbersAndSlashes = (str) => str.replace(/^\//, '').replace(/\d+/g, '').replace(/\/$/, '');

    const exceptionRoutes = [
        { route: 'templates', filter: ['templates'] },
        { route: 'organization', filter: ['organization'] },
        { route: 'conversations', filter: ['conversations'] },
        { route: 'avatars', filter: ['avatars'], backLink: "avatars" },
        { route: 'documents', filter: ['documents'], backLink: "documents" },
        { route: 'organization/admin-panel', filter: [], backLink: "organizations" },
        { route: 'organization/groups', filter: [], backLink: "organizations" },
        { route: 'organization/campaigns', filter: ['organization', 'campaigns'], backLink: "organization/campaigns" },
        { route: 'organizations/organization-settings', filter: ['organization-settings'], backLink: "organizations" },
        { route: 'organization/campaigns//users', filter: ['organization', 'campaigns',], backLink: "organizations" },
        { route: 'campaign//documents', filter: [] },
        { route: 'admin/analytics/income', filter: ['admin', 'analytics', 'income'] },
        { route: 'admin/analytics/products', filter: ['admin', 'analytics', 'products'] },
        { route: 'admin/analytics/customers', filter: ['admin', 'analytics', 'customers'] },
        { route: 'chat-widget-generator', filter: ['chat-widget-generator'] },
        { route: 'topics', filter: ['topics'] },
        { route: 'super-fetch', filter: ['super-fetch'] },
        { route: 'hashtags', filter: ['hashtags'] },
        { route: 'organizations', filter: ['organizations'] },
        { route: 'tutorials', filter: ['tutorials'] },
        { route: 'billing', filter: ['billing'] },
        { route: 'settings', filter: ['settings'] },
        { route: 'auth', filter: ['auth'] },
    ];

    const exceptionBreadcrumb = (crumbs, exceptionRoutes) => {

        for (const route of exceptionRoutes) {
            if (removeNumbersAndSlashes(location.pathname) === route.route) {
                const filters = route.filter;
                crumbs = crumbs.filter((crumb) =>
                    !filters.some((filter) => crumb.includes(filter))
                );
            }
        }

        return crumbs;
    };

    const renderBackButton = () => {

        const currentRoute = exceptionRoutes.find((route) =>
            removeNumbersAndSlashes(location.pathname) === route.route
        );

        let backLink = () => window.history.back();

        if (currentRoute?.backLink) {
            backLink = currentRoute.backLink
        }

        return (
            <li className="breadcrumb-item">
                {typeof backLink === 'string' ? (
                    <Link to={backLink}>
                        <i className="fa fa-chevron-left me-1"></i> Back
                    </Link>
                ) : (
                    <button onClick={backLink} style={{ color: "grey", border: "none", backgroundColor: "transparent", fontSize: "15px", padding: 0 }}>
                        <i className="fa fa-chevron-left me-1"></i> Back
                    </button>
                )}
            </li>
        );
    };

    const formatCrumbText = (crumb) => {
        const formattedText = crumb
            .replace(/[-%]/g, ' ')
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        return formattedText;
    };

    const resultCrumbs = exceptionBreadcrumb(crumbs, exceptionRoutes);

    const breadcrumbComponents = resultCrumbs.map((crumb, index) => {
        // if (!isNaN(crumb)) { return }
        currentLink += `/${crumb}`;
        return (
            <li key={index} className="breadcrumb-item">
                {index === resultCrumbs.length - 1 ? (
                    formatCrumbText(crumb)
                ) : (
                    <Link to={currentLink} style={{ textTransform: 'capitalize' }}>
                        {formatCrumbText(crumb)}
                    </Link>
                )}
            </li>
        );
    });

    const renderBreadCrumbs = () => {
        const resultCrumbs = exceptionBreadcrumb(crumbs, exceptionRoutes);
        return resultCrumbs.length <= 2 ? renderBackButton() : breadcrumbComponents;
    };


    return breadcrumbComponents.length > 0 ? (
        <nav
            className="row"
            aria-label="breadcrumb"
            style={{
                paddingLeft: !isSmallDevice ? "20px" : "12px",
                maxHeight: !isSmallDevice ? "30px" : "40px",
            }}
        >
            <ol
                className="breadcrumb"
                style={{ marginBottom: !isSmallDevice ? "20px" : "8px" }}
            >
                {renderBreadCrumbs()}
            </ol>
        </nav>
    ) : (
        <div style={{ display: "none", height: "0" }}></div>
    );
};

export default BreadcrumbNav;