import { Link } from "@reach/router";
import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { DocumentsContext } from "../../context/DocumentsContext";
import DocumentForm from "./DocumentForm";

const DocumentCard = ({ document, link, handleCallback }) => {
  const { clearModal, modalComponent } = useContext(ModalContext);
  const { setDocument, deleteDocument } = useContext(DocumentsContext);

  const documentId = document?.document_id;
  const currentLink = link
    ? `${link}/${documentId}`
    : `/documents/${documentId}`;

  // const handleCancel = () => {
  //   setConversation(null);
  //   clearModal();
  // };

  const handleClick = () => {
    if (typeof handleCallback === "function") {
      handleCallback();
    }
  };

  const handleEdit = () => {
    setDocument(document);
    modalComponent(
      "Edit Document",
      <DocumentForm />
    );
  };

  const handleDelete = () => {
    modalComponent(
      "Delete Document",
      <div>
        <p>
          Are you sure you want to delete this document? This action CANNOT
          be reversed.
        </p>
        <div className="row">
          <div className="col-6">
            <button onClick={clearModal} className="btn w-100 text-muted">
              Cancel
            </button>
          </div>
          <div className="col-6">
            <button
              onClick={() => deleteDocument(document.document_id)}
              className="btn w-100 btn-danger"
            >
              <i className="fa fa-trash me-2"></i>Delete
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="col-12 col-md-4">
      <div
        className="card position-relative bg-light mb-3 my-3"
        style={{
          height: "130px",
        }}
      >
        <div className="card-body pb-2 position-relative">
          {document?.name}
        </div>

        <div className="card-footer bg-light border-0 pb-3">
          <button
            onClick={handleEdit}
            className="btn btn-sm btn-round border ms-2"
          >
            <i className="fa fa-edit"></i>
          </button>
          <button
            onClick={handleDelete}
            className="btn btn-sm btn-outline-danger btn-round ms-2"
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
        <Link
          to={currentLink}
          onClick={handleClick}
          className="btn btn-primary btn-view"
        >
          <i className="fa fa-file"></i>
        </Link>
      </div>
    </div>
  );
};

export default DocumentCard;
