import { SELECT_TABS, SET_DEFAULT_TABS, SET_ORGANIZATION_TABS, SET_SELECTED, SET_TABS_DATA, SET_TOOLS_TABS, TOGGLE_SETTINGS } from "../types/menu";

const MenuReducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SELECTED:
      return { ...state, selected: payload };
    case SET_DEFAULT_TABS:
      return payload;
    case SET_TOOLS_TABS:
      const updatedTabs = {
        ...state.toolsTabs,
      }
      updatedTabs.tools.tabs = [...payload];
      return { ...state, toolsTabs: updatedTabs };
    case SET_TABS_DATA:
      return { ...state, tabs: [...state.tabs, ...payload] };
    case TOGGLE_SETTINGS:
      return { ...state, showSettings: !state.showSettings };
    case SET_ORGANIZATION_TABS:
      return { ...state, organizationTabs: payload };
    default:
      return { ...state };
  }
};

export default MenuReducer;
