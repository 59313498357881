import React, { useContext } from "react";
import { ModalContext } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";
import { AppConfigContext } from "../../context/AppConfigContext";
const IntroVideo = () => {
  const { clearModal } = useContext(ModalContext);
  const { user, updateUser } = useContext(AuthContext);
  const { app_name, intro_video_url, high_ticket_offer_link } = useContext(AppConfigContext);

  const hideForever = () => {
    updateUser({ ...user, show_video: false }, clearModal, true);
  };
  return (
    <div>
      <div>
        <iframe
          title={`Welcome to ${app_name}`}
          src={intro_video_url}
          allow="autoplay; fullscreen; picture-in-picture"
          className="mw-100 w-100 my-3 vimeo-player"
          allowFullScreen
        ></iframe>
      </div>
      <div className="row">
        <div className="col-6">
          <button
            onClick={clearModal}
            className="btn w-100 btn-link text-muted"
          >
            No, Thanks
          </button>
        </div>
        <div className="col-6">
          <a
            href={high_ticket_offer_link}
            className="btn w-100 btn-primary"
            target="_blank"
            rel="noreferrer"
          >
            Schedule a 1 on 1 Call
          </a>
        </div>
      </div>
      <button
        onClick={hideForever}
        className="btn btn-sm btn-link text-center mt-4 d-block m-auto"
      >
        Don't show this again
      </button>
    </div>
  );
};

export default IntroVideo;
