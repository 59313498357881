/**
 * Ordenes
 */
export const TEST_PURCHASE_RECEIVED = "TEST_PURCHASE_RECEIVED";
export const PURCHASES_RECIBIDAS = "PURCHASES_RECIBIDAS";
export const SET_PROPERTY_PURCHASE = "SET_PROPERTY_PURCHASE";
export const SET_PAYMENT_NEEDED = "SET_PAYMENT_NEEDED";
export const INVOICES_RECIBIDOS = "INVOICES_RECIBIDOS";
export const SET_PURCHASE = "SET_PURCHASE";
export const SET_INVOICE = "SET_INVOICE";
