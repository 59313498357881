

import React, { useContext, useEffect, useState } from "react";
import { CampaignsContext } from "../../context/CampaignsContext";
import SelectTemplateData from "./SelectTemplateCard";
import { AvatarsContext } from "../../context/AvatarsContext";
import useFilesConverter from "../../hooks/global/useFilesConverter";
import { DocumentsContext } from "../../context/DocumentsContext";
import { OrganizationsContext } from "../../context/OrganizationsContext";
import { ModalContext } from "../../context/ModalContext";

const SelectDocumentData = ({ 
  campaginDocumentId, 
  callback,
}) => {
  const [templateSelected, setTemplateSelected] = useState(null);
  const [avatarSelected, setAvatarSelected] = useState(null);
  const [type, setType] = useState('templates');

  const { campaign } = useContext(CampaignsContext);
  const { clearModal } = useContext(ModalContext);
  const { userAvatars } = useContext(AvatarsContext);
  const { documentUserAvatarOutput } = useContext(DocumentsContext);
  const { organization } = useContext(OrganizationsContext);

  const modalTitle = type === 'templates' ? 'Select a Template' : 'Select an Avatar';


  const renderResources = () => {
    if(type === 'templates') {
      return renderTemplates();
    } else {
      return renderAvatars();
    }
   
  }

  const renderTemplates = () => {
    return campaign.template.map(resource => {
      const template_id = resource.template.template_id;
      const isSelected = template_id === templateSelected ? true : false;

      return(
        <div
          key={template_id}
          onClick={() => {
            setTemplateSelected(template_id);
          }}
          className={`row px-0 mb-2 border border-2 rounded-2 cursor-pointer
          ${isSelected ? 'border-primary': 'border-secondary'}`}
        >
          <SelectTemplateData 
            title={resource.template.name} 
            description={resource.template.description}
          />
        </div>
      )
    })
  }

  const renderAvatars = () => {
    return userAvatars.map(avatar => {
      const avatar_id = avatar.avatar_id;
      const isSelected = avatar_id === avatarSelected ? true : false;

      return(
        <div
          key={avatar_id}
          onClick={() => {
            setAvatarSelected(avatar_id)
          }}
          className={`row px-0 mb-2 border border-2 rounded-2 cursor-pointer
          ${isSelected ? 'border-primary': 'border-secondary'}`}
        >
          <SelectTemplateData 
            title={avatar.name} 
            description={avatar.description}
          />
        </div>
      )
    })
  }

  const handleNextBtn = () => {
    if(type === 'templates') {
      setType('avatars');
    } else {
      const currentAvatar = userAvatars.find(obj => {
        return obj.avatar_id === avatarSelected;
      });

      documentUserAvatarOutput({
        campaign_document_id: campaginDocumentId,
        template_id: templateSelected,
        avatar_id: avatarSelected,
        assistant_id: currentAvatar.assistant_id,
        organization_id: organization.organization_id,
        campaign_id: campaign.campaign_id,
        campaign_name: campaign.name
      });

      if(typeof callback === 'function') callback();
    }
  }

  const renderNextBtn = () => {
    return(
      <button
        disabled={templateSelected === null ? true : false}
        className="btn btn-primary small"
        onClick={handleNextBtn}
      >
        {type === 'templates' ? 'Next' : 'Generate'}
        <i className="fas fa-arrow-right ms-2"></i>
      </button>
    )
  }

  return(
    <div className="row">
      <p className="text-bold px-0">{modalTitle}</p>
      {renderResources()}

      <div className="col-12 text-end">
        {renderNextBtn()}
      </div>
    </div>
  )
};

export default SelectDocumentData;
