const invoices = {
  date: "Date",
  type: "Type",
  total: "Total",
  next: "Next on",
  status: "Status",
  product: "Product",
  actions: "Actions",
  customer: "Customer",
  discount: "Discount",
  empty: "You haven't received any invoices",
  purchase: "Purchase",
  amount: "Amount",
  paymentMethod: "Payment method",
  saveInvoice: "Save Invoice",
  statusCompleted: "Completed",
  statusActive: "Active",
  statusPending: "Pending",
  statusFailed: "Failed",
  noPurchase: "No Purchase",
  editInvoice: "Edit Invoice",
};

export default invoices;
