import { useContext } from "react";
import { TemplatesContext } from "../../context/TemplatesContext";

export default function useTemplateFilters() {
  const { filters, setFilters } = useContext(TemplatesContext);

  const setFilterValue = (key, value) => {
    const updatedFilters = { 
      ...filters, 
      [key]: value
    }

    setFilters(updatedFilters);
  }

  const setManyFilterValues = (values) => {
    let updatedFilters = { 
      ...filters, 
    }

    values.map(current => {
      const { key, value } = current;
      updatedFilters[key] = value;
    })

    setFilters(updatedFilters);
  }

  const setTemplatesTab = (value) => {
    setFilterValue('templates_tab', value);
  }

  const setQuery = (value) => {
    setFilterValue('query', value);
  }

  const setSortBy= (value) => {
    setFilterValue('sortBy', value);
  }

  const setPage = (value) => {
    setFilterValue('page', value);
  }

  const setShowFavorites = (value) => {
    setFilterValue('showFavorites', value);
  }

  const setCurrentTag = (value) => {
    setFilterValue('currentTag', value);
  }

  const setCurrentType = (value) => {
    setFilterValue('currentType', value);
  }


  return {
    filters, 
    setFilterValue,
    setManyFilterValues,
    setTemplatesTab,
    setQuery,
    setSortBy,
    setPage,
    setShowFavorites,
    setCurrentTag,
    setCurrentType
  }
}