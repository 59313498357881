export const setupColor = (key, color) => {
  document.documentElement.style.setProperty(`--${key}`, color);
  document.documentElement.style.setProperty(`--ion-color-${key}`, color);
};

export const setupTitle = (app_name, app_tagline) => {
  let title = `${app_name}`;
  if(app_tagline) {
    title += `| ${app_tagline}`;
  }
  document.title = title;
};
