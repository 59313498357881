import React, { useContext, useState } from "react";
import useTranslations from "../../../hooks/useTranslations";
import { Link } from "@reach/router";
import { OrganizationsContext } from "../../../context/OrganizationsContext";

const OrganizationPanelHeader = ({ query, setQuery }) => {

  const { organization } = useContext(OrganizationsContext);
  const translations = useTranslations();
  const usersTranslations = translations.admin.users;

  return(
    <div className="row  align-items-center" >
      <div className="col-12 col-md-4 px-0">
        <h2
          className="text-gradient mb-0 d-inline-block"
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          Organization Users
        </h2>
      </div>

      <div className="col-12 col-md-8 px-0 align-items-center" >
        <div className="row justify-content-end">

          <div className="col-10 col-md-8 px-0">
            <input
              type="text"
              className="form-control bg-white"
              placeholder="Search..."
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>

          <div className="col-2 col-md-4 text-right">
            <Link to="add-user" className="btn btn-primary w-100 px-0">
              + <span className="hide-mobile">{usersTranslations.addBtn}</span>
            </Link>
          </div>
          
        </div>
      </div>
      
    </div>
  );
}

export default OrganizationPanelHeader;