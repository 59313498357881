import React, { useEffect, useState, useContext } from "react";
import useTranslations from "../hooks/useTranslations";
import DocumentCard from "../components/documents/DocumentCard";
import CardList from "../components/common/CardList";
import { DocumentsContext } from "../context/DocumentsContext";
import { ModalContext } from "../context/ModalContext";
import DocumentCreateForm from "../components/documents/DocumentForm";
import { OrganizationsContext } from "../context/OrganizationsContext";
import useWindowSize from "../hooks/useWindowSize";

const Documents = () => {
  const { getDocuments, createDocument, documents } =
    useContext(DocumentsContext);
  const { modalComponent } = useContext(ModalContext);
  const { organization } = useContext(OrganizationsContext);
  const [query, setQuery] = useState("");
  const [sortBy, setSortBy] = useState("");

  const isSmallDevice = useWindowSize(1200);
  const translations = useTranslations();

  useEffect(() => {
    getDocuments({ query, sortBy });
  }, [query, sortBy]);

  useEffect(() => {
    getDocuments();
  }, [organization]);

  const handleCreateDocument = () => {
    createDocument();
    modalComponent("Create new Document", <DocumentCreateForm />);
  };

  const renderDocuments = () => {
    if (documents && documents.length > 0) {
      return documents?.map((doc) => (
        <DocumentCard key={doc.document_id} document={doc} />
      ));
    } else {
      return <p>{translations.documents.noDocuments}</p>;
    }
  };

  return (
    <div className="container-fluid d-flex flex-column h-100 rounded-3">
      <div className="row mb-2">
        <div className="col-12">
          <h1 className="text-capitalize text-gradient mb-0 d-inline-block">
            Documents
          </h1>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 mb-3">
          <input
            type="text"
            value={query}
            className="form-control"
            placeholder={"Search Documents"}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="col-6 col-md-3 mb-3">
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
            className="form-control d-inline-block"
          >
            <option value="createdAt_desc">Last Created</option>
            <option value="updatedAt_desc">Last Used</option>
            <option value="name_asc">A-Z</option>
            <option value="name_desc">Z-A</option>
          </select>
        </div>
        <div
          className="col-6 col-md-3 mb-3"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <button
            className="btn btn-primary w-100"
            onClick={handleCreateDocument}
          >
            + Add
          </button>
        </div>
      </div>
      <CardList>{renderDocuments()}</CardList>
    </div>
  );
};

export default Documents;
