import React, { useEffect, useState, useContext } from "react";
import SearchableSelect from "../components/global/SearchableSelect";
import { AvatarsContext } from "../context/AvatarsContext";
import { ModalContext } from "../context/ModalContext";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { AuthContext } from "../context/AuthContext";
import ImageInput from "../components/common/ImageInput";
import FilesService from "../services/FilesService";
import { S3_ENDPOINT } from "../utils";

const ChatWidgetGenerator = () => {
    const { avatars, getAvatars, clearAvatars } = useContext(AvatarsContext);
    const { success, alert } = useContext(ModalContext);
    const [selectedColor, setSelectedColor] = useState("#ff0000");
    const [widgetName, setWidgetName] = useState("");
    const [avatar_id, setAvatarId] = useState("");
    const [uid, setUid] = useState(false);
    const [image, setWidgetImage] = useState("");
    const [showMarkdownPreview, setShowMarkdownPreview] = useState(false);

    const { user } = useContext(AuthContext);

    useEffect(() => {
        if (!user?.uid) return;
        setUid(user.uid);
    }, [user]);

    useEffect(() => {
        setShowMarkdownPreview(false);
    }, [selectedColor, widgetName, avatar_id, image]);

    const generatedHTML = `
    <div
      id="chat-widget"
      data-uid="${uid}"
      data-avatar-id="${avatar_id}"
      data-accent="${selectedColor.slice(1)}"
      data-widget-name="${widgetName}"
      data-image="${image ? S3_ENDPOINT : ""}"
    ></div>
    <link href="./widget/index.css" rel="stylesheet" />
    <script src="./widget/index.js"></script>
  `;
    const handleColorChange = (e) => {
        setSelectedColor(e.target.value);
    };

    const handleWidgetNameChange = (e) => {
        setWidgetName(e.target.value);
    };

    useEffect(() => {
        fetchAvatars();
    }, []);

    const fetchAvatars = (query) => {
        clearAvatars();
        getAvatars(query);
    };

    const saveImage = (file) => {
        const formData = FilesService.getFormData(file);
        FilesService.postFile(formData).then((res) => {
        });
    };

    const displayAvatars = () => {
        if (avatars && avatars.length > 0) {
            return (
                <SearchableSelect
                    items={avatars}
                    itemText={"name"}
                    itemValue={"avatar_id"}
                    placeholderText={"Select an Avatar..."}
                    modifier={(avatar) => setAvatarId(avatar.value)}
                />
            );
        }
    };

    const generateAvatarWidget = () => {
        let tempImage;

        if (avatar_id === "") {
            alert("Please select an Avatar");
            return;
        }

        if (widgetName === "") {
            alert("Please type in a name for your Widget");
            return;
        }

        if (image && image != tempImage) {
            saveImage(image);
            tempImage = image;
        }

        setShowMarkdownPreview(true);
        success(`${widgetName} Widget Generated`);
    };

    const copyChatWidgetCode = () => {
        navigator.clipboard.writeText(generatedHTML).then(() => {
            success("Avatar widget code copied to clipboard!");
        });
    }

    return (
        <div className="container py-3 d-flex flex-column h-100 w-100">
            <h1 className="text-gradient d-inline-block">Chat Widget Generator</h1>
            <div className="card p-3 shadow-sm w-100 " style={{ overflowY: "auto", flex: 1 }}>
                <div className="row my-3 align-items-center">
                    <div className="col-12 col-md-6">
                        <h4>Designated Avatar</h4>
                        <p className="small">The avatar serving your chat widget</p>
                    </div>
                    <div className="col-12 col-md-6">{displayAvatars()}</div>
                </div>
                <div className="row my-3 align-items-center">
                    <div className="col-12 col-md-6">
                        <h4>Chat Widget Name</h4>
                        <p className="small">Name displayed on the chat header</p>
                    </div>
                    <div className="col-12 col-md-6">
                        <input
                            className="form-control w-100"
                            value={widgetName}
                            onChange={handleWidgetNameChange}
                        />
                    </div>
                </div>
                <div className="row my-3 align-items-center">
                    <div className="col-12 col-md-6">
                        <h4>Accent Color</h4>
                        <p className="small">
                            Color displayed in the header and chat launcher
                        </p>
                    </div>
                    <div className="col-9 col-md-5">
                        <input
                            type="text"
                            value={selectedColor}
                            onChange={(e) => setSelectedColor(e.target.value)}
                            className="form-control"
                            placeholder="#ff0000"
                        />
                    </div>
                    <div className="col-3 col-md-1 ps-0">
                        <input
                            type="color"
                            value={selectedColor}
                            onChange={handleColorChange}
                            className="w-100"
                        />
                    </div>
                </div>

                <div className="row my-3 align-items-center">
                    <div className="col-12 col-md-6">
                        <h4>Chat Widget Button Image</h4>
                        <p className="small">
                            Image displayed in the widget button (optional)
                        </p>
                    </div>
                    <div className="col-12 col-md-6 p-0">
                        <ImageInput
                            value={image}
                            modifier={(e) => {
                                setWidgetImage(e);
                            }}
                        />
                    </div>
                </div>

                <div className="row my-3" style={{ padding: "0 12px" }}>
                    <button
                        className="btn btn-primary mt-3"
                        onClick={generateAvatarWidget}
                    >
                        Generate Chat Widget
                    </button>
                </div>

                {showMarkdownPreview && (
                    <div className="row my-3 align-items-center">
                        <MarkdownPreview source={generatedHTML} />

                        <div className="row my-3" style={{ padding: "0 12px" }}>
                            <button
                                className="btn btn-secondary mt-3"
                                onClick={copyChatWidgetCode}
                            >
                                Copy Widget To Clipboard
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatWidgetGenerator;
