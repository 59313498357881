import React, { useContext, useEffect, useState } from "react";
import TemplateGenerateCard from "../templates/TemplateGenerateCard";
import { TemplatesContext } from "../../context/TemplatesContext";
import { TranslationsContext } from "../../context/TranslationsContext";
import TemplateAsideApply from "../templates/TemplateAsideApply";
import SuperFetchAside from "../super_fetch/SuperFetchAside";
import TextareaInput from "./TextareaInput";
import { AuthContext } from "../../context/AuthContext";
import { DocumentsContext } from "../../context/DocumentsContext";
import { SuperFetchContext } from "../../context/SuperFetchContext";
import DocumentDownloadButtons from "../documents/DocumentActionsButtons";
import MobileModal from "../mobile/MobileModal";
import { CapacitorContext } from "../../context/CapacitorContext";
import TemplateDocumentToolbar from "../templates/TemplateDocumentToolbar";
import SuperFetchService from "../../services/SuperFetchService";
import { OrganizationsContext } from "../../context/OrganizationsContext";
import useTranslations from "../../hooks/useTranslations";
import DocumentAvatarTools from "../documents/DocumentAvatarTools";
import { useDocumentText } from "../../hooks/documents/useDocumentText";

function DocumentTools({
  createPDF,
  createWord,
  toggleAside,
  asideActive,
  sideBarWidth: width,
}) {
  const [promptValid, setPromptValid] = useState(true);
  const [promptValue, setPromptValue] = useState("");
  const [cardsActive, setCardsActive] = useState(true);
  const [section, setSection] = useState("avatars");

  const translations = useTranslations();
  const { getContextPrompt } = useDocumentText();

  const { user } = useContext(AuthContext);
  const { platform } = useContext(CapacitorContext);
  const { setOutputs } = useContext(SuperFetchContext);
  const { organization } = useContext(OrganizationsContext);
  const { getSingleTemplate, template } = useContext(TemplatesContext);
  const { setLoadingOutputs, loadingOutputs } = useContext(DocumentsContext);

  const hideHeader = platform !== "web";
  const sideBarWidth = platform !== "web" ? "100%" : width;

  const sectionNames = {
    templates: translations.templates.title,
    avatars: translations.avatars.title,
    prompt: translations.documents.prompt,
    download: translations.general.download,
  };

  const handleApplyTemplate = (template) => {
    getSingleTemplate(template.template_id);
    setCardsActive(false);
  };

  const handleChangeSection = (newSection) => {
    setSection(newSection);
  };

  const renderCards = () => {
    if (cardsActive && section === "templates") {
      return (
        <TemplateDocumentToolbar
          handleApplyTemplate={handleApplyTemplate}
          cardsActive={cardsActive}
        />
      );
    }
  };

  const renderSingleTemplate = () => {
    if (!cardsActive && section === "templates") {
      return (
        <div className="">
          <TemplateAsideApply template={template} />
        </div>
      );
    }
  };

  const renderBackBtn = () => {
    if (!cardsActive) {
      let text;
      if (section === "avatars") text = "Avatars";
      if (section === "templates") text = "Templates";

      return (
        <button
          type="button"
          className="btn btn-accent small position-absolute 
          start-0 top-0 mt-2 ms-2"
          onClick={() => setCardsActive(true)}
          style={{
            zIndex: 3,
          }}
        >
          <i className="fa fa-chevron-left me-2"></i>
          {text}
        </button>
      );
    }
  };

  const renderAsideSuperFetch = () => {
    if (section === "fetch") {
      return <SuperFetchAside />;
    }
  };

  const handleChangePrompt = (value) => {
    value.length <= 0 ? setPromptValid(false) : setPromptValid(true);
    setPromptValue(value);
  };

  const handleGeneratePrompt = async () => {
    if (promptValue.length > 0) {
      setOutputs([]);
      setLoadingOutputs(true);

      const assistant_id = user.defaultAssistant?.id;
      const service = SuperFetchService.getOutput;

      const modifiedPrompt = getContextPrompt(promptValue);
      const data = {
        messages: [modifiedPrompt],
        assistant_id,
      };

      if (organization && organization.organization_id) {
        data.organization_id = organization.organization_id;
      }

      service(data).catch((err) => {
        console.log(err);
      });

      // getSuperFetchPrompt(1, assistant_id, promptValue);
    } else {
      setPromptValid(false);
    }
  };

  const renderLoading = () => {
    if (loadingOutputs) {
      return (
        <div className=" bg-white mt-3 rounded-3 shadow  fw-bold">
          <p className="loading fs-6 m-1 text-gradient">
            {translations.conversations.spinner}
          </p>
        </div>
      );
    }
  };

  const renderAsidePrompt = () => {
    if (section === "prompt") {
      return (
        <div className="px-0 row">
          <TextareaInput
            label={"Insert your instruction!"}
            placeholder={"Write a 50 words paragraph..."}
            valid={promptValid}
            value={promptValue}
            handleChange={handleChangePrompt}
            maxHeight={"200px"}
            smallDescription
            smallContent
            bgWhite
          />

          {renderLoading()}

          <button
            className="btn btn-primary small position-relative mt-3"
            onClick={handleGeneratePrompt}
            disabled={loadingOutputs}
          >
            <i className="fas fa-chevron-left me-2"/>
            Generate
          </button>
        </div>
      );
    }
  };

  const renderDownloads = () => {
    if (section === "download") {
      return (
        <DocumentDownloadButtons
          createPDF={createPDF}
          createWord={createWord}
        />
      );
    }
  };

  const renderSectionBtns = () => {
    if (cardsActive) {
      return (
        <div className="dropdown w-max-content pe-0">
          <button
            style={{ backgroundColor: "unset", boxShadow: "unset" }}
            className="btn btn-sm btn-outline-primary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {translations.general.options}
          </button>

          <ul className="dropdown-menu py-0">
            <li>
              <button
                type="button"
                className={`text-dark dropdown-item px-2`}
                style={{
                  minWidth: "max-content",
                }}
                onClick={() => handleChangeSection("templates")}
              >
                <i
                  className={`fas fa-shapes me-2 ${
                    section === "templates" ? "text-primary" : "text-accent"
                  }`}
                  style={{ width: "20px", height: "20px" }}
                />
                {translations.templates.title}
              </button>
            </li>

            <li>
              <button
                type="button"
                className={`text-dark dropdown-item  px-2`}
                onClick={() => handleChangeSection("avatars")}
                style={{
                  minWidth: "max-content",
                }}
              >
                <i
                  className={`fa fa-users me-2 ${
                    section === "avatars" ? "text-primary" : "text-accent"
                  }`}
                  style={{ width: "20px", height: "20px" }}
                />
                {translations.avatars.title}
              </button>
            </li>

            <li>
              <button
                type="button"
                className={`text-dark dropdown-item  px-2`}
                style={{
                  minWidth: "max-content",
                }}
                onClick={() => handleChangeSection("prompt")}
              >
                <i
                  className={`fa fa-bolt me-2 ${
                    section === "prompt" ? "text-primary" : "text-accent"
                  }`}
                  style={{ width: "20px", height: "20px" }}
                />
                {translations.documents.prompt}
              </button>
            </li>

            <li>
              <button
                type="button"
                className={`text-dark dropdown-item  px-2`}
                style={{
                  minWidth: "max-content",
                }}
                onClick={() => handleChangeSection("download")}
              >
                <i
                  className={`fas fa-download me-2 ${
                    section === "download" ? "text-primary" : "text-accent"
                  }`}
                  style={{ width: "20px", height: "20px" }}
                />
                {translations.general.download}
              </button>
            </li>
          </ul>
        </div>
      );
    }
  };

  const renderAvatarTools = () => {
    if(section === 'avatars') return <DocumentAvatarTools/>
  }

  const documentToolsComponent = (
    <div
      className="card bg-light h-100 d-flex flex-column p-0  border"
      style={{ overflowX: "hidden", width: sideBarWidth }}
    >
      <div className={`w-100 d-flex card-header ${hideHeader ? "d-none" : ""}`}>
        <h4 className="w-50">Tools</h4>
      </div>
      <div
        className="card-body position-relative"
        style={{ width: "100%", overflowX: "hidden", overflowY: "auto" }}
      >
        <div className="row justify-content-between px-0">
          <p className="ps-0 text-gradient w-max-content bold">
            {sectionNames[section]}
          </p>
          {renderSectionBtns()}
        </div>
        {renderAvatarTools()}
        {renderBackBtn()}
        {renderCards()}
        {renderAsideSuperFetch()}
        {renderSingleTemplate()}
        {renderAsidePrompt()}
        {renderDownloads()}
      </div>
    </div>
  );

  const mobileVersion = (
    <div
      className={`position-absolute end-0 z-10 bottom-0 h-100 px-0`}
      style={{ width: 460 }}
    >
      <MobileModal title={"Tools"} isOpen={asideActive} setIsOpen={toggleAside}>
        {documentToolsComponent}
      </MobileModal>
    </div>
  );

  return platform !== "web" ? mobileVersion : documentToolsComponent;
}

export default DocumentTools;
