
import {
  SET_CAMPAIGN,
  CREATE_CAMPAIGN,
  CAMPAIGNS_RECEIVED,
  SET_PROPERTY_CAMPAIGN,
} from "../types/Campaigns";

const schema = {

}

const CampaignsReducer = (state, { type, payload }) => {
  switch (type) {
    case CAMPAIGNS_RECEIVED:
      return { ...state, campaigns: payload };
    case SET_CAMPAIGN:
      return { ...state, campaign: payload };
    case CREATE_CAMPAIGN:
      return { ...state, campaign: schema };
    case SET_PROPERTY_CAMPAIGN: {
      const { key, value } = payload;
      const campaign = { ...state.campaign };
      campaign[key] = value;
      return { ...state, campaign };
    }
    default:
      return { ...state };
  }
};

export default CampaignsReducer;
