import { Link } from "@reach/router";
import React, { useContext } from "react";
import CampaignResourceHandler from "./CampaignResourceHandler";
import { CampaignsContext } from "../../../context/CampaignsContext";


const CampaignResourceCard = ({ content, type }) => {
    const { campaign } = useContext(CampaignsContext);
    const { handleDeleteCampaignResource, } = CampaignResourceHandler(type)

    const resource = content[type]

    let currentLink = `/campaign/${campaign.campaign_id}/${type}s/${resource[`${type}_id`]}`;

    if (type === 'template') {
        currentLink = `/${type}s/edit-template/${resource[`${type}_id`]}`;
    }


    const icon = type === "document" ? "fa fa-file" : "fa fa-shapes"

    return (
        <div
            className="card bg-light mb-3 my-3 col-12 col-xl-4 ps-0 me-3"
            style={{
                height: "130px",
            }}
        >
            <div className="card-body pb-2 position-relative">
                {resource.name}
            </div>

            <div className="card-footer bg-light border-0 pb-3">
                <button
                    onClick={() => handleDeleteCampaignResource(content, type)}
                    className="btn btn-sm btn-outline-danger btn-round ms-2"
                >
                    <i className="fa fa-trash"></i>
                </button>
            </div>
            <Link
                to={currentLink}
                className="btn btn-primary btn-view"
            >
                <i className={icon}></i>
            </Link>
        </div>
    );
};

export default CampaignResourceCard;
