import React, { useContext } from "react";
import { S3_ENDPOINT } from "../../utils";
import { Link } from "@reach/router";
import { ModalContext } from "../../context/ModalContext";
import { AvatarsContext } from "../../context/AvatarsContext";
import useTranslations from "../../hooks/useTranslations";

const AvatarCard = ({ avatar }) => {
  const translations = useTranslations();
  const { deleteAvatar } = useContext(AvatarsContext);
  const { clearModal, modalComponent } = useContext(ModalContext);

  const handleDelete = () => {
    modalComponent(
      `${translations.general.delete} Avatar`,
      <div>
        <p>
          {translations.avatars.delete_confirm} "{avatar.name}"?
        </p>
        <p>{translations.general.delete_undone}</p>
        <div className="row">
          <div onClick={clearModal} className="col-6">
            <button className="btn text-muted w-100">{translations.general.cancel}</button>
          </div>
          <div className="col-6">
            <button
              onClick={() => deleteAvatar(avatar.avatar_id)}
              className="btn btn-danger w-100 text-white"
            >
              <i className="fa fa-trash me-2"></i> {translations.general.delete}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderImage = () => {
    if (avatar && avatar !== null) {
      const { file } = avatar;
      if (file && file !== null) {
        return (
          <img
            src={`${S3_ENDPOINT}/${file.name}.${file.type}`}
            className="user-img m-auto round"
            alt={avatar.name}
          />
        );
      }
    }
    return <i className="fa fa-user-circle text-muted fa-3x"></i>;
  };

  return (
    <div className="card position-relative bg-light p-3 mb-3">
      <div className="row align-items-center">
        <div className="col-2 px-0">{renderImage()}</div>
        <div className="col-10">
          <h3 className="h4 bold">{avatar.name}</h3>
          <button
            onClick={handleDelete}
            className="btn btn-sm btn-outline-danger btn-round"
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      </div>
      <Link
        to={`/avatars/${avatar.avatar_id}`}
        className="btn btn-view btn-primary px-2"
      >
        <i className="fa fa-chevron-right"></i>
      </Link>
    </div>
  );
};

export default AvatarCard;
