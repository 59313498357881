import React, { useContext } from "react";
import { CampaignsContext } from "../../../context/CampaignsContext";
import { ModalContext } from "../../../context/ModalContext";
import CampaignAccessForm from "./CampaignAccessForm";
import useTranslations from "../../../hooks/useTranslations";

const CampaignAccessHandler = () => {
    const { modalComponent, clearModal } = useContext(ModalContext);
    const { giveCampaignAccess, revokeCampaignAccess, } = useContext(CampaignsContext);

    const translations = useTranslations()

    const handleCancel = () => {
        clearModal();
    };

    const handleGiveCampaignAccess = (campaign_id, type) => {
        modalComponent(
            `${translations.campaigns.giveAccess}`,
            <CampaignAccessForm
                onCancel={handleCancel}
                onSave={giveCampaignAccess}
                campaign_id={campaign_id}
                type={type}
            />
        );
    };


    const handleRevokeCampaignAccess = ({ campaign_id, user_id, group_id }, goBack) => {

        const onDelete = () => {
            revokeCampaignAccess({ campaign_id, user_id, group_id }, goBack);
            if (goBack) {
                window.history.go(-1);
            }
        };

        modalComponent(
            `${translations.campaigns.revokeAccess}`,
            <div className="w-100 d-flex mt-3">
                <button className="btn me-1 w-50" onClick={clearModal}>
                    Cancel
                </button>
                <button className="btn btn-danger ms-1 w-50 text-white" onClick={onDelete}>
                    {translations.campaigns.revokeAccess.split(' ')[0]}
                </button>
            </div>
        );
    };

    return {
        handleGiveCampaignAccess,
        handleRevokeCampaignAccess,
    };
};

export default CampaignAccessHandler;
