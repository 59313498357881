import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { createReactEditorJS } from 'react-editor-js';
import { EDITOR_JS_TOOLS, parseBlock } from "../../utils/editor-tools";
import DocumentTools from "../common/DocumentTools";
import { CapacitorContext } from "../../context/CapacitorContext";
import useWindowSize from "../../hooks/useWindowSize";
import { DocumentsContext } from "../../context/DocumentsContext";
import { SuperFetchContext } from "../../context/SuperFetchContext";
import DocumentHeader from "../documents/DocumentHeader";
import useFilesConverter from "../../hooks/global/useFilesConverter";
import { htmlToBlocks } from "../../utils";

const ReactEditorJS = createReactEditorJS();

const EditDocument = () => {
  const [content, setContent] = useState('');
  const [asideActive, setAsideActive] = useState(false);
  const editorCore = useRef(null);
  const smallDevice = useWindowSize(1200);

  const {
    document,
    userDocumentAvatarOutput,
    setLoadingOutputs,
    saveDocument,
    setDocumentBlocks,
    documentBlocks,
    loadingOutputs,
    getDocumentConverted,
    documentBuffer
  } = useContext(DocumentsContext);
  const { outputs } = useContext(SuperFetchContext);

  const { export2Doc, export2pdf } = useFilesConverter();



  useEffect(() => {
    if (document?.content?.length > 0) {
      setTimeout(() => {
        const currentBlocks = htmlToBlocks(document.content);
        handleRender(currentBlocks);
      }, 100);
    }

    return () => {
      setDocumentBlocks([]);
    }
  }, []);


  useEffect(() => {
    if(documentBuffer) {
      export2pdf(documentBuffer);
    }
  }, [documentBuffer]);

  const toggleAside = () => {
    setAsideActive(!asideActive);
  };

  const createWord = () => {
    export2Doc("document");
  };

  const createPDF = () => {
    getDocumentConverted(document.document_id);
  };

  useEffect(() => {
    if (outputs && outputs !== null && outputs.length > 0) {
      setLoadingOutputs(false);

      const newBlocks = [
        ...documentBlocks,
        {
          id: `block-${documentBlocks.length + 1}`,
          type: "paragraph",
          data: {
            text: outputs[0].content[0].text.value,
          }
        }
      ];

      handleRender(newBlocks);
    }
  }, [outputs]);

  useEffect(() => {
    if (userDocumentAvatarOutput !== null) {
      setTimeout(() => {
        handleAddOutput();
      }, 50);
    }
  }, [userDocumentAvatarOutput]);

  const handleRender = useCallback(async (newBlocks) => {
    if (editorCore.current) {
      await editorCore?.current?.render({ blocks: newBlocks });
      await handleSave();
    }
  }, []);

  const handleAddOutput = () => {
    setLoadingOutputs(false);

    const newBlocks = [
      ...documentBlocks,
      {
        id: `block-${documentBlocks.length + 1}`,
        type: "paragraph",
        data: {
          text: userDocumentAvatarOutput,
        }
      }
    ]

    handleRender(newBlocks);
    setDocumentBlocks(newBlocks);
  }

  const handleSave = useCallback(async () => {
    if (editorCore.current) {
      const savedData = await editorCore.current?.save();
      setDocumentBlocks(savedData?.blocks);
      handleBlocksToHTML(savedData?.blocks);
    }
  }, []);


  const handleBlocksToHTML = (blocks) => {
    let newContent = '';

    blocks?.map(block => {
      newContent += parseBlock(block);
    });

    setContent(newContent);
  }

  const handleInitialize = useCallback((instance) => {
    editorCore.current = instance;
  }, []);

  const renderDocumentTools = () => {
    if (asideActive) {
      return <DocumentTools
        createPDF={createPDF}
        createWord={createWord}
        toggleAside={toggleAside}
        asideActive={asideActive}
        sideBarWidth={450}
      />
    }
  };

  const handleSaveDocumentContent = () => {
    saveDocument({
      ...document,
      content
    });
  };


  return (
    <div className="row position-relative" style={{ flex: 1, overflow: "hidden" }}>
      <div className="h-100" style={{ overflowY: 'auto', flex: 1 }}>
        <DocumentHeader
          document={document}
          handleSaveDocumentContent={handleSaveDocumentContent}
          smallDevice={smallDevice}
          toggleAside={toggleAside}
          asideActive={asideActive}
        />
        <ReactEditorJS
          onChange={handleSave}
          onInitialize={handleInitialize}
          defaultValue={{ blocks: documentBlocks }}
          tools={EDITOR_JS_TOOLS}
        />
      </div>
      {renderDocumentTools()}
    </div>
  )
};

export default EditDocument;
