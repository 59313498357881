import { useContext, useEffect, useState } from "react";
import { TemplatesContext } from "../../context/TemplatesContext";
import { TranslationsContext } from "../../context/TranslationsContext";
import TemplateGenerateCard from "./TemplateGenerateCard";
import TemplateTagCard from "./TemplateTagCard";
import TemplateTypeCard from "./TemplateTypeCard";
import useTranslations from "../../hooks/useTranslations";
import useTemplateFilters from "../../hooks/templates/useTemplateFilters";

const TemplateDocumentToolbar = ({ handleApplyTemplate, cardsActive }) => {
    const {
        tags,
        types,
        spinner,
        templates,
        getAllTags,
        getAllTypes,
        getPublicTemplates,
    } = useContext(TemplatesContext);
    const { lang } = useContext(TranslationsContext);

    const { 
        filters,
        setTemplatesTab,
        setManyFilterValues,
        setQuery,
        setSortBy,
        setShowFavorites,
    } = useTemplateFilters();

    const {
        templates_tab,
        query,
        sortBy,
        page,
        showFavorites,
        currentTag,
        currentType,
    } = filters;

    const translations = useTranslations();

    useEffect(() => {
        if (templates_tab === "tags") {
            getAllTags({ query, page, language: lang, sortBy });
        }

        if (templates_tab === "type") {
            getAllTypes({ query, page, language: lang, sortBy });
        }

        if (templates_tab === "") {
            getPublicTemplates({
                query,
                sortBy,
                page,
                tag: currentTag,
                type: currentType,
                favorites: showFavorites,
                language: lang,
            });
        }
    }, [
        query,
        sortBy,
        templates_tab,
        lang,
        page,
        showFavorites,
        currentTag,
        currentType,
    ]);

    const handleSetType = (type) => {
        setManyFilterValues([
            { key: 'currentTag', value: null },
            { key: 'currentType', value: type },
            { key: 'templates_tab', value: "" },
        ]);
    };

    const handleSetTag = (tag) => {
        setManyFilterValues([
            { key: 'currentType', value: null },
            { key: 'currentTag', value: tag },
            { key: 'templates_tab', value: "" },
        ]);
    };
    
    const handleBack = () => {
        if (currentType) {
          setManyFilterValues([
            { key: 'templates_tab', value: "type" },
            { key: 'currentType', value: null },
          ]);
        } else {
          setManyFilterValues([
            { key: 'templates_tab', value: "tags" },
            { key: 'currentTag', value: null },
          ]);
        }
      };

    const renderTemplates = () => {
        if (templates?.length > 0 && templates_tab === "") {
            return templates?.map((template) => {
                return (
                    <TemplateGenerateCard
                        key={template.template_id}
                        data={template}
                        handleApply={handleApplyTemplate}
                    />
                );
            });
        }

        if (Array.isArray(tags) && templates_tab === "tags") {
            return tags?.map((tag) => (
                <TemplateTagCard
                    key={tag}
                    tag={tag}
                    setCurrentTag={handleSetTag}
                />
            ));
        }
        if (Array.isArray(types) && templates_tab === "type") {
            return types.map(({ type }) => (
                <TemplateTypeCard
                    key={type}
                    type={type}
                    setCurrentType={handleSetType}
                />
            ));
        }
        if (spinner) return <div className="spinner-border"></div>;
    };

    const renderButtons = () => {
        if (currentType || currentTag) {
            return (
                <button onClick={handleBack} className="btn btn-outline-primary">
                    <i className="fa fa-chevron-left me-2"></i>{" "}
                    {translations.templates.back}
                </button>
            );
        } else {
            return (
                <div
                    className="btn-group d-inline-block border br-25"
                    role="group"
                    style={{
                        minWidth: 180,
                    }}
                >
                    <button
                        type="button"
                        onClick={() => setTemplatesTab("type")}
                        className={`btn btn-sm ${templates_tab === "type" ? "btn-primary" : ""
                            }`}
                    >
                        {translations.templates.type}
                    </button>
                    <button
                        type="button"
                        onClick={() => setTemplatesTab("tags")}
                        className={`btn btn-sm ${templates_tab === "tags" ? "btn-primary" : ""
                            }`}
                    >
                        {translations.templates.tag}
                    </button>
                    <button
                        type="button"
                        onClick={() => setTemplatesTab("")}
                        className={`btn btn-sm ${templates_tab === "" ? "btn-primary" : ""
                            }`}
                    >
                        {translations.templates.all}
                    </button>
                </div>
            );
        }
    };

    const renderEmptyState = () => {
        if (templates?.length <= 0 && templates_tab === "") {
            return <p>There aren't any templates</p>;
        }

        if (tags?.length <= 0 && templates_tab === "tags") {
            return <p>There aren't any Tags</p>;
        }

        if (types?.length <= 0 && templates_tab === "type") {
            return <p>There aren't any Types</p>;
        }
    };

    const renderQueryInputs = () => {
        return (
            <>
                <div className="col-12 mb-2 px-0">
                    <input
                        type="text"
                        value={query}
                        className="form-control bg-white"
                        placeholder="Search..."
                        onChange={(e) => setQuery(e.target.value)}
                    />
                </div>
                <div className="col-12 px-0 mb-2">
                    <select
                        value={sortBy}
                        onChange={(e) => setSortBy(e.target.value)}
                        className="form-control d-inline-block bg-white w-100"
                    >
                        <option value="createdAt_desc">Last Created</option>
                        <option value="updatedAt_desc">Last Used</option>
                        <option value={`name_asc`}>A-Z</option>
                        <option value={`name_desc`}>Z-A</option>
                    </select>
                </div>

                <div className="col-12 mb-2 d-flex justify-content-end px-0">
                    {renderButtons()}
                    <button
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={translations.templates.favorites}
                        className={`ms-2 btn btn-round btn-sm ${showFavorites > 0 ? "btn-warning text-dark" : "border"
                            } me-2`}
                        onClick={() => setShowFavorites(!showFavorites)}
                    >
                        <i className="fa fa-star"></i>
                    </button>
                </div>
            </>
        );
    };

    return (
        <div className="row align-items-center my-3">
            {renderQueryInputs()}
            <div className="mt-2 card p-2">
                {renderEmptyState()}
                {renderTemplates()}
            </div>
        </div>
    );
};

export default TemplateDocumentToolbar;
