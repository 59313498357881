import React, { createContext, useReducer, useContext } from "react";
import WorkflowsService from "../services/WorkflowsService";
import WorkflowsReducer from "../reducers/WorkflowsReducer";
import {
  WORKFLOWS_RECEIVED,
  SET_WORKFLOW,
  CREATE_WORKFLOW,
  SET_PROPERTY_WORKFLOW,
} from "../types/workflows";
import { ModalContext } from "./ModalContext";
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import useTranslations from "../hooks/useTranslations";

const initialState = {
  workflows: null,
  workflow: null,
};

export const WorkflowsContext = createContext(initialState);

export const WorkflowsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(WorkflowsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);

  const translations = useTranslations();

  const getWorkflows = () => {
    WorkflowsService.getWorkflows()
      .then((response) => {
        const { workflows } = response.data;
        dispatch({ type: WORKFLOWS_RECEIVED, payload: workflows });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleWorkflow = (workflow_id) => {
    WorkflowsService.getSingleWorkflow(workflow_id)
      .then((response) => {
        const { workflow } = response.data;
        dispatch({ type: SET_WORKFLOW, payload: workflow });
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setWorkflow = (workflow) => {
    dispatch({ type: SET_WORKFLOW, payload: workflow });
  };

  const createWorkflow = () => {
    dispatch({ type: CREATE_WORKFLOW });
  };

  const setPropertyWorkflow = (key, value) => {
    dispatch({ type: SET_PROPERTY_WORKFLOW, payload: { key, value } });
  };

  const saveWorkflow = (workflow, callback) => {
    dispatch({ type: SHOW_SPINNER });
    let service = WorkflowsService.putWorkflow;
    if (isNaN(parseInt(workflow.workflow_id))) {
      service = WorkflowsService.postWorkflow;
    }
    service(workflow)
      .then(() => {
        success(translations.workflows.saved);
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  const deleteWorkflow = (workflow_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    WorkflowsService.deleteWorkflow(workflow_id)
      .then(() => {
        success(translations.workflows.deleted);
        dispatch({ type: HIDE_SPINNER });
        clearModal();
        if (typeof callback === "function") {
          callback();
        }
      })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };

  return (
    <WorkflowsContext.Provider
      value={{
        ...state,
        setWorkflow,
        getWorkflows,
        saveWorkflow,
        deleteWorkflow,
        createWorkflow,
        getSingleWorkflow,
        setPropertyWorkflow,
      }}
    >
      {children}
    </WorkflowsContext.Provider>
  );
};
