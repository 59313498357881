import React, { createContext, useReducer, useContext } from 'react';
import CampaignsService from '../services/CampaignsService';
import CampaignsReducer from '../reducers/CampaignsReducer';
import {
  CAMPAIGNS_RECEIVED,
  SET_CAMPAIGN,
} from "../types/Campaigns";
import { ModalContext } from './ModalContext';
import { HIDE_SPINNER, SHOW_SPINNER } from "../types";
import { useCampaignAccess } from '../hooks/campaigns/useCampaignsAccess';
import { AuthContext } from './AuthContext';

const initialState = {
  campaigns: [],
  currentCampaignResources: [],
  campaign: null,
};

export const CampaignsContext = createContext(initialState);

export const CampaignsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(CampaignsReducer, initialState);

  const { alert, success, clearModal } = useContext(ModalContext);
  const { user_organization } = useContext(AuthContext);
  const { getCurrentUserCampaigns } = useCampaignAccess();


  const getCampaigns = (organization_id, query, sortBy) => {
    CampaignsService.getCampaigns(organization_id, query, sortBy)
      .then((response) => {
        const campaigns = response.data;

        if (user_organization && user_organization?.user_organization_id) {
          const currentCampaigns = getCurrentUserCampaigns(campaigns);
          dispatch({ type: CAMPAIGNS_RECEIVED, payload: currentCampaigns });
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const getSingleCampaign = (campaign_id, filter = "", query = "") => {
    CampaignsService.getSingleCampaign(campaign_id, filter, query)
      .then((response) => {
        const campaign = response.data;

        setCampaign(campaign);
      })
      .catch((error) => {
        alert(error);
      });
  };

  const setCampaign = (campaign) => {
    dispatch({ type: SET_CAMPAIGN, payload: campaign });
  };

  const getFormData = (campaign) => {
    const formData = new FormData();
    formData.append("file", campaign.thumbnail);
    const campaignData = JSON.stringify(campaign);
    formData.append("campaign", campaignData);
    return formData;
  }

  const saveCampaign = (campaign, callback) => {
    dispatch({ type: SHOW_SPINNER });
    const campaignFormData = getFormData(campaign)
    let service = CampaignsService.putCampaign;
    if (isNaN(parseInt(campaign.campaign_id))) {
      service = CampaignsService.postCampaign;
    }
    service(campaignFormData).then(() => {
      success("Campaign saved.");
      dispatch({ type: HIDE_SPINNER });
      setCampaign(campaign);
      clearModal();
      if (typeof callback === "function") {
        callback();
        setCampaign(campaign);
      }
    })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };


  const saveCampaignResource = (campaignResource, callback) => {
    dispatch({ type: SHOW_SPINNER });

    let service = CampaignsService.postCampaignResource;
    if (isNaN(parseInt(campaignResource.campaign_id))) {
      service = CampaignsService.putCampaignResource;
    }
    service(campaignResource).then((res) => {
      success(`Campaign ${campaignResource.type} added`);
      dispatch({ type: HIDE_SPINNER });

      getSingleCampaign(campaignResource.campaign_id, campaignResource.type)

      clearModal();
      if (typeof callback === "function") {
        callback();
      }
    })
      .catch((error) => {
        dispatch({ type: HIDE_SPINNER });
        alert(error);
      });
  };


  const deleteCampaignResource = (campaignResource, callback) => {
    dispatch({ type: SHOW_SPINNER });

    CampaignsService.deleteCampaignResource(campaignResource).then(() => {
      success(`${campaignResource.type} deleted from campaign`);
      dispatch({ type: HIDE_SPINNER });
      clearModal();

      getSingleCampaign(campaignResource.campaign_id, campaignResource.type)

      if (typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

  const deleteCampaign = (campaign_id, callback) => {
    dispatch({ type: SHOW_SPINNER });
    CampaignsService.deleteCampaign(campaign_id).then(() => {
      success("Campaign deleted.");
      dispatch({ type: HIDE_SPINNER });
      clearModal();
      setCampaign({});
      if (typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    })
  };

  const giveCampaignAccess = (accessParams, callback) => {
    dispatch({ type: SHOW_SPINNER });
    CampaignsService.giveCampaignAccess(accessParams).then(() => {
      success("Gave campaign access");
      dispatch({ type: HIDE_SPINNER });
      clearModal();

      getSingleCampaign(accessParams.campaign_id)

      if (typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error.response.data.error);
    }).finally(clearModal)
  };

  const revokeCampaignAccess = (accessParams, callback) => {
    dispatch({ type: SHOW_SPINNER });
    CampaignsService.revokeAccessCampaign(accessParams).then(() => {
      success("Removed Access");

      getSingleCampaign(accessParams.campaign_id)

      dispatch({ type: HIDE_SPINNER });
      if (typeof callback === "function") {
        callback();
      }
    }).catch(error => {
      dispatch({ type: HIDE_SPINNER });
      alert(error);
    }).finally(clearModal)
  };

  return (
    <CampaignsContext.Provider
      value={{
        ...state,
        setCampaign,
        getCampaigns,
        saveCampaign,
        deleteCampaign,
        getSingleCampaign,
        deleteCampaignResource,
        saveCampaignResource,
        giveCampaignAccess,
        revokeCampaignAccess,
      }}
    >
      {children}
    </CampaignsContext.Provider>
  );
};
