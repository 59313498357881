import React, { useEffect, useContext } from "react";
import Generator from "./Generator";
import { MenuContext } from "../context/MenuContext";
import useTranslations from "../hooks/useTranslations";

const TopicsGenerator = () => {
  const translations = useTranslations();
  const { setSelected } = useContext(MenuContext);

  useEffect(() => {
    setSelected("Hashtag Generator");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBasePrompt = () => {
    const currentLang = translations.lang === 'en' ? 'English' : 'Español';
    return `Use ${currentLang} as the main language to do the next task: Generate 2 to 5 trending topic ideas for`;
  }


  return (
    <Generator
      base_prompt={getBasePrompt()}
      title={translations.topics.title}
      message_type_id={3}
    />
  );
};

export default TopicsGenerator;
