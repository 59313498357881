import React, { useContext, useState } from "react";
import { AppConfigContext } from "../../context/AppConfigContext";
import SettingsNav from "../../components/settings/SettingsNav";
import ContentSection from "../../components/settings/ContentSection";
import { useCrudAppConfig } from "../../hooks/settings/useCrudAppConfig";
import AdvancedSection from "../../components/settings/AdvancedSection";

const AdminSettings = () => {
  const [currentTab, setCurrentTab] = useState('content');
  const { loading } = useContext(AppConfigContext);
  
  const { saveAppConfig } = useCrudAppConfig();

  const componentsByTab = {
    content: <ContentSection/>,
    advanced: <AdvancedSection/>
  }

  const renderCurrentTab = () => {
    return componentsByTab[currentTab];
  }

  return (
    <div className="container-fluid h-100 d-flex flex-column">
      <SettingsNav currentTab={currentTab} setCurrentTab={setCurrentTab}/>
      {renderCurrentTab()}

      <div className="row w-100 mx-auto">
        <div className="col-12 d-flex justify-content-end">
          <button 
            type="button"
            className="btn btn-primary"
            onClick={saveAppConfig}
            disabled={loading}
            style={{
              maxWidth: 'max-content'
            }}
          >
            Save
          </button>
        </div>
      </div> 
    </div>
  );
};

export default AdminSettings;
