const avatar = {
  files: "Files",
  editable_files: "Editable Files",
  files_empty: "Your Avatar doesn't have any Files yet.",
  editable_files_empty: "Your Avatar doesn't have any Editable Files yet.",
  files_description: "Give more context to your Avatar and improve its responses by uploading Files!",
  editable_files_description: "Give more context to your Avatar and improve its responses by uploading Editable Files!",
  context_header: "Context",
  context_description: "Train your Avatar giving it some Context",
  train_start: "Begin training it!",
  context_empty: "doesn't have any context yet.",
  train_title: "Train your Avatar!",
  search: "Search for content...",
  select: "Select",
  train: "Train",
  import: "Import",
  content: "Content",
  combine: "Combine",
  details: "Details",
  selecting: "Selecting",
  trainings: {
    execute: "Train",
    title: "Trainings",
    single: "Training",
    sample_file: "Sample File",
    search: "Search trainings...",
    empty: "You don't have any trainings.",
    card: {
      last_update: "Last Updated",
    },
    start:
      "This will execute a training on this dataset and you will be billed to your OpenAI account between $20 to $60 USD depending on the size of your dataset.",
  },
  outputs: {
    details: "Details",
    title: "Outputs",
    card: {
      details: "Show details",
      delete: "Delete Output",
      edit: "Edit Output",
    },
  },
};

export default avatar;
