import React, { useContext } from "react";
import { TemplatesContext } from "../context/TemplatesContext";
import TemplateList from "../components/templates/TemplatesList";

const PublicTemplates = ({
  tag,
  type,
  size,
  hideEdit,
  avatar_id,
  hideCreate,
  handleApply,
}) => {
  const { templates, getTemplatesByTag, getTemplatesByType } = useContext(TemplatesContext);

  const fetchTemplates = (params) => {
    if (tag && tag !== null) {
      getTemplatesByTag(tag, params);
    }

    if (type && type !== null) {
      getTemplatesByType(type, params);
    }
  };

  const getTitle = () => {
    if (tag && tag !== null) {
      return `${tag} Templates`;
    }
    if (type && type !== null) {
      return `${type} Templates`;
    }
    return "Templates";
  };

  return (
    <div className="px-4 py-2 h-100 card bg-white active-scroll-y">
      <TemplateList
        title={getTitle()}
        tag={tag}
        type={type}
        size={size}
        hideEdit={hideEdit}
        avatar_id={avatar_id}
        templates={templates}
        hideCreate={hideCreate}
        handleApply={handleApply}
        fetchTemplates={fetchTemplates}
        defaultSelected={''}
      />
    </div>
  );
};

export default PublicTemplates;
