import { useContext } from "react";
import { PurchasesContext } from "../../context/PurchasesContext";
import SearchableSelect from "../global/SearchableSelect";
import { CustomerContext } from "../../context/CustomerContext";
import { useEffect } from "react";
import ReactSwitch from "react-switch";
import { useState } from "react";

const ProductTestPurchase = ({ handleCancel, product_id }) => {
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [userId, setUserId] = useState(null);

  const [existing, setExisting] = useState(true);

  const { spinner, test_purchase, testPurchase, clearTestPurchase } =
    useContext(PurchasesContext);
  const { customers, getAllCustomers } = useContext(CustomerContext);

  useEffect(() => {
    if (query !== "") {
      getAllCustomers({ query });
    }
    return clearTestPurchase;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const handleSubmit = (e) => {
    e.preventDefault();
    testPurchase({ email, user_id: userId, product_id });
  };

  const renderResponse = () => {
    if (test_purchase && test_purchase !== null) {
      return JSON.stringify(test_purchase, null, 2);
    }
  };

  return (
    <div>
      <h4 className="border-bottom pb-2">Test User</h4>
      <div className="row my-3 align-items-center">
        <div className="col-10 ps-0">
          <label>Test with an existing user?</label>
        </div>
        <div className="col-2 pe-0">
          <ReactSwitch checked={existing} onChange={setExisting} />
        </div>
      </div>
      {existing ? (
        <SearchableSelect
          value={userId}
          items={customers}
          itemText={"email"}
          itemValue={"user_id"}
          handleInputCallback={setQuery}
          modifier={({ value }) => setUserId(value)}
        />
      ) : (
        <div>
          <label>Enter test email:</label>
          <input
            type="email"
            value={email}
            className="form-control"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      )}
      <div className="my-3">{renderResponse()}</div>
      <div className="row">
        <div className="col-6">
          <button onClick={handleCancel} className="btn text-muted w-100">
            Cancel
          </button>
        </div>
        <div className="col-6">
          <button
            disabled={spinner}
            onClick={handleSubmit}
            className="btn btn-primary w-100"
          >
            {spinner ? <div className="spinner-border" /> : "Test"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductTestPurchase;
